import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react";
import {
    Box, Button, CircularProgress,
    MenuItem,
    Select, Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStores} from "../../hooks/use-stores";
import moment from "moment";
import {capitalize, formatNumber} from "../../utils/helpers";
import {makeStyles} from "@material-ui/core/styles";
import AccordionSection from "./controls/AccordionSection";
import clsx from "clsx";
import StyledHeadTableRow from "../RoyalStarfish/components/StyledHeadTableRow";
import StyledTableRow from "../RoyalStarfish/components/StyledTableRow";
import BonusChart from "./controls/BonusChart";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MyProgressStore from "../../stores/myProgressStore";
import Periods from "../RoyalStarfish/components/Periods";


const useStyles = makeStyles((theme) => ({
    qualifyingTeam: {
        display: "table-cell",
        background: "#e3e3e3",
        verticalAlign: "top",
        textAlign: "center",
        minWidth: 75,


    },

    row: {
        border: "1px dashed #fff",
        minWidth: "80px",
        maxWidth: 90,
        paddingTop: "15px",
        paddingBottom: "5px",
        minHeight: "70px",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        fontSize: 13
    },
    cellName: {
        overflow: "hidden",
        padding: "0 5px",
        textOverflow: "ellipsis"
    },
    rowTeam: {
        paddingTop: "5px",
        minHeight: "80px",
        lineHeight: "20px",
        backgroundColor: "#000",
        color: "#fff !important",
    },

    rowQualify: {
        backgroundColor: "#47a447",
        color: "#fff"
    },

    rowPending: {
        border: "1px solid #da2127 !important"
    },

    rowEmpty: {
        backgroundColor: "#fff"
    },

    clickable: {
        cursor: "pointer"
    }


}));


function ShowPVrow({NumTeams, NumFill, PV, TeamBonus}) {
    if (NumTeams === 0 && NumFill === 0) {
        return <span>{formatNumber(PV, 2)}PV</span>

    }
    return <span>${formatNumber(TeamBonus, 2)}</span>

}

function FillEmptyBoxesBBB({classes, NumTeams, NumFill, LastName, PV}) {
    if (NumFill > 0) {
        if (NumTeams > -1) {
            return <>
                {[...Array(NumFill).keys()].map((index) => {
                    return <Box key={index} className={clsx(classes.row, classes.rowPending)}/>
                })}
            </>

        } else {
            return <>
                <Box className={clsx(classes.row)}>{LastName}<br/>{formatNumber(PV, 2)} PV</Box>
                {NumFill - 1 > 0
                    ? [...Array(NumFill - 1).keys()].map((index) => {
                        return <Box key={index} className={clsx(classes.row, classes.rowEmpty)}/>
                    })
                    : null
                }
            </>
        }

    }
    return null;

}


function MyProgress() {
    const classes = useStyles();
    const {t} = useTranslation("myprogress");
    const [showingLegend, setShowingLegend] = React.useState(false);
    const {authStore, myProgressStore, periodsStore, dashboardStore, commonStore} = useStores();
    const {periodsLoading} = periodsStore;
    const {dashboardPersonalData} = dashboardStore;
    const {currentUser} = authStore;
    const {
        PVPeriodID, calculatingRetailSales, retailSales,
        calculatingSalesAchiever, salesAchieverData,
        calculatingSalesAchieverPro, salesAchieverDataPro,
        howTos, howTosLoading, BLBUser,
        blbData, blbDataLoading, otcaData, otcaDataLoading
    } = myProgressStore;


    useEffect(() => {
        if (currentUser) {
            (async () => {
                await periodsStore.loadPeriods(currentUser.userID);
                await myProgressStore.loadHowTos(commonStore.language);
                await myProgressStore.resetData();
            })();
        }
    }, [currentUser])

    const handleChangePeriod = (e) => {
        myProgressStore.setPVPeriodID(e.target.value)
    }

    const calculateRetailSales = () => {
        myProgressStore.calculateRetailSales()
    }

    const calculateSalesAchiever = () => {
        myProgressStore.calculateSalesAchiever()
    }

    const calculateSalesAchieverPro = () => {
        myProgressStore.calculateSalesAchieverPro()
    }

    const calculateBLB = () => {
        myProgressStore.calculateBLB()
    }

    const calculateOTCA = () => {
        myProgressStore.calculateOTCA()
    }

    const cbValue = useMemo(() => {
        if (!dashboardPersonalData) return 0;
        if (dashboardPersonalData.customerBonus.Q4)
            return dashboardPersonalData.customerBonus.Q4
        if (dashboardPersonalData.customerBonus.Q3)
            return dashboardPersonalData.customerBonus.Q3
        if (dashboardPersonalData.customerBonus.Q2)
            return dashboardPersonalData.customerBonus.Q2
        if (dashboardPersonalData.customerBonus.Q1)
            return dashboardPersonalData.customerBonus.Q1

        return 0;
    }, [dashboardPersonalData])

    const cpbLabel = useMemo(() => {
        if (!dashboardPersonalData) return "";
        if (dashboardPersonalData.customerBonus.Q4)
            return "Q4"
        if (dashboardPersonalData.customerBonus.Q3)
            return "Q3"
        if (dashboardPersonalData.customerBonus.Q2)
            return "Q2"
        if (dashboardPersonalData.customerBonus.Q1)
            return "Q1"
        return "";

    }, [dashboardPersonalData])

    const isClickable = useMemo(() => {
        return [22, 61241449, 61238771, 61237467, 61235140, 61237533, 61235200].includes(currentUser.userID)
    }, [currentUser])

    const handleSwitchUser = (user) => () => {
        myProgressStore.setBLBUserID(user)
    }

    const showLegend = async () => {
        setShowingLegend(true)
    }

    const hideLegend = async () => {
        setShowingLegend(false)
    }


    return (
        <>
            {periodsLoading
                ? <> <CircularProgress/> </>
                : <Box display={"flex"} alignItems={"center"} style={{gap: 16}}>
                    <Typography variant={"subtitle1"}>{t("monthly_progress")}</Typography>
                    <Periods  margin={"dense"} periods={periodsStore.periods} PVPeriodID={PVPeriodID} handleChangePeriod={handleChangePeriod}/>
                </Box>}
            {howTosLoading !== null && periodsLoading !== null && !periodsLoading && <>
                <Typography variant={"subtitle1"} dangerouslySetInnerHTML={{__html:howTos && howTos["pageHeader"]}}/>
                <Typography variant={"h6"}>{t("retail_sales_bonuses")}</Typography>
                <Box paddingLeft={2}>
                    <AccordionSection id={"retailSalesCommission"} howTos={howTos}>
                        <>
                            <Button variant={"contained"} color={"primary"}
                                    disabled={calculatingSalesAchiever}
                                    onClick={calculateRetailSales}>{t("calculateRetailSales")}</Button>
                            {retailSales && <Box display={"grid"} gridTemplateColumns={"1fr 4fr"}>
                                <Typography>{t("new_customer")}</Typography>
                                <Typography>{formatNumber(retailSales.CustomerPVNew, 2)} PV -
                                    ${formatNumber(retailSales.CustomerBonusNew, 2)}</Typography>
                                <Typography>{t("standard_customer")}</Typography>
                                <Typography>{formatNumber(retailSales.CustomerPVStandard, 2)} PV -
                                    ${formatNumber(retailSales.CustomerBonusStandard, 2)}</Typography>
                            </Box>}
                        </>
                    </AccordionSection>

                    {(PVPeriodID >= 113 && PVPeriodID < 147) && <AccordionSection id={"salesAchiever"} howTos={howTos}>
                        <>
                            <Button variant={"contained"} color={"primary"}
                                    disabled={calculatingRetailSales}
                                    onClick={calculateSalesAchiever}>{t("calculateSalesAchiever")}</Button>
                            {salesAchieverData && <>
                                <Table size={"small"} style={{width: "auto"}}>
                                    <TableHead>
                                        <StyledHeadTableRow>
                                            <TableCell align={"center"}>Customer</TableCell>
                                            <TableCell align={"center"}>Platform</TableCell>
                                            <TableCell align={"center"}>Royal Starfish</TableCell>
                                            <TableCell align={"center"}>Accelerator</TableCell>
                                            <TableCell align={"center"}>Starfish</TableCell>
                                            <TableCell align={"center"}>Total</TableCell>
                                        </StyledHeadTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {salesAchieverData.customers.length > 0 ? salesAchieverData.customers.map((customer, index) => {
                                                return <StyledTableRow key={index}>
                                                    <TableCell
                                                        align={"left"}>{customer.FirstName} {customer.LastName}</TableCell>
                                                    <TableCell align={"right"}>{customer.AppSuper}</TableCell>
                                                    <TableCell align={"right"}>{customer.AppSuperPlus}</TableCell>
                                                    <TableCell align={"right"}>{customer.Product}</TableCell>
                                                    <TableCell align={"right"}>{customer.FullStart}</TableCell>
                                                    <TableCell
                                                        align={"right"}>{customer.AppSuper + customer.AppSuperPlus + customer.Product + customer.FullStart}</TableCell>
                                                </StyledTableRow>
                                            })
                                            : <StyledTableRow>
                                                <TableCell align={"center"} colSpan={6}>No customers</TableCell>
                                            </StyledTableRow>
                                        }

                                    </TableBody>
                                </Table>
                                <Box display={"grid"} gridTemplateColumns={"1fr 4fr"} marginTop={2}>
                                    <Typography>Bonus</Typography>
                                    {salesAchieverData.totalPV >= 600
                                        ? <Typography>{formatNumber(salesAchieverData.totalPV, 2)} PV
                                            @ {(salesAchieverData.percentSA * 100).toFixed(0)}% =
                                            ${formatNumber(salesAchieverData.percentSA * salesAchieverData.totalPV, 2)}</Typography>
                                        : <Typography>$0.00</Typography>
                                    }
                                </Box>

                            </>}
                        </>
                    </AccordionSection>}
                    {(PVPeriodID >= 113 && PVPeriodID < 124) &&
                        <AccordionSection id={"salesAchieverPro"} howTos={howTos}>
                            <>
                                <Button variant={"contained"} color={"primary"}
                                        disabled={calculatingSalesAchieverPro}
                                        onClick={calculateSalesAchieverPro}>{t("calculateSalesAchieverPro")}</Button>
                                {salesAchieverDataPro && <>

                                    {salesAchieverDataPro.teams && <> <Table size={"small"} style={{width: "auto"}}>
                                        <TableHead>
                                            <StyledHeadTableRow>
                                                <TableCell align={"left"} colSpan={6}>Downline Members</TableCell>
                                            </StyledHeadTableRow>
                                            <StyledHeadTableRow>
                                                <TableCell align={"center"}>Customer</TableCell>
                                                <TableCell align={"center"}>Platform</TableCell>
                                                <TableCell align={"center"}>Royal Starfish</TableCell>
                                                <TableCell align={"center"}>Accelerator</TableCell>
                                                <TableCell align={"center"}>Full Start *</TableCell>
                                                <TableCell align={"center"}>Total</TableCell>
                                            </StyledHeadTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {salesAchieverDataPro.teams.length > 0 ? salesAchieverDataPro.teams.map((customer, index) => {
                                                    return customer.Team
                                                        ? <StyledTableRow key={index} colSpan={6}>
                                                            <TableCell align={"left"}>{customer.Team}</TableCell>
                                                        </StyledTableRow>
                                                        : <StyledTableRow key={index}>
                                                            <TableCell
                                                                align={"left"}>{customer.FirstName} {customer.LastName}</TableCell>
                                                            <TableCell align={"right"}>{customer.AppSuper}</TableCell>
                                                            <TableCell align={"right"}>{customer.AppSuperPlus}</TableCell>
                                                            <TableCell align={"right"}>{customer.Product}</TableCell>
                                                            <TableCell align={"right"}>{customer.FullStart}</TableCell>
                                                            <TableCell
                                                                align={"right"}>{customer.AppSuper + customer.AppSuperPlus + customer.Product + customer.FullStart}</TableCell>
                                                        </StyledTableRow>
                                                })
                                                : <StyledTableRow>
                                                    <TableCell align={"center"} colSpan={6}>No customers</TableCell>
                                                </StyledTableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    </>}

                                    {salesAchieverDataPro.personal && <> <Table size={"small"} style={{width: "auto"}}>
                                        <TableHead>
                                            <StyledHeadTableRow>
                                                <TableCell align={"left"} colSpan={6}>Personal Sales</TableCell>
                                            </StyledHeadTableRow>
                                            <StyledHeadTableRow>
                                                <TableCell align={"center"}>Customer</TableCell>
                                                <TableCell align={"center"}>Platform</TableCell>
                                                <TableCell align={"center"}>Royal Starfish</TableCell>
                                                <TableCell align={"center"}>Accelerator</TableCell>
                                                <TableCell align={"center"}>Full Start *</TableCell>
                                                <TableCell align={"center"}>Total</TableCell>
                                            </StyledHeadTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {salesAchieverDataPro.personal.customers.length > 0 ? salesAchieverDataPro.personal.customers.map((customer, index) => {
                                                    return <StyledTableRow key={index}>
                                                        <TableCell
                                                            align={"left"}>{customer.FirstName} {customer.LastName}</TableCell>
                                                        <TableCell align={"right"}>{customer.AppSuper}</TableCell>
                                                        <TableCell align={"right"}>{customer.AppSuperPlus}</TableCell>
                                                        <TableCell align={"right"}>{customer.Product}</TableCell>
                                                        <TableCell align={"right"}>{customer.FullStart}</TableCell>
                                                        <TableCell
                                                            align={"right"}>{customer.AppSuper + customer.AppSuperPlus + customer.Product + customer.FullStart}</TableCell>
                                                    </StyledTableRow>
                                                })
                                                : <StyledTableRow>
                                                    <TableCell align={"center"} colSpan={6}>No customers</TableCell>
                                                </StyledTableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                        <Typography variant={"subtitle2"}>* The Full Start product is specific to the
                                            South Central market.</Typography>
                                    </>}

                                    <Box display={"grid"} gridTemplateColumns={"1fr 4fr"} marginTop={2}>
                                        <Typography>Bonus</Typography>
                                        {salesAchieverDataPro.totalPV >= 600
                                            ? <Typography>{formatNumber(salesAchieverData.totalPV, 2)} PV
                                                @ {(salesAchieverData.percentSA * 100).toFixed(0)}% =
                                                ${formatNumber(salesAchieverData.percentSA * salesAchieverData.totalPV, 2)}</Typography>
                                            : <Typography>$0.00</Typography>
                                        }
                                    </Box>
                                </>}
                            </>
                        </AccordionSection>}


                    <AccordionSection id={"customerPoolBonus"} howTos={howTos}>
                        <>
                            <Box display={"grid"} gridTemplateColumns={"1fr 4fr"}>
                                <Typography>{t("quarter_customer_pv", {quarter: cpbLabel})}</Typography>
                                <Typography>{formatNumber(cbValue, 2)}</Typography>

                            </Box>
                        </>
                    </AccordionSection>

                    <AccordionSection id={"cumulativeCustomerBonus"} howTos={howTos}>
                        <>
                            {dashboardPersonalData && <Box display={"grid"} gridTemplateColumns={"1fr 4fr"}>
                                <Typography>{t("annual_customer_pv")}</Typography>
                                <Typography>{formatNumber(dashboardPersonalData.customerBonus.CCB, 2)} PV</Typography>
                                <Typography>{t("customerPoolBonus-detailsTab")}:</Typography>
                                <Typography>${formatNumber(dashboardPersonalData.customerBonus.CCBBonus, 2)}</Typography>
                            </Box>}
                        </>
                    </AccordionSection>
                </Box>
                <br/>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant={"h6"}>{t("bonus_chart_commission")}</Typography>
                    <InfoIcon style={{cursor:"pointer", marginLeft:2}} onClick={showLegend}/>
                </Box>
                <Box paddingLeft={2}>
                    <AccordionSection id={"personalBonus"} howTos={howTos}/>
                    <AccordionSection id={"customerBonus"} howTos={howTos}/>
                    <AccordionSection id={"differentialBonus"} howTos={howTos}/>
                    {PVPeriodID >= 102 && <AccordionSection id={"doubleDifferentialBonus"} howTos={howTos}/>}

                </Box>


                <AccordionSection id={"chartTopperBonus"} howTos={howTos}/>
                <AccordionSection id={"buildingLeadersBonus"} howTos={howTos}
                                  detailsLabel={BLBUser ? `${BLBUser.LastName}  BLB` : null}>
                    <>
                        <Button variant={"contained"} color={"primary"}
                                disabled={blbDataLoading}
                                onClick={calculateBLB}>{t("calculate_blb")}</Button>
                        {" "}
                        {BLBUser && <Button variant={"contained"} color={"secondary"}
                                            disabled={blbDataLoading}
                                            onClick={handleSwitchUser(null)}>{t("back_to_my_blb")}</Button>}
                        <br/> <br/>
                        {blbDataLoading && <CircularProgress/>}
                        {blbData && <Box display={"flex"} flexDirection={"column"}>
                            {( PVPeriodID < MyProgressStore.BBBSwitchPeriodId) &&
                              <>
                                  <Typography noWrap>{t("asToday")}</Typography> ${formatNumber(blbData.bonusTotal, 2)}
                              </>}
                            {( PVPeriodID >= MyProgressStore.BBBSwitchPeriodId) &&
                              <Table style={{maxWidth: 700, marginBottom:20}} size={"small"}>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell>{t("blbSubtotal")}</TableCell>
                                          <TableCell align={"right"}>${formatNumber(blbData.bonusTotal, 2)}</TableCell>
                                          <TableCell></TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell>{t("blbBoost%")}</TableCell>
                                          <TableCell align={"right"}>{formatNumber( blbData.BLBBoostPercentage>=0.05?  blbData.BLBBoostPercentage*100: 0, 2)}%</TableCell>
                                          <TableCell align={"left"} style={{whiteSpace:"nowrap"}}>- BLB Boost begins with 5 Grid Spots and 5%</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell>{t("blbBoost")}</TableCell>
                                          <TableCell align={"right"}>${formatNumber(blbData.BLBBoostPercentage>=0.05? blbData.BLBBoost:0, 2)}</TableCell>
                                          <TableCell></TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell>{t("blbBoostTotal")}</TableCell>
                                          <TableCell align={"right"}>${formatNumber(blbData.bonusTotal + (blbData.BLBBoostPercentage>=0.05? blbData.BLBBoost:0), 2)}</TableCell>
                                          <TableCell></TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>}
                            <Box>
                                {blbData.teams.map((team, index) => {
                                    return <Box key={index} className={classes.qualifyingTeam}>
                                        <Box
                                            className={clsx(classes.row, classes.rowTeam)}>
                                            <small className={classes.cellTeam}>Team</small>
                                            <span className={classes.celName}>{team.LastName}</span>
                                            <ShowPVrow {...team} />
                                        </Box>
                                        {team.members && team.members.map((member, index) => {
                                            return <Box
                                                onClick={isClickable && handleSwitchUser(member)}
                                                className={clsx(classes.row, classes.rowQualify, {[classes.clickable]: isClickable})}>
                                                <span className={classes.celName}>{member.LastName}</span>
                                                <span>${formatNumber(member.Bonus, 2)}</span>

                                            </Box>
                                        })}
                                        <FillEmptyBoxesBBB classes={classes} {...team}/>
                                    </Box>
                                })}
                            </Box>
                        </Box>}
                    </>
                </AccordionSection>

                <AccordionSection id={"oneTimeCashAwards"} howTos={howTos}>
                    <>
                        <Button variant={"contained"} color={"primary"}
                                disabled={otcaDataLoading}
                                onClick={calculateOTCA}>{t("calculate_otca")}</Button>
                        {otcaData && <Box display={"flex"} flexDirection={"column"}>
                            <span>{t("baseline_rank")}: <strong>{otcaData.BaselineRank} </strong></span>
                            {otcaData.TargetRank &&
                                <span> {t("target_rank")}: <strong>{otcaData.TargetRank} </strong></span>}


                            <Table size={"small"} style={{width: "auto"}}>
                                <TableHead>
                                    <StyledHeadTableRow>
                                        <TableCell align={"center"}>Start Month</TableCell>
                                        <TableCell align={"center"}>Rank</TableCell>
                                        <TableCell align={"center"}>Months of Qualification</TableCell>
                                        <TableCell align={"center"}>Qualification Month</TableCell>
                                        <TableCell align={"center"}>One Time Cash Award</TableCell>
                                    </StyledHeadTableRow>
                                </TableHead>
                                <TableBody>
                                    {otcaData.Months.length > 0 ? otcaData.Months.map((customer, index) => {
                                            return <StyledTableRow key={index}>
                                                <TableCell
                                                    align={"center"}>{moment(customer.StartDate).format("MMMM YYYY")}</TableCell>
                                                <TableCell align={"center"}>{customer.OTCARank}</TableCell>
                                                <TableCell align={"center"}>{customer.NumMonths}</TableCell>
                                                <TableCell
                                                    align={"center"}>{moment(customer.EndDate).format("MMMM YYYY")}</TableCell>
                                                <TableCell align={"center"}>${formatNumber( customer.Amount, 2)}</TableCell>
                                            </StyledTableRow>
                                        })
                                        : <StyledTableRow>
                                            <TableCell align={"center"} colSpan={5}>No data</TableCell>
                                        </StyledTableRow>
                                    }

                                </TableBody>
                            </Table>
                        </Box>}
                    </>
                </AccordionSection>
            </>}
            <BonusChart showingLegend={showingLegend} hideLegend={hideLegend}/>

        </>


    );
}


export default observer(MyProgress);