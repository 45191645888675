import {MenuItem, Select} from "@material-ui/core";
import {capitalize, monthAndYear} from "../../../utils/helpers";
import moment from "moment";
import React from "react";
import {observer} from "mobx-react";
import {useStores} from "../../../hooks/use-stores";
import {useTranslation} from "react-i18next";

function Periods({PVPeriodID, periods, handleChangePeriod, ...rest }){

    const {commonStore} = useStores()
    const {t} = useTranslation();


    return  <Select variant={"outlined"} value={PVPeriodID} onChange={handleChangePeriod} {...rest}>
        {periods.map(period => {
            return <MenuItem key={period.PVPeriodID}
                             value={period.PVPeriodID}>{monthAndYear(period.StartDate,commonStore.language,t )}</MenuItem>
        })}
    </Select>
}


export default observer(Periods)