import React, {useEffect, useState} from "react";
import {observer, useLocalObservable } from "mobx-react-lite";
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import { useStores } from "../../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import PageTitle from "../../components/PageTitle";
import {useTranslation} from "react-i18next";
import moment from "moment";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import {
    Box, Button, DialogActions, DialogContent, DialogTitle,
    InputLabel, MenuItem, Select, Grid, TextField, Avatar
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import {CircularProgress} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import {copyToClipBoard, starFishColor} from "../../components/styles";
import clsx from "clsx";
import secondaryWhiteTheme from "../../themes/secondaryWhite";
import {useWindowVirtualizer} from "@tanstack/react-virtual";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        color: "white",
        backgroundColor: "black",
    },
    root: {
        flexGrow: 1,
        color: "white"
    },

    merchantLogoContainer:{
        alignSelf: "flex-start"
    },
    merchantLogo:{
        width: 96,
        height:96,
        backgroundColor: "#fff",
        '& > img': {
            objectFit: 'contain'
        }
    },
    amount:{
        ...starFishColor(theme),
        fontWeight:900,
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end"
    },
    code:{
        ...starFishColor(theme),
        alignItems: "center",
        display: "flex"
    },

    cardList:{
        marginBottom:50,
        [theme.breakpoints.down('xs')]: {
            gap:10,
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: "column"
        },
    },
    card:{
        marginBottom:20,
        height: 300,
        width: "calc( 50% - 20px )",
        display: "flex",
        flexDirection: "column",
        "&.MuiPaper-root": {
            backgroundColor: "black",
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 20
        },
        ...starFishColor(theme, "borderColor"),
        [theme.breakpoints.down('xs')]: {
            width: "calc( 100% - 20px )",
            marginLeft:10,
        }
    },
    cardContent:{
       flex: 1,
        display: "flex",
        flexDirection: "column",
        paddingBottom:0
    },
    result:{
        padding:20,
        display:"flex",
        flexDirection:"column",
        gap:20,
        [theme.breakpoints.down('sm')]: {
            paddingLeft:0,
            paddingRight:0
        }

    },
    archived:{
        paddingLeft:5,
        color: "red",
    },

    active:{
        paddingLeft:5,
        color: "#00D200",
    },
    faIcons:{
        marginLeft:8,
    },
    statusCell:{
        width: 120,
        height:55,
    },
    notesCell:{
        width: 120,
    },
   ...copyToClipBoard(theme)
}));


export default observer(function Wallet({ parentMatch, section }) {
    const classes = useStyles();
    const {t} = useTranslation("gift_cards");
    const { giftCardsStore, commonStore } = useStores();
    const [selectedItem, setSelectedItem] = useState();
    const [checkingBalanceItem, setCheckingBalanceItem] = useState();
    const { cardsNotes, notesLoading, notesUpdating, giftCardsUpdating, error } = giftCardsStore;

    useEffect(() => {
        (async () => {
            await giftCardsStore.loadGiftCards()
        })();
    }, []);


    const localStore = useLocalObservable(() => ({
        activeTab: "active",
        selectedMerchant: "all",
        setActiveTab(value) {
            this.activeTab  = value
        },
        setSelectedMerchant(value) {
            this.selectedMerchant  = value
        },

        get effectiveLoader() {
            return giftCardsStore.activeGiftCardsLoading || giftCardsStore.archivedGiftCardsLoading
        },

        get effectiveList() {
            console.log("recalculating effectiveList")
            let result = [];

            switch (this.activeTab) {
                case "active":
                    result = giftCardsStore.activeGiftCards;
                    break
                case "archived":
                    result = giftCardsStore.archivedGiftCards;
                    break
                case "all":
                    result = [...giftCardsStore.activeGiftCards, ...giftCardsStore.archivedGiftCards];


            }

            return orderBy( result, ["orderDate"], ["desc"] )
        },

        get effectiveMerchants() {
            console.log("recalculating effectiveMerchants")
            return sortBy(uniq(this.effectiveList.map(x=>x.merchantName) ), x=> x.toLowerCase() )
        },

        get filteredList() {
            console.log("recalculating filtered list")
            return this.selectedMerchant === "all"? this.effectiveList : this.effectiveList.filter( x=> x.merchantName ===  this.selectedMerchant )
        },
    }));


    const handleToggleStatus = async (selectedItem)=>{
        const  result = await giftCardsStore.toggleGiftCardStatus(selectedItem)
        if( result && localStore.filteredList.length === 0 ){
            localStore.setSelectedMerchant("all")
        }
    }

    const handleCheckBalance = async ()=>{
        setCheckingBalanceItem(selectedItem)
        if (selectedItem.balanceCheck)
             giftCardsStore.checkBalance( selectedItem )

    }


    const handleOpenNotes = (item)=>{
        giftCardsStore.setItemDraftNotes(item, ( cardsNotes[item.giftCardID] && cardsNotes[item.giftCardID].notes )  )
        setSelectedItem(item)
    }

    const handleCloseNotes = ()=>{
        if ( notesLoading ) return
        giftCardsStore.setItemDraftNotes(selectedItem, null )
        setSelectedItem(null)
    }

    const handleNoteChange = (e)=>{
        selectedItem.noteDraft = e.target.value;
    }

    const handleSaveNotes =  async ()=>{
        await giftCardsStore.updateNotes( selectedItem.giftCardID, selectedItem.noteDraft )
        handleCloseNotes()
    }

    const handleCloseCheckBalance = ()=>{
        setCheckingBalanceItem(null)
    }

    const parentRef = React.useRef(null)

    const parentOffsetRef = React.useRef(0)

    React.useLayoutEffect(() => {
        parentOffsetRef.current = parentRef.current?.offsetTop ?? 0
    }, [])

    const virtualizer = useWindowVirtualizer({
        count: localStore.filteredList.length,
        estimateSize: () => 320,
        scrollMargin: parentOffsetRef.current,
    })
    const items = virtualizer.getVirtualItems()

    return (<div className={classes.root}>
        <PageTitle>{t("gift_cards")}</PageTitle>
        {error ? <Alert severity="error">{error}</Alert> : null}
            {localStore.effectiveList.length===0 && ( localStore.effectiveLoader == null || localStore.effectiveLoader )
                ?< CircularProgress />

                :   <div className={classes.result}>
                    <Grid container spacing={2} >
                        <Grid item  xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="status-label" >
                                    {t("gift_card_status")}
                                </InputLabel>
                                <Select  value={localStore.activeTab}  id={"status"} labelId={"status-label"}
                                         onChange={e=>localStore.setActiveTab(e.target.value)}>
                                    <MenuItem value={"all"}>{t("global:showAll")}</MenuItem>
                                    <MenuItem value={"active"}>{t("global:activeOnly")}</MenuItem>
                                    <MenuItem value={"archived"}>{t("global:usedOnly")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="filter-label" >
                                    {t("merchant_filter")}
                                </InputLabel>
                                <Select  value={localStore.selectedMerchant}  id={"filter"} labelId={"filter-label"}
                                         onChange={e=>{localStore.setSelectedMerchant(e.target.value)}}>
                                    <MenuItem value={"all"}>{t("global:all")}</MenuItem>
                                    {localStore.effectiveMerchants.map(x=>(<MenuItem key={x} value={x}>{x}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className={classes.cardList} ref={parentRef} >
                        <div
                            style={{
                                height: virtualizer.getTotalSize(),
                                width: '100%',
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    transform: `translateY(${
                                        items[0]? (items[0].start):0 - virtualizer.options.scrollMargin
                                    }px)`,
                                }}
                            >

                                {items.map((virtualRow)=>  {
                                    const x= localStore.filteredList[virtualRow.index]
                                    let codesCount = 0;
                                    if ( x.pin ) codesCount++;
                                    if( x.securityCode ) codesCount++;
                                    if ( x.barcode ) codesCount ++
                                    return <Card  key={virtualRow.key}
                                                  data-index={virtualRow.index}
                                                  ref={virtualizer.measureElement} className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {t("purchased_date")}: {moment(x.orderDate).format("MM/DD/YYYY")}
                                            </Typography>
                                            <Box display={"flex"}  alignItems={"flex-start"} justifyContent={"flex-start"} marginTop={1}>
                                                <div className={classes.merchantLogoContainer}>
                                                    <Avatar variant="rounded" src={x.logo} className={classes.merchantLogo}></Avatar>
                                                </div>
                                                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"} flex={1}>
                                                    <Box display={"flex"} alignSelf="flex-start" paddingLeft={1}>
                                                        <Typography variant="h6" color="textSecondary" component="p">
                                                            {x.merchantName}
                                                        </Typography>
                                                    </Box>
                                                    <Box display={"flex"} alignSelf={"flex-end"} flexDirection="column">
                                                        <Typography variant="h5"  className={classes.amount}>
                                                            ${ ( Number(x.amount) || 0 ).toFixed(2) }
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            {t("purchased_amount")}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box display="flex" justifyContent={codesCount>1? "space-between": "center"} marginTop={"16px"} >
                                                {x.pin &&<CopyToClipboard text={x.pin}
                                                                 onCopy={() => commonStore.success("Code copied")}>
                                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} style={{cursor:"pointer"}}>
                                                        <Typography variant="body2" color="textSecondary" component="p" >
                                                            {t("activation_code")}
                                                        </Typography>
                                                        <Typography variant="h6"  className={classes.code}>
                                                            { x.pin }
                                                        </Typography>
                                                        <Typography variant="body2"  className={classes.code}>
                                                            copy code{' '}<FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" />
                                                        </Typography>
                                                    </Box>
                                                </CopyToClipboard>}

                                                {x.securityCode &&<CopyToClipboard text={x.securityCode}
                                                                 onCopy={() => commonStore.success("Code copied")}>
                                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} style={{cursor:"pointer"}}>
                                                        <Typography variant="body2" color="textSecondary" component="p" >
                                                            {t("activation_code")}
                                                        </Typography>
                                                        <Typography variant="h6"  className={classes.code}>
                                                            { x.securityCode }
                                                        </Typography>
                                                        <Typography variant="body2"  className={classes.code}>
                                                            copy code{' '}<FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" />
                                                        </Typography>
                                                    </Box>
                                                </CopyToClipboard>}

                                                {x.barcode &&<CopyToClipboard text={x.barcode}
                                                                 onCopy={() => commonStore.success("Code copied")}>
                                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} style={{cursor:"pointer"}}>
                                                        <Typography variant="body2" color="textSecondary" component="p" >
                                                            {t("barcode")}
                                                        </Typography>
                                                        <Typography variant="h6"  className={classes.code}>
                                                            { x.barcode }
                                                        </Typography>
                                                        <Typography variant="body2"  className={classes.code}>
                                                            copy code{' '}<FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" />
                                                        </Typography>
                                                    </Box>
                                                </CopyToClipboard>}
                                            </Box>
                                        </CardContent>

                                        <CardActions style={{justifyContent:"space-between"}} al>
                                            <Box display="flex" flexDirection="column"
                                                 alignItems={giftCardsUpdating.includes(x.giftCardID)?"center":"flex-start"} justifyContent="center"
                                                 className={classes.statusCell}>
                                                {giftCardsUpdating.includes(x.giftCardID)
                                                    ? <CircularProgress size={24} className={classes.buttonProgress} />
                                                    : <>
                                                        <Typography className={clsx({ [classes.archived]: x.archived, [classes.active]: !x.archived })}>
                                                            {x.archived? t("used") : t("active")}
                                                        </Typography>
                                                        <Button size={"small"} variant={"text"} color={"primary"}  onClick={()=>handleToggleStatus(x)} >
                                                            {!x.archived? t("mark_used"): t("mark_active")}
                                                        </Button>
                                                    </>
                                                }
                                            </Box>
                                            <Box display="flex" >
                                                {x.deliveryURL && <Button color="primary" variant={"contained"} target={"_blank"} href={x.deliveryURL}>
                                                    {t("View Card")}
                                                </Button>}
                                            </Box>
                                            <Box display="flex" className={classes.notesCell}>
                                                <Button  color="primary" variant={"text"} onClick={()=>handleOpenNotes(x)} >
                                                    { cardsNotes && cardsNotes[x.giftCardID]? t("my_notes"): t("add_notes") }
                                                    {' '}<FontAwesomeIcon className={classes.faIcons} icon={faPencil} size="1x" />
                                                </Button>
                                            </Box>

                                        </CardActions>
                                    </Card>})}
                            </div>
                        </div>
                     </div>
                </div>
            }
        {selectedItem  && <Dialog maxWidth="sm" fullWidth onClose={handleCloseNotes} open={!!selectedItem}>
            <DialogTitle>
                { cardsNotes && cardsNotes[selectedItem.giftCardID]? t("edit_notes-header"): t("add_notes-header") }</DialogTitle>
            <DialogContent>
                {notesLoading
                    ? <CircularProgress/>
                    :<TextField fullWidth disabled={ notesUpdating.includes(selectedItem.giftCardID)}
                                value={selectedItem.noteDraft  || "" }
                                minRows={4} multiline onChange={handleNoteChange}/> }
            </DialogContent>
            <DialogActions>
                <Button disabled={notesLoading|| notesUpdating.includes(selectedItem.giftCardID)} color={"secondary"} variant={"contained"} onClick={handleCloseNotes}>Close</Button>

                    <Button disabled={notesLoading|| notesUpdating.includes(selectedItem.giftCardID)}
                            startIcon={ notesUpdating.includes(selectedItem.giftCardID) &&  <ThemeProvider theme={secondaryWhiteTheme}>
                                    <CircularProgress  color={"secondary"} size={20}/>
                                    </ThemeProvider>}
                            color={"primary"} variant={"contained"} onClick={handleSaveNotes}>Save</Button>

            </DialogActions>
        </Dialog>}

        {checkingBalanceItem  && <Dialog maxWidth={"sm"} fullWidth onClose={handleCloseCheckBalance} open={!!checkingBalanceItem}>
            <DialogTitle>
                <Box display={"flex"}  flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                    <img src={checkingBalanceItem.logo} className={classes.icon}/>
                    <Typography variant="h5">{checkingBalanceItem.merchant}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display={"flex"}  flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                {checkingBalanceItem.balanceCheck
                    ? <>
                    {checkingBalanceItem.balanceChecking
                        ?<CircularProgress/>
                        :<Box display={"flex"}  flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant={"subtitle1"}>{t("card_balance")}</Typography>
                            <Typography variant={"subtitle2"}>{checkingBalanceItem.balance}</Typography>
                        </Box>}


                    </>
                    : <Typography variant={"subtitle1"}>{t("balance_not_supported")}</Typography>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color={"secondary"} variant={"contained"} fullWidth onClick={handleCloseCheckBalance}> OK </Button>
            </DialogActions>
        </Dialog>}


    </div>);

});