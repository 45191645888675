import {
    Box,
    Button,
    CircularProgress, DialogTitle,
    Grid,
    MenuItem, Paper,
    Select, TextField,
    Typography, withStyles
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { createTheme, makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowForward } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useStores } from "../../../hooks/use-stores";
import { joinPath } from "../../../utils/helpers";

import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Toolbar from "@material-ui/core/Toolbar";
import { ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import PageTitle from "../../../components/PageTitle";
import VerificationStatus from "../../../components/Wallet/VerificationStatus";
import services from "../../../services";
import BillingInfoList from "../../Profile/BillingInfo/BillingInfoList";
import { currencyFormatter } from "../../shoppingboss/components/StyledValueWithCurrency";
import SparkWalletCheckbox from "../SparkWalletCheckbox";

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            decimalScale={2}
            thousandSeparator={true}
            getInputRef={inputRef}
            type="text"
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
        />
    );
}

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            borderWidth: 2
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#007BFF',
            },
            '&:hover fieldset': {
                borderColor: '#007BFF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#007BFF',
            },
        },
    },
})(TextField);
const CssFormControl = withStyles({
    root: {
        '& label.Mui-focused': {
            borderWidth: 2
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#007BFF',
            },
            '&:hover fieldset': {
                borderColor: '#007BFF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#007BFF',
            },
        },
    },
})(FormControl);


const useStyles = makeStyles((theme) => ({

    root: {
        height: '100%',
        display: "flex",
        flexDirection: "column"
    },
    mainBlock: {
        flex: 1
    },
    icons: {
        gap: theme.spacing(3),
        flexBasis: "initial"
    },
    continueBlock: {
    },
    avatar: {
        width: 75,
        height: 75
    },
    paper: {
        background: '#fff',
        height: "calc(100% - 60px)",
        marginBottom: 20,
        padding: theme.spacing(2),
        flexGrow: 1
    },
    divider: {
        width: '100%',
    },
    backButton: {
        flex: 1,
        minWidth: "fit-content"
    },

    rightCell: {
        flex: 1,
        minWidth: 100
    },
    simpleTitle: {
        flex: "1 1 auto",
        textAlign: "center",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        margin: "0 20px"

    },
    appbarToolbar: {
        height: "100%"
    },
    dialogTitle: {
        padding: 0
    },
    rightAlign: {
        textAlign: 'right'
    },
    dialogRoot: {
        marginTop: "-60px",
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    nextButton: {
        marginTop: '25%'
    }
}));


export default observer(function AddEWalletCard({ parentMatch, walletMatch, cardStatus, type = "card", achDisabled }) {
    const classes = useStyles();
    const { t } = useTranslation("wallet");
    const history = useHistory();
    const { commonStore, shoppingBossStore, authStore, identityVerificationStore, userBillingInfoStore, subscriptionStore } = useStores();
    const { currentUser } = authStore;
    const { sparkUserID, country } = currentUser;
    const { banks, cards, allLoading, error: billingError } = userBillingInfoStore;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const {
        loadingIdentityVerification,
        identityVerificationStatus,
        inquiryId,
        makingDeposits,
    } = identityVerificationStore;
    const [selectedCard, setSelectedCard] = React.useState(-1);
    const [showCardModal, setShowCardModal] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [depositAmount, setDepositAmount] = React.useState(null);
    const [acknowledge, setAcknowledge] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [hasAcceptedSparkTerms, setHasAcceptedSparkTerms] = React.useState(false);
    const { updatePaymentError } = subscriptionStore;

    const { eWalletCanAdd } = shoppingBossStore;


    useEffect(() => {
        if ( type === "bank"  && !!achDisabled ) {
            commonStore.showError("We apologize, but your ACH Deposit option is currently unavailable. Please contact customer support for more details.");
            history.push('/profile/help');
        }
        else {
            (async () => {
                await Promise.all([
                    shoppingBossStore.fetchUserBalance(),
                    identityVerificationStore.checkIdentityVerification(),
                    userBillingInfoStore.loadAll(),
                    getSparkWalletTermsStatus()
                ]);
            })();
        }
    }, []);

    const getSparkWalletTermsStatus = async () => {
        try {
            const { hasAcceptedSparkTerms = true } = await services.UserProfile.getSparkWalletTermsStatus();
            setHasAcceptedSparkTerms(hasAcceptedSparkTerms);
        } catch (e) {
            setHasAcceptedSparkTerms(true);
        }
    };

    const effectiveVerificationStatus = useMemo(() => {
        return identityVerificationStatus;
    }, [loadingIdentityVerification, currentUser,]);

    const fee = useMemo(() => {
        if (type === 'bank') return 0;
        return (depositAmount * (country && country === 'CA' ? 0.05 : 0.03)).toFixed(2);

    }, [depositAmount, type]);

    const selectedCardTitle = useMemo(() => {
        if (type === "bank") {
            let bank = banks.find(bank => bank.id === selectedCard);
            if (bank)
                return bank.friendlyName;
        }
        else {
            let card = cards.find(card => card.paymentID === selectedCard);
            if (card)
                return `********${card.last4}`;
        }

    }, [type, selectedCard, cards, banks]);


    const total = useMemo(() => {
        if (type === 'bank') return (parseFloat(depositAmount)).toFixed(2);
        return (depositAmount * (country && country === 'CA' ? 1.05 : 1.03)).toFixed(2);
    }, [type, depositAmount,]);

    const buttonTitle = useMemo(() => {

        switch (effectiveVerificationStatus) {
            case "PENDING":
                return t("verificationActions.check_status");
            case "CLOSED":
                return t("verificationActions.check_status");
            case "APPROVED":
                return "Next";
            case "NOACCOUNT":
            case "OPEN":
            default:
                return t("verificationActions.required");

        }

    }, [effectiveVerificationStatus, inquiryId, showConfirm]);



    const minimum = useMemo(() => {
        if( country && country === 'CA' && type === 'bank') return 50;
        if (type === 'bank') return 100;
        return (cardStatus && cardStatus.min) || 0;
    }, [type]);

    const maximum = useMemo(() => {
        if (type === 'bank') return eWalletCanAdd;
        return (cardStatus && cardStatus.max) || 0;
    }, [type, eWalletCanAdd, cardStatus]);

    const nextEnabledCA = useMemo(() => {
        if (buttonTitle === "Next" && type === "bank") {
            if (depositAmount >= minimum && depositAmount <= maximum) {
                return true;
            }
            return false;
        }
        else {
            if (["NOACCOUNT", "OPEN"].includes(effectiveVerificationStatus)
                && !sparkUserID && !termsAccepted) return false;
        }
        return true;
    }, [depositAmount, buttonTitle, type, termsAccepted])


    const nextEnabled = useMemo(() => {
        if (loadingIdentityVerification) return false;
        if (buttonTitle === "Next") {
            if (type === "card") {
                if (selectedCard >= 0 && (depositAmount >= minimum && depositAmount <= maximum)) {
                    return true;
                }
            }
            if (type === "bank") {
                if (selectedCard >= 0 && (depositAmount >= minimum && depositAmount <= maximum)) {
                    return true;
                }
            }
            return false;
        }
        else {
            if (["NOACCOUNT", "OPEN"].includes(effectiveVerificationStatus)
                && !sparkUserID && !termsAccepted) return false;
        }
        return true;
    }, [buttonTitle, selectedCard, depositAmount, effectiveVerificationStatus, termsAccepted, sparkUserID, minimum, maximum]);

    const handleNext = () => {
        switch (effectiveVerificationStatus) {
            case "PENDING":
                identityVerificationStore.checkIdentityVerification();
                break;
            case "CLOSED":
                history.push(joinPath("/profile/help"));
                break;
            case "APPROVED":
                void handleAcceptSparkTerms();
                return setShowConfirm(true);
            case "NOACCOUNT":
            case "OPEN":
            default:
                history.push(joinPath(parentMatch.url, "add_more_money/verify_identity"));
                break;
        }
    };

    const handleAcceptSparkTerms = async () => {
        if (!hasAcceptedSparkTerms) {
            setHasAcceptedSparkTerms(true);
            if (country && country !== 'CA') {
                await services.UserProfile.acceptSparkWalletTerms();
            }
        }
    };

    const handleCardChange = (event) => {
        if (event.target.value === -99) {
            setShowCardModal(true);
        }
        else {
            console.log('value: ', event.target.value);
            setSelectedCard(event.target.value);
        }

    };

    const handleHidePaymentEdit = () => {
        setShowCardModal(false);
    };

    const handleSavedPaymentEdit = () => {
        userBillingInfoStore.loadAll();
        setShowCardModal(false);
    };

    const handleDepositAmoutChange = (e) => {
        if (isNaN(Number(e.target.value))) return;
        setDepositAmount(e.target.value);
    };

    const handleConfirm = async (e) => {
        let result;
        if (type === 'bank') {
            if (country && country === 'CA') {
                result = await userBillingInfoStore.makeBankDepositCA(depositAmount);
            } else {
                result = await userBillingInfoStore.makeBankDeposit(selectedCard, depositAmount);
            }
        }
        else {
            result = await userBillingInfoStore.makeDeposit(selectedCard, depositAmount);
        }
        if (result) {
            if (country && country === 'CA') {
                history.push( joinPath(walletMatch.url, `add_more_money/etransfer`) )
            }
            else {
                history.push(joinPath(walletMatch.url));
            }
            await services.UserProfile.Wallet().recordDepositBankAccount({
                paymentId: userBillingInfoStore.cards.find(card => card.paymentID === selectedCard).paymentID,
                amount: depositAmount,
                userId: currentUser.userID
            });
            shoppingBossStore.fetchUserBalance();
            commonStore.success(t('add_money.success'));
        }
    };

    const lightTheme = createTheme({
        palette: {
            type: 'light'
        }
    });

    if ( type === "card" && country ==="CA" )
        return <Alert variant={"filled"} color={"error"}>{t("add_money.card.errorCountry")}</Alert>;

    return <>
        <PageTitle>{showConfirm ? t(`add_money.${type}.titleConfirmation`) : t(`add_money.${type}.title`)}</PageTitle>
        {showConfirm && <div className={classes.root}>
            {billingError && <Alert severity="error">{t(`add_money.${type}.confirm.error`)}</Alert>}
            <ThemeProvider theme={lightTheme}>
                <Paper classes={{ root: classes.paper }}>
                    <Grid container xs={12}>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={8} md={6} lg={4}>
                                <Typography variant="subtitle1">{(country && country === 'CA' && type !== "card" ) ? t(`add_money.confirm.from_ca`) : t(`add_money.confirm.from`)}</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.rightAlign}>
                                <b>{selectedCardTitle}</b>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={8} md={6} lg={4}>
                                <Typography variant="subtitle1">{t("add_money.confirm.amount")}</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.rightAlign}>
                                <b>{currencyFormatter(depositAmount * 100)}</b>
                            </Grid>
                        </Grid>
                        {type === "card" && <Grid container item xs={12} alignItems="center">
                            <Grid item xs={8} md={6} lg={4}>
                                <Typography variant="subtitle1">{country && country === 'CA' ? t("add_money.confirm.deposit_fee_CA") : t("add_money.confirm.deposit_fee")}</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.rightAlign}>
                                <b>{currencyFormatter(fee * 100)}</b>
                            </Grid>
                        </Grid>}
                        <Divider className={classes.divider} />
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={8} md={6} lg={4}>
                                <Typography variant="subtitle1"><b>{t("add_money.confirm.total")}</b></Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.rightAlign}>
                                <b>{currencyFormatter(total * 100)}</b>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                            {country && country === "CA" ? (
                                <ul>
                                    <li>{t("add_money.confirm.ccTerms_ca.line1")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line2")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line3")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line4")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line5")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line6")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line7")}</li>
                                    <li>{t("add_money.confirm.ccTerms_ca.line8")}</li>
                                </ul>
                                ) : (
                                <ul>
                                    <li>{t("add_money.confirm.ccTerms.line1")}</li>
                                    <li>{t("add_money.confirm.ccTerms.line2")}</li>
                                    <li>{t("add_money.confirm.ccTerms.line3")}</li>
                                    <li>{t("add_money.confirm.ccTerms.line4")}</li>
                                    <li>{t("add_money.confirm.ccTerms.line5")}</li>
                                    <li>{t("add_money.confirm.ccTerms.line6")}</li>
                                    <li>{t("add_money.confirm.ccTerms.line7")}</li>
                                    <li>
                                    {type === "card"
                                        ? t("add_money.confirm.ccTerms.line8")
                                        : t("add_money.confirm.ccTerms.line8_bank")}
                                    </li>
                                    {type === "bank" && (
                                    <li>{t("add_money.confirm.ccTerms.line9_bank")}</li>
                                    )}
                                </ul>
                            )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox />}
                                value={acknowledge}
                                onChange={() => {
                                    setAcknowledge(!acknowledge);
                                }}
                                label={
                                    country && country === "CA"
                                      ? t("add_money.confirm.acknowledgelabel_ca")
                                      : t("add_money.confirm.acknowledgelabel")
                                }
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Button className={classes.continueBlock} disabled={makingDeposits || !acknowledge} variant={"contained"}
                    fullWidth color="primary"
                    style={{ backgroundColor: '#007BFF' }}
                    onClick={handleConfirm}>Confirm</Button>

            </ThemeProvider>
        </div>}
        {!showConfirm &&
            <Box display={"flex"} flexDirection={"column"} alignItems="center" className={classes.root} >
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} className={classes.icons}>
                    <Avatar className={classes.avatar}
                        src="https://resources.lifeinfoapp.com/public/icon/wallet/giftcards.png" variant="rounded" />

                    <ArrowForward />
                    <Avatar className={classes.avatar}
                        src="https://resources.lifeinfoapp.com/public/icon/wallet/ewallet.png" variant="rounded" />
                </Box>
                <Box flexDirection={"column"} flexGrow={1} display={"flex"} marginBottom={2} marginTop={2} width="100%" style={{ gap: "20px" }} >
                    {effectiveVerificationStatus === "APPROVED" && <>

                        <Box>
                            {allLoading
                                ? (type === "card" ? <CircularProgress />:null)
                                :
                                (type === "card"
                                    ? <CssFormControl variant="outlined" color="secondary" fullWidth>
                                        <Select value={selectedCard} onChange={handleCardChange}>
                                            <MenuItem value={-1}>{t("add_money.card.select_card")}</MenuItem>
                                            {cards.map((card, index) => {
                                                return <MenuItem value={card.paymentID}
                                                    key={card.paymentID}>{`************${card.last4}, Expires ${card.expMonth | "00"}/${card.expYear | "00"} `}</MenuItem>;
                                            })}
                                            <MenuItem value={-99}>{t(`add_money.${type}.add_new`)}</MenuItem>
                                        </Select>
                                    </CssFormControl> :
                                    (country && country !== 'CA' ?
                                    <CssFormControl variant="outlined" color="secondary" fullWidth>
                                        <Select value={selectedCard} onChange={handleCardChange} >
                                            <MenuItem value={-1}>{t("add_money.bank.select_one")}</MenuItem>
                                            {banks.map((bank, index) => {
                                                return <MenuItem value={bank.id}
                                                    key={bank.id}>{`${bank.friendlyName} - ${bank.an}`}</MenuItem>;
                                            })}
                                            <MenuItem value={-99}>{t("add_money.bank.add_new")}</MenuItem>
                                        </Select></CssFormControl>:null) )}
                            </Box>
                        <Typography variant={"subtitle1"}>{t("add_money.general.enter_amount")}</Typography>

                        <Box display={"flex"} alignItems={"center"} style={{ gap: "20px" }}>
                            <CssTextField
                                type={"number"}
                                variant="outlined"
                                value={depositAmount}
                                color="secondary"
                                onChange={handleDepositAmoutChange}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                            <Box display={"flex"} flexDirection={"column"} whiteSpace={"nowrap"} flexGrow={1}>
                                <div>{t("add_money.general.minimum", { amount: "$" + minimum.toFixed(2) })}</div>
                                <div>{t("add_money.general.maximum", { amount: `$${maximum.toFixed(2)}` })}</div>
                            </Box>
                        </Box>
                    </>}

                    {country && country !== 'CA' &&
                    <>
                    {["NOACCOUNT", "OPEN"].includes(effectiveVerificationStatus) &&
                        <Typography style={{ whiteSpace: "pre-wrap" }} align={"center"}>{t("first_time")}</Typography>}
                    <div style={{ minHeight: '20vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <VerificationStatus textVariant={identityVerificationStatus !== "APPROVED" ? "h6" : "subtitle1"}
                            layout={identityVerificationStatus !== "APPROVED" ? "column" : "row"} status={identityVerificationStatus} />
                    </div>
                    </>}
                </Box>

                {country && country === "CA" ? (
                    <Button
                        className={classes.nextButton}
                        disabled={!nextEnabledCA}
                        variant={"contained"}
                        fullWidth
                        color="primary"
                        onClick={handleNext}
                    >
                        {loadingIdentityVerification ? <CircularProgress /> : buttonTitle}
                    </Button>
                    ) : (
                        <div style={{ width: '100%', marginTop: '40px' }}>
                            {
                                type === "bank"
                                    ?
                                    <>
                                        <Grid container item xs={12} style={{ width: '100%', marginBottom: '10px' }} >
                                            <Typography variant="subtitle1">{t("add_money.bank.deposit_details")}</Typography>
                                        </Grid>
                                    </>
                                    : ''
                            }
                            <SparkWalletCheckbox hasAcceptedSparkTerms={hasAcceptedSparkTerms} termsAccepted={termsAccepted} onTermsAccepted={setTermsAccepted} />
                            <Button className={classes.continueBlock} disabled={!nextEnabled} variant={"contained"} fullWidth color="primary"
                                onClick={handleNext}>{loadingIdentityVerification ?
                                    <CircularProgress /> : buttonTitle}</Button>
                        </div>
                    )
                }

            </Box>
        }
        {showCardModal && <Dialog maxWidth={"md"} fullScreen={!isDesktop} fullWidth open={true} onClose={handleHidePaymentEdit}>
            {!isDesktop && <DialogTitle classes={{ root: classes.dialogTitle }}>
                <Toolbar className={classes.appbarToolbar}>
                    <Box display="flex" width="100%" alignItems={"center"}>
                        <Button onClick={handleHidePaymentEdit} className={classes.backButton} size="small" startIcon={<ArrowBackIcon />}>Back</Button>
                        <div className={classes.simpleTitle}>{type === "card" ? t("global:newCard") : t("global:newBankAccount")}</div>
                        <div className={classes.rightCell} />
                    </Box>
                </Toolbar>
            </DialogTitle>}
            <DialogContent>
                <div className={clsx({ [classes.dialogRoot]: type === "bank" })}>
                    {updatePaymentError && <Alert variant={"filled"} color={"error"}>{updatePaymentError}</Alert>}
                    <BillingInfoList allowSelect onlyCards={type === "card" ? true : false} onlyBanks={type === "bank" ? true : false} showEwallet newOnly hideHeader={!isDesktop}
                        onSaved={handleSavedPaymentEdit}
                        onCancel={handleHidePaymentEdit} />


                </div>
            </DialogContent>
        </Dialog>}
    </>;

});