import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { useStores } from "../../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import services from "../../../services";
import { useHistory } from "react-router";
import PageTitle from "../../../components/PageTitle";
import ShippingAddressForm from "../../../components/enrollment/shippingAddressForm";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: theme.spacing(1),
        position: "relative",
        marginBottom: theme.spacing(3),

    },
    card: {

    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    defaultMark: {
        marginRight: "auto",
        textTransform: "uppercase"
    }
}));

export default observer(function ShippingInfoEdit({ addressId, parentMatch, onSaved, onCancel }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const { userShippingInfoStore, userProfileStore } = useStores();
    const { addressForm, suggestions, addresses, editingAddress, addressesLoading, error } = userShippingInfoStore;
    const { allCountries, regionsByCountry } = userProfileStore;
    const params = useParams();
    const history = useHistory();
    const { addressId: paramsAddressId } = params;
    const [country, setCountry] = useState(null);

    const effectiveAddressId = useMemo(()=>{
        return (addressId!=null)?addressId : paramsAddressId
    },[addressId, paramsAddressId])

    let showDefault = false;

    if (editingAddress) {
        showDefault = !editingAddress.primaryAddress;
    }
    else {
        showDefault = addresses.length > 0;
    }


    const handleOnSaved = () => {
        if ( onSaved )
            onSaved()
        else
            history.push(parentMatch.url);
    };

    useEffect(() => {
        (async () => {
            let profile = await services.UserProfile.list();
            userProfileStore.loadAllCountries();
            await userProfileStore.loadRegionsByCountry(profile.address.country);
            setCountry(profile.address.country);
            await userShippingInfoStore.loadAddresses();
            userShippingInfoStore.setEditForm(profile.address.country, effectiveAddressId, handleOnSaved);
        })();

    }, []);

    return <>

        {(!country || addressesLoading || !addressForm)
            ? <CircularProgress />
            : <>
                <PageTitle>{t("shippingInformation")}</PageTitle>
                <ShippingAddressForm form={addressForm} country={country}
                    suggestions={suggestions}
                    showButtons={true} editCountry={false} readonlyCountry={true}
                    showDefault={showDefault}
                    error={error}
                    parentMatch={parentMatch} onCancel = {onCancel}
                    regionsByCountry={regionsByCountry}
                    allCountries={allCountries}   />
            </>
        }
    </>;
});