import {useStores} from "../hooks/use-stores";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Link as MuiLink, ListItem, ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import {joinPath} from "../utils/helpers";
import clsx from "clsx";
import React, {useState} from "react";
import {starFishColor} from "./styles";
import Collapse from "@material-ui/core/Collapse";




function getProps(item, parentMatch, match, path, history, url, collapsePath, commonStore, videoStore, authStore, shoppingBossStore, supportInfo ){
    const props = {}
    const {t} = useTranslation()


    const handleOpenAlbum  = (albumId) => {
        videoStore.setVimeoAlbum(albumId)
    }

    const handleVideo  = (videoId) => {
        videoStore.setMedia({ vimeo: true, url: videoId})
    }

    let isUrlAbsolute = url && ( url.startsWith("http://") || url.startsWith("https://") )

    if ( item && item.action ==="void"){
        props.onClick = ()=>{}
        props.component = Box
    }
    if ( item && item.loginGuid &&  url ) {
        url =  url + (url.indexOf("?")>-1?"&":"?") + `loginguid=${authStore.loginGuid}`
    }

    if ( item && item.tag === "thing2travel" ) {
        url = `https://secure.rezserver.com/ivm/inbound/?refid=8827&mk=363497f7ed862911d49ee491479413c635e077836ed9cd6d423824c62441053df717929c41b48fbd&refclickid=${authStore.currentUser.userID}`;
    }
    if ( item && item.tag === "logout" ) {
        props.onClick = ()=> { authStore.logout(); history.push("/") }
        props.component = MuiLink
    }

    else if ( item && item.action ==="void"){
        props.onClick = ()=>{}
        props.component = MuiLink
    }
    else if ( item && item.tag === "step-starfish" ) {
        props.onClick = ()=> {
            commonStore.showConfirm( t("Congratulations, you are a Starfish Subscriber")
                , "OK"
                , t("Congratulations"),  ()=>{}, null );
        }
        props.component = MuiLink
    }
    else if ( item && item.tag === "step-top-customer" ) {
        props.onClick = ()=> {
            if( shoppingBossStore.eAcceleratorValid  ){
                commonStore.showConfirm( t("Congratulations, you are already a Top Customer")
                    ,  "OK"
                    , t("Congratulations"),  ()=>{}, null );
            }
            else{
                history.push("/royalstarfish/royal/accelerator")
            }
        }
        props.component = MuiLink
    }  else if ( item && item.tag === "step-member" ) {
        props.onClick = ()=> {
            if( authStore.currentUser.isMember  ){
                commonStore.showConfirm( t("Congratulations, you are already a Member")
                    ,  "OK"
                    , t("Congratulations"),  ()=>{}, null);
            }
            else if( !supportInfo.isMemberEnrollmentAvailable  ){
                commonStore.showConfirm( t("global:becomeAMember24HoursWait")
                    ,  "OK"
                    , t("global:becomeAMember"),  ()=>{}, null);
            }
            else{
                history.push("/member/enroll")
            }
        }
        props.component = MuiLink
    }
    else if ( item && item.albumID){
        props.onClick = ()=> handleOpenAlbum( item.albumID )
        props.component = MuiLink
    }
    else if ( item && item.videoID){
        props.onClick = ()=> handleVideo( item.videoID )
        props.component = MuiLink
    }
    else if ( url && isUrlAbsolute &&  item &&  ( newTabTags.includes( item.tag ) || item.target === "_blank" ) ){
        props.target = "_blank"
        props.href = url
        props.component = MuiLink
    }
    else if(url && isUrlAbsolute && url.startsWith( "https://my.lifeinfoapp.com") && item && !fullScreenFramePages.includes( item.tag )  ) {

        props.component = Link
        let targetPath = (url).replace("https://my.lifeinfoapp.com/",
            parentMatch.path.endsWith('/')
                ? parentMatch.path
                : parentMatch.path + '/' )
        if ( collapsePath ){
            let parts =  targetPath.split('/')
            let result = []
            parts.forEach( (part, index)=>{
                if ( part != result[result.length-1] )
                    result.push(part)

            })
            targetPath  = result.join('/')
        }
        props.to = targetPath
    }
    else if(url && isUrlAbsolute && url.startsWith( "https://shop.lifeinfoapp.com") && ( !item || item.tag !=='accelerator' ) ) {

        let prefix;
        if ( authStore.subscriptions.hasSuperAppPlus ){
            prefix = "/royalstarfish"
        } else if (  authStore.isStarfish  ) {
            prefix = "/starfish"
        }

        props.component = Link
        let targetPath = (url).replace("https://shop.lifeinfoapp.com/",
            prefix+ "/shop/" )
        props.to = targetPath
    }
    else if (path) {
        props.component = Link
        props.to = match ? joinPath(match.path, path) : path
    }

    return props;
}


const useStyles = makeStyles((theme) => ({
    menuItem:(props)=> ({
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        textDecoration: 'none',
        color: 'white',
        cursor: props?.action ==="void" ? "default": 'pointer'
    }),
    redBorder: {
        border: '1px solid #007BFF',
        borderRadius: '20px'
    },
    nested: {
        marginLeft: theme.spacing(4),
    },
    menuTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    backLink:{
        ...starFishColor()
    }
}));

const newTabTags = ["thing2travel"];
const fullScreenFramePages = ["autoship"];

const MenuItem = ({ item, title, subtitle, path, url, match, parentMatch, isBack, onClick, collapsePath  }) => {
    const classes = useStyles({action: item?.action});
    const {commonStore, authStore, videoStore, supportStore, shoppingBossStore} = useStores();
    const { supportInfo } = supportStore;
    const history = useHistory();
    const [open, setOpen]= useState(false)

    const {t} = useTranslation("starfish");
    const props = {onClick}
    const expandable = item && item.type==="expandable" && item.subItems && item.subItems.length > 0
    if ( expandable ){
        props.onClick = ()=> setOpen(!open)
        props.component = MuiLink
    }
    else {
        Object.assign(props, getProps(item, parentMatch, match, path, history, url, collapsePath, commonStore, videoStore, authStore, shoppingBossStore, supportInfo))
    }

    const translationKey = title && title.replace(/\s+/g, "_").replace(/\.+/g, "_").toLowerCase()
    let translatedTitle = t(`starfish:${translationKey}`)
    let isTranslated = translatedTitle !== translationKey
    if (!isTranslated && item &&  item.tag ){
        translatedTitle = t(`starfish:${item.tag}`)
        isTranslated = translatedTitle !== item.tag
    }


    return (
        <>
            <Box {...props} className={clsx(classes.menuItem, classes.redBorder, { [classes.backLink]: isBack })}>
                <Box display="flex" alignItems={"center"}>
                    <Typography className={classes.menuTitle} variant="h4">{isTranslated ? translatedTitle: title }</Typography>
                </Box>
                {subtitle && <Box>
                    <Typography variant="body1">{subtitle}</Typography>
                </Box>
                }
            </Box>
            {expandable && <>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {item.subItems.map( s=> {
                            const subProps =  getProps(s, parentMatch, match, joinPath( path, s.tag ), history, url, collapsePath, commonStore, videoStore, authStore, shoppingBossStore, supportInfo)
                            return <Box  {...subProps} button className={clsx(classes.menuItem, classes.redBorder, classes.nested )}>
                            {s.title}
                        </Box>})}
                    </Collapse>
                </>
            }

        </>

    );
};


export default MenuItem;
