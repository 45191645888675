import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useStores} from "../../hooks/use-stores";
import {Box, CircularProgress, Collapse, ListItemText, MenuItem, Select} from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import PrimarySwitch from "../../components/PrimarySwitch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import yellowText from "../../themes/yellowText";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import StyledTableRow from "../../components/StyledTableRow";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TransactionInfo from "../Profile/Transactions/TransactionInfo";
import DialogActions from "@material-ui/core/DialogActions";
import services from "../../services";
import {greenColor, redColor} from "../../components/styles";
import {useLocalObservable} from "mobx-react-lite";
import {formatNumberOrZero} from "../../utils/helpers";
import range from "lodash/range";
import clsx from "clsx";

const formatterUSD = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

const useStyles = makeStyles((theme) => ({
    amountCell: {
        gridTemplateColumns: "120px [col-start] 50px [col-start]"
    },
    greenLabel: {
        ...greenColor(theme),
        gridColumn: 1
    },
    total: {
        gridColumnStart: "col-start 1"

    },
    amountAdditionalValue: {
        gridColumn: 2
    },
    deposit: { ...greenColor(theme) },
    withdrawal: { ...redColor(theme) },
    pendingEWallet: {
        marginBottom: theme.spacing(5),
    },
    filter: {
        gap: theme.spacing(5),
    },
    inputLabel: {
        fontSize: '1.2rem'
    },
    receiptButtons:{
        gap:theme.spacing(2),
        alignItems:"flex-end",
        [theme.breakpoints.down('sm')]: {
            alignItems:"flex-start",
            paddingBottom:theme.spacing(2)
        }
    },
    green:{
        color:"#47a447"
    },
    table:{

        "& .MuiTableCell-root":{
            padding: 8
        }
    }

}));

function EWalletTransactions(){
    const classes = useStyles();
    const { t } = useTranslation("transactions");
    const { authStore, commonStore, statStore, transactionsStore } = useStores();
    const [selectedYear, setSelectedYear] = useState(2025);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [showReceipt, setShowReceipt] = useState(null);
    const { currentUser } = authStore;
    const { giftCardsStats } = statStore;

    useEffect(() => {
        transactionsStore.loadEwalletTransactions({ year: selectedYear, month: selectedMonth });
    }, [ selectedYear, selectedMonth]);

    const { eWalletPendingTransactions, summary, eWalletTransactions, eWalletTransactionsLoading, allTransactionDetails } = transactionsStore;

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const downloadCSV = async () => {
        try {
            const csvResponse = await services.UserProfile.Wallet().Transactions().export(selectedYear, selectedMonth);

            let hiddenDownloadLink = document.createElement('a');
            const blob = new Blob([csvResponse], { type: 'text/csv' });
            hiddenDownloadLink.href = window.URL.createObjectURL(blob);
            hiddenDownloadLink.download = 'transactions.csv';
            hiddenDownloadLink.click();
        } catch (e) {
            commonStore.warn("There was an error downloading your file. Please try again.");
        }
    };

    const toggleOpenDetails = (groupID) => {
        if ( !allTransactionDetails[groupID]) {
            transactionsStore.loadTransactionDetails(groupID);
        }
        if (localStore.open === groupID) {
            localStore.setOpen(-1);
        } else {
            localStore.setOpen(groupID);
        }
    };

    const localStore = useLocalObservable(() => ({
        transactionsTypeFilter: -1,
        open: -1,
        setTransactionsTypeFilter(value) {
            this.transactionsTypeFilter = value;
        },
        setOpen(value) {
            this.open = value;
        },
        get effectiveItems() {
            /*
                It's possible that the current filter doesn't exist for the new result set.
               Attempting to filter on a non-existant type would return nothing and cause a blank to show
               in the type dropdown. If the type doesn't exist in the new set, fall back to showing 'all'
            */
            if (!transactions.some(t => t.transactionTypeID == this.transactionsTypeFilter)) {
                this.transactionsTypeFilter = -1;
            }
            if (this.transactionsTypeFilter === -1) {
                return transactions;
            }
            else
                return transactions.filter(item => item.transactionTypeID === this.transactionsTypeFilter);
        },

        get details() {
            return allTransactionDetails[this.open]  &&  allTransactionDetails[this.open].orderDetails;
        },
        get detailsProducts() {
            return allTransactionDetails[this.open]  &&  allTransactionDetails[this.open].products;
        },
        get trackingInfo() {
            return allTransactionDetails[this.open] && allTransactionDetails[this.open].tracking
        }
    }));

    return <>
        {(eWalletTransactionsLoading || eWalletTransactionsLoading == null)
            ? <CircularProgress />
            : <>
                {eWalletPendingTransactions.length > 0 && <>
                    <PageTitle>{t("pendingeWalletTransactions")}</PageTitle>
                    <TableContainer className={classes.pendingEWallet}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("date")}</TableCell>
                                    <TableCell>{t("type")}</TableCell>
                                    <TableCell align="right">{t("amount")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eWalletPendingTransactions.map(p => {
                                    return <TableRow key={p.transactionID}>
                                        <TableCell>{moment.utc(p.orderDate).format("MM/DD")}</TableCell>
                                        <TableCell>{p.transactionName}</TableCell>
                                        <TableCell align="right" className={clsx({
                                            [classes.deposit]: p.total > 0,
                                            [classes.withdrawal]: p.total < 0
                                        })}>
                                            {p.total > 0 ? "+$" : "-$"}{p.total}</TableCell>
                                    </TableRow>;
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
                <PageTitle>{t("transactionsHistory")}</PageTitle>
                <Box display="flex" justifyContent="flex-end" className={classes.filter}>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("month")}</InputLabel>
                        <Select value={selectedMonth} onChange={handleMonthChange} label={t("month")} style={{textTransform:"capitalize"}} >
                            <MenuItem value={-1}>{t("all")}</MenuItem>
                            {range(1, 13).map(m => <MenuItem key={m} value={m} style={{textTransform:"capitalize"}}>
                                {new Date( 2000, m-1).toLocaleString(commonStore.language, { month: "long" })}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("year")}</InputLabel>
                        <Select value={selectedYear} onChange={handleYearChange} label={t("year")}>
                            <MenuItem value={2025}>2025</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                        </Select>
                    </FormControl>
                    {!commonStore.isNativeApp && <Button onClick={downloadCSV} startIcon={<CloudDownloadIcon />}>{t("export")}</Button>}
                </Box>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell />*/}
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("name")}</TableCell>
                                <TableCell align="right">{t("amount")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eWalletTransactions.map((row) => (
                                <>
                                    <TableRow key={row.transactionID} onClick={() =>!row.isDeposit && toggleOpenDetails(row.orderID)}>
                                        {/*<TableCell>*/}
                                        {/*    { row.TransactionName !==  "Adjustment"  && <IconButton aria-label="expand row" size="small"*/}
                                        {/*                                     onClick={(e) =>  {*/}
                                        {/*                                         toggleOpenDetails(row.GroupID)*/}
                                        {/*                                         e.stopPropagation();*/}
                                        {/*                                         e.preventDefault();*/}
                                        {/*                                     }}>*/}
                                        {/*        {localStore.open === row.GroupID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
                                        {/*    </IconButton>}*/}
                                        {/*</TableCell>*/}
                                        <TableCell>
                                            {moment.utc(row.orderDate).format("MM/DD")}
                                        </TableCell>
                                        <TableCell>
                                            <Box  display={"flex"}  alignItems={"center"} style={{gap:8}}>
                                                {row.imageUrl && <img src={row.imageUrl} width={50}/>  }
                                                {row.transactionName}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box display={"flex"} flexDirection={"column"}>
                                                <Typography>${formatterUSD.format(row.total)}</Typography>
                                                {row.TransactionName !==  "Adjustment" &&  <>
                                                {row.cashEarned>0  && <Typography variant={"caption"}>{t("perksEarned")}: <span className={classes.green}> ${formatterUSD.format(row.cashEarned)}</span></Typography>}
                                                {row.cashEarnedAdditional>0  &&     <Typography variant={"caption"}>{t("additionalPerks")}: <span className={classes.green}> ${formatterUSD.format(row.cashEarnedAdditional)}</span></Typography>}

                                                </>}

                                            </Box>

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={"100%"}>
                                            <Collapse in={localStore.open === row.GroupID} timeout="auto" unmountOnExit>
                                                {!localStore.details
                                                    ? <CircularProgress />
                                                    : <Box margin={1}>
                                                        <Grid container spacing={2}>
                                                            {
                                                                (row.orderStatusID === 110 || row.orderStatusID  === 111 ) &&
                                                                <ThemeProvider theme={yellowText}>
                                                                    <Grid container item sm={12} direction="column" alignItems={"flex-start"}  style={{padding:10, paddingLeft:0}}>
                                                                        { row.orderStatusID  === 110  && <Typography color={"primary"} >This order was fully refunded on {moment(row.refundDate).format("YYYY-MM-DD")}</Typography>}
                                                                        { row.orderStatusID  === 111  && <Typography color={"primary"} >This order was partially refunded  on {moment(row.refundDate).format("YYYY-MM-DD")}</Typography>}
                                                                    </Grid>
                                                                </ThemeProvider>
                                                            }
                                                            <Grid container item sm={5} direction="column">
                                                                <Typography variant={"h6"}>{t("global:shippingInfo")}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddressName}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddress1}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddress2}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingCity} {row.shippingState} {row.shippingZip} {row.shippingCountry}</Typography>
                                                            </Grid>
                                                            <Grid container item sm={5} direction="column">
                                                                <Typography variant={"h6"}>{t("global:billingInfo")}</Typography>
                                                                <Grid container item direction="column">
                                                                    { localStore?.details?.AmountEWallet >0 &&  <Typography variant={"body1"}>{t("cashRedeemed")}: {formatterUSD.format(localStore?.details?.AmountEWallet )}</Typography>}
                                                                    { localStore?.details?.AmountCC >0 && <Typography variant={"body1"}>{t("cc")}: {formatterUSD.format(localStore?.details?.AmountCC )}  ( {t("last4")} {row.last4} )</Typography>}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item sm={2} direction="column" className={ classes.receiptButtons}>
                                                                <Button variant={"contained"} color={"primary"} onClick={ ()=>{setShowReceipt(row.GroupID) }}>Show Receipt</Button>
                                                                <Button variant={"contained"} color={"primary"} to={`/order/${row.GroupID}`} target={"_blank"} component={RouterLink} >Print Receipt</Button>
                                                            </Grid>
                                                        </Grid>
                                                        {(localStore.trackingInfo && localStore.trackingInfo.length>0) ? <Grid container>
                                                            <Grid container item sm={6}>
                                                                <Box display={"grid"} gridTemplateColumns={"auto 1fr"} gridRowGap={3} gridColumnGap={8}>
                                                                    <Box ><Typography>Tracking#</Typography></Box>
                                                                    {( localStore.trackingInfo||[]).map((t, i) => {
                                                                        return  <Link style={{gridColumnStart:2, alignSelf:"center"}} key={i} href={t.TrackingLink}
                                                                                      target={"_blank"}> {t.TrackingNumber}</Link>

                                                                    })}
                                                                </Box>
                                                            </Grid>
                                                        </Grid> : null}
                                                        <Table size={"small"}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell align="right">{t("qty")}</TableCell>
                                                                    <TableCell align="right">{t("cost")}</TableCell>
                                                                    <TableCell align="right">{t("amount")}</TableCell>
                                                                    <TableCell align="right">{t("pv")}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {localStore.detailsProducts && localStore.detailsProducts.map((d, i) => {
                                                                    return <StyledTableRow key={i}>
                                                                        <TableCell>
                                                                            {d.ImageUrl && <Avatar variant={"rounded"} src={d.ImageUrl} alt={d.ProductName}
                                                                                                   style={{ width: "75px", height: "75px" }} />}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText primary={d.ProductName}
                                                                                          secondary={
                                                                                              `${d.SKU ? `SKU: ${d.SKU}` : ""}
                                                                                              | Format: ${d.IsDigital ? "Digital" : "Physical"} 
                                                                                              | Price: $${d.UnitCost.toFixed(2)}
                                                                                                ${currentUser.isMember ? `PV: ${d.UnitPV.toFixed(2)}` : ''}`


                                                                                          } />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {d.Quantity}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {d.UnitCost.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {(d.TotalCost).toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {d.TotalPV.toFixed(2)}
                                                                        </TableCell>
                                                                    </StyledTableRow>;
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                }
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box paddingTop={4}>
                    {t("numberOfGiftCards")} { summary.TotalOrders }
                </Box>
                <Box paddingTop={2}>
                    {t("totalSpent")} ${formatNumberOrZero(summary.TotalSpend, 2 )}
                </Box>
                <Box paddingTop={2} paddingBottom={5}>
                    {t("totalPerks")} ${formatNumberOrZero( summary.TotalPerks, 2 )}
                </Box>
            </>}

        {showReceipt && <Dialog open={showReceipt} fullWidth={true} maxWidth={"md"}

                                onClose={()=>setShowReceipt(null)}>
            <DialogContent>
                <TransactionInfo  transactionDetails={{ orderID: showReceipt, ...allTransactionDetails[showReceipt]}} onClose={()=>setShowReceipt(null)} />
            </DialogContent>
            <DialogActions>

                <Button onClick={()=>setShowReceipt(null)} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>}
    </>
}


export default observer(EWalletTransactions);