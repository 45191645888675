import { observer } from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {CircularProgress, MenuItem, Select} from "@material-ui/core";
import { runInAction } from "mobx";
import { useStores } from "../../hooks/use-stores";

const useStyles = makeStyles((theme) => ({
    cartSummary: {
        width: "100%",
        backgroundColor: "#f8f8f8",
        padding: 7
    },

    itemCheckBox: {
        marginLeft: -32,
        minWidth: "auto"
    },
    icon: {
        cursor: "pointer"
    },
}));


function CartSection({ items, title, onSwitchAlternative, onRemoveAlternative, onDelete, onIconClick, isStarfishSignup, removingItemToCart=[], addingItemsToCart=[] }) {
    const {enrollmentStore} = useStores();
    const classes = useStyles();
    const { t } = useTranslation();

    const chooseSku = async (item, sku) => {
        // Allow toggling of sku
        if (sku === enrollmentStore.effectiveProductSKU) {
            await onRemoveAlternative(item, sku);
        } else {
            await onSwitchAlternative(item, sku);
        }
    }

    const subTotal = useMemo(() => {

        const r =  items.reduce((a, x) => {
            return a + ((!x.removed && x.totalCost) ? x.totalCost : 0);
        }, 0);
        return r
    }, [items]);

    const altSubtotal = useMemo(() => {
        // Only one alternative can be selected at a time
        const selectedProduct = items.find(i => i.effectiveProductSKU == enrollmentStore.effectiveProductSKU);

        return selectedProduct?.totalCost ?? 0
    }, [items])

    return <>
        <Typography variant={"subtitle2"}>{title}</Typography>
        <List>
            {items.map( (x, index) => {
                if (isStarfishSignup && x.alternatives) {
                    return (
                        <>
                            {x.alternatives?.map((a,i) =>
                                <ListItem key={ `${a.productSKU}-sku-${i}` }>
                                    <ListItemIcon className={classes.itemCheckBox}>
                                        <Checkbox
                                            style={{ color: '#007BFF' }}
                                            edge="start"
                                            checked={enrollmentStore.effectiveProductSKU === a.productSKU}
                                            disabled={!!enrollmentStore.effectiveProductSKU && enrollmentStore.effectiveProductSKU !== a.productSKU}
                                            onClick={e => { chooseSku(x, a.productSKU); }}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemAvatar>
                                        <Avatar variant={"square"}
                                            className={classes.icon}
                                            onClick={e => { onIconClick(a); }}
                                            alt={`icon`}
                                            src={x.imageURL}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={x.productTitleAjusted || x.productTitle}
                                        secondary={
                                            a.productPrice
                                        }
                                    />
                                </ListItem>
                            )
                            }
                            <ListItem>
                                <ListItemText primary={t("enrollment:subtotal")} />
                                <ListItemSecondaryAction>
                                    <ListItemText primary={`$${altSubtotal.toFixed(2)}` || "$0.00"} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </>
                    )
                }

                return (
                        <ListItem key={ `${x.productID}-id-${index}` }>
                            {x.canDelete && <ListItemIcon className={classes.itemCheckBox}>
                                <>
                                {addingItemsToCart.includes(x.productID)
                                    ? <CircularProgress size={20}/>
                                    : <Checkbox
                                    style={{ color: '#007BFF' }}
                                    edge="start"
                                    checked={!x.removed}
                                    disabled={ x.canAdd===false}
                                    onClick={e => { onDelete(x, true); }}
                                    tabIndex={-1}
                                    disableRipple
                                />}
                                </>
                            </ListItemIcon>}
                            <ListItemAvatar>
                                <Avatar variant={"square"}
                                    className={classes.icon}
                                    onClick={e => { onIconClick(x); }}
                                    alt={`icon`}
                                    src={x.imageURL}
                                />
                            </ListItemAvatar>
                            {
                                // !isStarfishSignup
                                // ?
                                <>
                                    <ListItemText primary={x.productTitleAjusted || x.productTitle }
                                        secondary={`${ x.customPrice ? x.customPrice: x.productPrice}`} />
                                </>
                                // :
                                // <>
                                //     <ListItemText primary={x.productTitle}
                                //         secondary={
                                //             <>
                                //                 {x.alternatives
                                //                     ? <select variant={"filled"}
                                //                         onChange={e => { onSwitchAlternative(x, e.target.value) }}
                                //                         value={x.effectiveProductSKU || x.productSKU}>
                                //                         {x.alternatives.map(a => {
                                //                             return <option selected={x.effectiveProductSKU === a.productSKU || x.productSKU === a.productSKU}
                                //                                 key={a.productSKU}
                                //                                 value={a.productSKU}
                                //                             >{a.productPrice}</option>
                                //                         })}
                                //                     </select>
                                //                     : <>{x.productPrice}</>}
                                //             </>}
                                //     />

                                // </>
                            }
                            <ListItemSecondaryAction>
                                {removingItemToCart.includes(x.productID)
                                    ? <CircularProgress  size={20}/>
                                    :<>
                                        {(x.canDelete && !x.removed) && <IconButton style={{ color: '#007BFF' }} onClick={e => { onDelete(x, false); }}><DeleteIcon /></IconButton>}
                                    </>
                                }
                                <select value={x.quantity} disabled>
                                    <option>{x.quantity}</option>
                                </select>
                            </ListItemSecondaryAction>
                        </ListItem>

                )
            })
            }
            { !(items.length===1 && items[0].alternatives &&  items[0].alternatives.length>0) && <ListItem>
                <ListItemText primary={t("enrollment:subtotal")} />
                <ListItemSecondaryAction>
                    <ListItemText primary={`$${subTotal.toFixed(2)}` || "$0.00"} />
                </ListItemSecondaryAction>
            </ListItem>}
        </List>
    </>;

}

export default observer(CartSection);