import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import { updateCardRules, updatePostalCodeRules } from "./helpers";


const fields = {
    subject: {
        label: 'Subject',
        rules: 'string|required',
        placeholder: 'subject',
    },
    message: {
        label: 'Message',
        rules: 'string|required',
        placeholder: 'message',
    }
};



export default function create(initials, hooks) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }
    const form = new MobxReactForm({ fields: f }, { plugins, hooks });
    return form;

}

