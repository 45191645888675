import {
  Box, CircularProgress,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid, InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, {useMemo, useState} from "react";
import range from "lodash/range";
import LabeledTextField from "./LabeledTextField";
import UplineSearch from "../../Members/controls/UplineSearch";
import FormControl from "@material-ui/core/FormControl";
import ErrorMessage from "../../Lifeline/CreateVideo/ErrorMessage";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  grid: {
    overflow: "hidden",
  },
  dialog: {
    padding: "4rem",
  },
  formLabels: {
    fontWeight: "bold",
  },
  lifeNumber: {
    width: "16rem",
    marginRight: "2rem",
  },
  textField:{
    margin: "0 !important",
  }
});

/**
 * @typedef {Array<{value: string, label: string}>} SelectOptions
 */

/**
 * @type {SelectOptions}
 */
const transferTargetOptions = [
  { label: "Spouse", value: "spouse" },
  { label: "Guest", value: "guest" },
  { label: "Member", value: "member" },
];



export function TransferTicketDialog({ currentUser, open, handleCloseDialog, handleTransfer,item, transferringTicket, transferTicketError }) {
  const classes = useStyles();
  const {t} = useTranslation("starfish");
  const [transferTarget, setTransferTarget] = useState(transferTargetOptions[0].value);
  const [qty, setQty] = useState(1);
  const [firstNameError, setFirstNameError] = useState( "");
  const [lastNameError, setLastNameError] = useState( "");
  const [emailError, setEmailError] = useState( "");

  const [firstName, setFirstName] = useState(currentUser?.spouse?.firstName || "");
  const [lastName, setLastName] = useState(currentUser?.spouse?.lastName || "");
  const [email, setEmail] = useState(currentUser?.spouse?.email || "");
  const [memberId, setMemberId] = useState(currentUser?.userID || null);

  const quantityOptions = useMemo(() => {
    return range(1, item.TicketQty + 1).map((i) => ({ label: i, value: i}));
  },[item])

  const handleUserSelectFromSearch = (user) => {
    setFirstName(user.FirstName);
    setLastName(user.LastName);
    setEmail(user.Email);
    setMemberId(user.UserID)
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
  }

  const handleTransferTargetChange = (event)=>{
    setTransferTarget(event.target.value);
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    switch (event.target.value) {
        case "spouse":
            setFirstName(currentUser?.spouse?.firstName || "");
            setLastName(currentUser?.spouse?.lastName || "");
            setEmail(currentUser?.spouse?.email || "");
            setMemberId(currentUser?.userID || null);
            break;
        case "guest":
            setFirstName("");
            setLastName("");
            setEmail("");
            setMemberId(currentUser?.userID || null)
            break;
        case "member":
            setFirstName("");
            setLastName("");
            setEmail("");
            setMemberId(null)
            break;

    }
  }
  const handleQtyChange = (event)=>{
    setQty(event.target.value);
  }
  const handleFirstNameChange = (event)=>{
    setFirstName(event.target.value);
    setFirstNameError( event.target.value? "": "First Name is required");
  }
  const handleLastNameChange = (event)=>{
    setLastName(event.target.value);
    setLastNameError( event.target.value? "": "Last Name is required");
  }
  const handleEmailChange = (event)=>{
    setEmail(event.target.value);
    if ( !event.target.value ){
      setEmailError(  "Email is required");
    }
    else
    {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if ( !emailRegex.test(event.target.value) ){
            setEmailError("Invalid Email");
        }
        else{
            setEmailError("");
        }
    }


  }

  const onHandleTransfer = () => {
    if( transferTarget === "guest" && (!firstName) ){
       setFirstNameError(t("tickets.First Name is required"));
    }
    if( transferTarget === "guest" && (!lastName) ){
        setLastNameError(t("tickets.Last Name is required"));
    }
    if( transferTarget === "guest" && (!email) ){
        setEmailError(t("tickets.Email is required"));
    }

    if (transferTarget !== "guest" || ( firstName &&  lastName && email )) {

      handleTransfer({
        transferTarget,
        qty,
        firstName,
        lastName,
        email,
        toUserID: memberId,
        ticketID: item.TicketID,
        eventID: item.EventID
      });
    }
  }

  return (
    <div>
      <Dialog
        className={classes.dialog}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("tickets.Transfer Ticket")}</DialogTitle>
        <DialogContent dividers>

          <Box display={"flex"} style={{gap: 16}} flexDirection={"column"} >

              <Box display={"flex"} style={{gap: 4}} flexDirection={"column"} >
                <Typography variant={"body1"}>{t("tickets.Target")}</Typography>
                <TextField
                    classes={{root: classes.textField}}
                    select
                    variant="outlined"
                    margin={"dense"}
                    value = {transferTarget}
                    onChange={handleTransferTargetChange}
                    fullWidth
                >
                  {transferTargetOptions.map((option, index) => {
                  return <MenuItem value={option.value}
                                   key={option.value}>{option.label}</MenuItem>;
                })}
              </TextField>
            </Box>

            {transferTarget === "member"  && <Box display={"flex"} style={{gap: 4}} flexDirection={"column"} >
              <Typography variant={"body1"}>{t("tickets.Member")}</Typography>
              <UplineSearch popup disabled={transferTarget !== "member"} onlyId={false} noPadding show={true} handleUserSelectFromSearch={handleUserSelectFromSearch}  enableUplineSearch={true}/>
            </Box>}

            <Box display={"flex"} style={{gap: 4}} flexDirection={"column"} >
              <Typography variant={"body1"}>{t("global:firstName")}</Typography>
              <TextField
                  classes={{root: classes.textField}}
                  variant="outlined"
                  margin={"dense"}
                  value = {firstName}
                  onChange={handleFirstNameChange}
                  disabled={transferTarget !== "guest"}
                  fullWidth
                  error={transferTarget === "guest" && firstNameError}
                  helperText={transferTarget === "guest" && firstNameError}
              />
            </Box>
            <Box display={"flex"} style={{gap: 4}} flexDirection={"column"} >
              <Typography variant={"body1"}>{t("global:lastName")}</Typography>
              <TextField
                  classes={{root: classes.textField}}
                  variant="outlined"
                  margin={"dense"}
                  value = {lastName}
                  onChange={handleLastNameChange}
                  disabled={transferTarget !== "guest"}
                  fullWidth
                    error={transferTarget === "guest" && lastNameError}
                    helperText={transferTarget === "guest" && lastNameError}
              />
            </Box>
            <Box display={"flex"} style={{gap: 4}} flexDirection={"column"} >
              <Typography variant={"body1"}>{t("global:email")}</Typography>
              <TextField
                  classes={{root: classes.textField}}
                  variant="outlined"
                  margin={"dense"}
                  value = {email}
                  onChange={handleEmailChange}
                  disabled={transferTarget !== "guest"}
                  inputProps={{type: "email"}}
                  fullWidth
                    error={transferTarget === "guest" && emailError}
                    helperText={transferTarget === "guest" && emailError}
              />
            </Box>

            <Box display={"flex"} style={{gap: 4}} flexDirection={"column"} >
              <Typography variant={"body1"}>{t("tickets.Qty")}</Typography>
              <TextField
                  classes={{root: classes.textField}}
                  select
                  variant="outlined"
                  margin={"dense"}
                  value = {qty}
                  onChange={handleQtyChange}

                  fullWidth
              >
                {quantityOptions.map((option, index) => {
                  return <MenuItem value={option.value}
                                   key={option.value}>{option.label}</MenuItem>;
                })}
              </TextField>
            </Box>
            {transferTicketError && <Alert severity="error" variant={"filled"}>{transferTicketError}</Alert>}

          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog} color="primary">
            {t("global:cancel")}
          </Button>
          <Button variant="contained" onClick={onHandleTransfer} color="primary" disabled={transferringTicket}
          startIcon={transferringTicket && <CircularProgress size={20} />}
          >
            {t("tickets.Transfer")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

