import React, {Fragment, useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import MediaList from "../components/MediaList";
import SpeakersList from "../components/SpeakersList";
import LibraryTitle from "../components/LibraryTitle";
import {useTranslation} from "react-i18next";
import compact from "lodash/compact";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "white",
    },
    margin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    divider: {
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    colorWhite: {
        color: "white",
    },
    grid: {

        "&:after": {
            content: '""',
            flex: "auto"
        }
    },

    btnSeries: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        textDecoration: "none"
    },


    seriesIcon: {
        borderRadius: "50%",
        width: "95%"
    },
    lineClamp: {
        margin: "5px 0",
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.5em",
    },

    notchedOutline: {
        borderColor: "white"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },


}));



export default observer(function LibraryPack({ packID, prefix, packIDs, noreset, hideSpeakers, hideLanguageSelector }) {
    const classes = useStyles();
    const { commonStore, packsStore, audioStore, mediaHandlers, authStore } = useStores();
    const params = useParams();
    const { packId = packID } = params;
    const { books, speakers, packs, audios, videos, sections, mediaType, title, imageURL, packLoading, error } = packsStore;
    const { mediaID, audioProgress, audioPaused, canBuy } = audioStore;
    const { loginGuid } = authStore;
    const [ lang, setLang ] = useState("en");
    const {t} = useTranslation();

    const selectedPackId = useMemo(()=>{
        if ( packIDs){
            return packIDs[lang]
        }
        else return packId;
    }, [lang, packId, packIDs]);

    useEffect(() => {
        if ( noreset ) return;
        return () => {
            packsStore.reset();
        }
    },[])

    useEffect(() => {
        console.log("loading pack", selectedPackId);
        (async () => {
            if (selectedPackId)
                await packsStore.loadPack(selectedPackId);
            packsStore.setMediaType(sections[0] && sections[0].mediaType);
        })();
    }, [selectedPackId]);

    const handleTabChange = (event, newValue) => {
        packsStore.setMediaType(newValue);
    };


    const effectiveMediaType = mediaType || (sections[0] && sections[0].mediaType);

    return <div className={classes.root}>


        {error ? <Alert severity="error">{error}</Alert> : null}

        {packLoading ? <CircularProgress />
            : <>
                <LibraryTitle title={title}
                              imageURL={imageURL}
                              speakers={commonStore.onStarfishOrRoyalStarfish ? `${t("with")} ${ compact( (speakers || []).map(sp => sp.speakerName) ).join(", ")}` : ''}
                              languageSelector = {!hideLanguageSelector && !!packIDs}
                              language = {lang}
                              onLanguageChange = {setLang}
                />
                {effectiveMediaType && <TabContext value={effectiveMediaType}>
                    <AppBar position="static">
                        <Tabs onChange={handleTabChange} value={effectiveMediaType} indicatorColor="primary">
                            {sections.map(x => {
                                if( x.mediaType === "speaker" && hideSpeakers) return null;
                                return <Tab key={x.mediaType} value={x.mediaType} label={ t(x.title)} selected={x.mediaType === mediaType} />;
                            })}

                        </Tabs>
                    </AppBar>
                    <TabPanel value={"audio"} >
                        <MediaList currentMediaID={mediaID} items={audios}
                            onAudioPlay={mediaHandlers.handleAudioPlay}
                            audioPaused={audioPaused}
                            loginGuid={loginGuid}
                            canBuy={canBuy}
                            audioProgress={audioProgress}
                            onAudioPause={mediaHandlers.handleAudioPause} />
                    </TabPanel>
                    <TabPanel value={"video"} >
                        <MediaList items={videos}
                            onVideoOpen={mediaHandlers.handleVideoOpen}
                        />
                    </TabPanel>
                    <TabPanel value={"book"}>
                        <MediaList items={books} onOpenBook={mediaHandlers.handleBookOpen} />
                    </TabPanel>
                    <TabPanel value={"pack"}>
                        <MediaList items={packs} />
                    </TabPanel>
                    <TabPanel value={"speaker"} >
                        <SpeakersList items={speakers} prefix={prefix} />
                    </TabPanel>
                </TabContext>}

            </>}

    </div>;
});