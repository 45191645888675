import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import {makeAutoObservable, reaction} from "mobx";
import sumBy from "lodash/sumBy";


class PeriodsStore {
    volumeDataLoading = null;
    volumeData = {}
    PVPeriodID=null
    constructor(periodsStore, authStore) {
        makeAutoObservable(this);

        this.periodsStore = periodsStore;
        this.authStore = authStore;

        reaction( ()=> this.periodsStore.periods.slice(),
            periods => {
                if (periods.length > 1) {
                    let beforeTimeSwitch = false

                    const today = new Date()
                    if (today.getDate() === 1 && today.getHours() < 2) {
                        beforeTimeSwitch = true
                    }
                    if (today.getDate() < 3 && !beforeTimeSwitch)
                        this.setPVPeriodID(periods[1].PVPeriodID)
                    else
                        this.setPVPeriodID(periods[0].PVPeriodID)
                } else if (periods.length == 1) {
                    this.setPVPeriodID(periods[0].PVPeriodID)
                }
            })

    }

    get currentPeriodMemberSubtotal(){
            return sumBy( this.currentIsMemberList,  "Subtotal" )
    }

    get currentPeriodCustomerSubtotal(){
            return sumBy( this.currentIsCustomerList,  "Subtotal" )
    }

    get currentPeriodMemberShippingTax(){
            return sumBy( this.currentIsMemberList,  x=>(x.Shipping + x.Tax) )
    }

    get currentPeriodCustomerShippingTax(){
            return sumBy( this.currentIsCustomerList,  x=>(x.Shipping + x.Tax) )
    }

    get currentPeriodMemberTotal(){
            return sumBy( this.currentIsMemberList,  "Total")
    }

    get currentPeriodCustomerTotal(){
            return sumBy( this.currentIsCustomerList,  "Total")
    }

    get currentPeriodMemberPV(){
            return sumBy( this.currentIsMemberList,  "PV")
    }

    get currentPeriodCustomerPV(){
            return sumBy( this.currentIsCustomerList,  "PV")
    }

    get currentPeriodMemberGiftCards(){
        return sumBy( this.currentIsMemberList.filter(x=>x.TransactionName === "Gift Cards" ),  "Total")
    }

    get currentPeriodCustomerGiftCards(){
        return sumBy( this.currentIsCustomerList.filter(x=>x.TransactionName === "Gift Cards" ),  "Total")
    }


    get giftCardsCustomers(){
        if( this.volumeData  && this.volumeData.giftCardsCustomers )
            return this.volumeData.giftCardsCustomers
        return []
    }


    get currentIsMemberList(){
        if( this.volumeData  && this.volumeData.currentPeriod )
            return this.volumeData.currentPeriod.filter( item => item.IsMember ===  1 )
        return []
    }

    get currentIsCustomerList(){
        if( this.volumeData  && this.volumeData.currentPeriod )
            return this.volumeData.currentPeriod.filter( item => item.IsMember ===  0 && !item.PVApplyDate )
        return []
    }

    get currentIsCustomerOrDirectCustomerList(){
        if( this.volumeData  && this.volumeData.currentPeriod ) {
            const isMember =  this.authStore.currentUser.isMember
            return this.volumeData.currentPeriod.filter(item => {
                return ( isMember ? item.IsDirectCustomer === 1 : item.IsMember === 0 ) && !item.PVApplyDate
            })
        }
        return []
    }
    get currentPendingCustomerList(){
        if( this.volumeData  && this.volumeData.currentPeriod ) {
            const isMember =  this.authStore.currentUser.isMember
            return this.volumeData.currentPeriod.filter(item => {
                return ( isMember ? item.IsDirectCustomer === 1 : item.IsMember === 0 ) && item.PVApplyDate
            })
        }
        return []
    }

    get currentPendingCustomerSubtotal(){
        return sumBy( this.currentPendingCustomerList,  "Subtotal" )
    }

    get currentPendingCustomerShippingTax(){
        return sumBy( this.currentPendingCustomerList,  x=>(x.Shipping + x.Tax) )
    }

    get currentPendingCustomerPV(){
        return sumBy( this.currentPendingCustomerList,  "PV")
    }

    get currentPendingCustomerTotal(){
        return sumBy( this.currentPendingCustomerList,  "Total")
    }




    get SAPercent(){
        return 25
    }




    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    setPVPeriodID(value) {
        this.PVPeriodID = value
    }

    loadVolumeData = generateLoadEntity(
        "volumeData",
        this,
        "volumeDataLoading",
         () => {
            return services.MyVolume.list( {PVPeriodID: this.PVPeriodID} );
        },
        "volumeData"
    );

}





export default PeriodsStore