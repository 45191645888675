import React, {useEffect, useState} from "react";
import {  observer } from "mobx-react-lite";
import {makeStyles} from "@material-ui/styles";
import {useStores} from "../../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import {Link} from "react-router-dom";
import {joinPath} from "../../../utils/helpers";
import PageTitle from "../../../components/PageTitle";
import ShippingInfoEdit from "./ShippingInfoEdit";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection:"column",
        padding: theme.spacing(3)
    },
    cardWrapper:{
        display: "inline-flex"
    },
    card: {
        width:"100%",
        border:"1px solid #777",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    selectedCard: {
        border:`2px solid ${theme.palette.primary.light}`,
    },
    addAddress:{
        border: "1px dashed white",
        borderRadius: 4,
        minHeight: 150,
        display:"flex",
        textDecoration:"none",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
    },
    defaultMark:{
        fontSize: 12,
        marginRight:"auto",
        textTransform: "uppercase"
    },
    withSpace:{
        marginTop:theme.spacing(3)
    }
}));

export default observer ( function ShippingInfoList ({parentMatch, allowSelect=false, onSelect, onEdit}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {userShippingInfoStore, commonStore} = useStores();
    const [inlineEditId, setInlineEditId] = useState();
    const [selectedId, setSelectedId] = useState();
    const { addresses, addressesLoading, error} =  userShippingInfoStore

    const handleOnEdit = (addressId)=>{
        setInlineEditId( addressId )
        if ( onEdit )
            onEdit(addressId)
    }

    const setSelected = (addressId)=>{
        setSelectedId( addressId )
        if ( onSelect )
            onSelect(addressId)
    }


    useEffect(  () => {
        (async ()=>{
            await userShippingInfoStore.loadAddresses()
        })()

    }, []);

    const removeAddress= (address)=>{
        commonStore.showDeleteConfirm("Address", address, async ()=>{
            await userShippingInfoStore.removeAddress(address.addressID)
            await userShippingInfoStore.loadAddresses()
        } )
    }

    if ( inlineEditId != null ){
        return <ShippingInfoEdit  addressId ={inlineEditId}
                                  onSaved = {e=>{ setInlineEditId(null)}}
                                  onCancel = {e=>{ setInlineEditId(null)}}
        />
    }

    return <>
        {error ?  <Alert severity="error">{error}</Alert> : null}
        {(addressesLoading)
            ? <CircularProgress/>
            : <>
                <PageTitle>{t("shippingInformation")}</PageTitle>
                <div className={classes.root}>
                <Grid container  spacing={2} >
                    {addresses.map(x => {
                        return <Grid  item   xs={12}  md={6} lg ={6} xl={6} className={classes.cardWrapper}  key={x.addressID}>
                            <Card className={clsx( classes.card, {[classes.selectedCard]: selectedId===x.addressID})}
                                  onClick={allowSelect?(e=>{setSelected(x.addressID)}):undefined}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h4">
                                            {x.addressName}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {x.address1} {x.address2}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {x.city}, {x.region} {x.zip}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {x.country}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions >
                                    <Typography className={classes.defaultMark}>{x.primaryAddress? t("defaultAddress"):""}</Typography>
                                    {!x.primaryAddress&&<Button size="small"
                                            variant={"contained"}
                                            color="secondary"
                                            onClick={e=>{ removeAddress(x) } }>
                                        {t("remove")}
                                    </Button>}
                                    {parentMatch
                                        ? <Button component={Link} to={ joinPath(parentMatch.url, x.addressID, "edit")} size="small"
                                                variant={"contained"}
                                                color="primary">
                                            {t("edit")}
                                        </Button>
                                        : <Button onClick={e=>handleOnEdit(x.addressID)} size="small"
                                                  variant={"contained"}
                                                  color="primary">
                                            {t("edit")}
                                        </Button>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    })}
                </Grid>
                <Grid container spacing={2} className={classes.withSpace}>
                    <Grid  item  xs={12}  md={6} lg ={6} xl={6}>
                        {parentMatch
                            ? <Card component={Link} to={joinPath(parentMatch.url, "create")}
                                    className={classes.addAddress}>
                                <Typography align="center" gutterBottom variant="h6" component="h4">
                                    {t("addNewAddress")}
                                </Typography>
                                <AddIcon fontSize={"large"}/>
                            </Card>
                            : <Card onClick={e=>handleOnEdit(-1)}
                                    className={classes.addAddress}>
                                <Typography align="center" gutterBottom variant="h6" component="h4">
                                    {t("addNewAddress")}
                                </Typography>
                                <AddIcon fontSize={"large"}/>
                            </Card>
                        }
                    </Grid>
                </Grid>
            </div></>}
        </>
})