import React from "react";
import {Button, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
}));


function GoGetter(){

    const classes = useStyles();
    const {commonStore} = useStores();
    const {t} = useTranslation("starfish");

    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={ `/img/gogetter_${commonStore.language}.png` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("gogetter.timeless_strategy")}<span className={classes.linkColor}>{t("gogetter.life_top_performers")}</span></Typography>
                <Typography align="center" className={classes.mainText}  variant="body1">{t("gogetter.universal_trait")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <img
                src={`/img/team.jpg`}
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText}  variant="h3">{t("gogetter.driving_results")}<span className={classes.linkColor}>{t("gogetter.simple_as_going")}</span></Typography>
                <Typography align="center" className={classes.mainText} variant="body1">{t("gogetter.benchmark")}</Typography>
                <Typography align="center" className={classes.mainText} variant="body1">{t("gogetter.requirement")}</Typography>
                <Typography align="center" className={classes.mainText} variant="body1"></Typography>
            </div>
        </section>




        <section className={clsx( classes.section)}>

            <Button color="primary" variant="contained" target="_blank" href={
                commonStore.language === "fr"
                    ? "https://resources.lifeinfoapp.com/forms/us_powerplayertracker_fr.pdf"
                    : "https://resources.lifeinfoapp.com/forms/us_powerplayertracker_en.pdf"
            }>{t("gogetter.download_btn")}</Button>
       </section>

        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default GoGetter