import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { useStores } from "../../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ContactSupportFormDef from "../../forms/ContactSupportForm";
import MaterialTextField from "../../components/inputs/MaterialTextField";
import { useTranslation } from "react-i18next";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";



const useStyles = makeStyles((theme) => ({
    whiteColor: {
        color: "white"
    },

}));



export default observer(function Help() {
    const classes = useStyles();
    const { commonStore, helpStore, supportStore } = useStores();
    const { onStarfishOrRoyalStarfish:onStarfish } = commonStore;
    const { t } = useTranslation();
    const { error: helpError } = helpStore;
    const { supportInfo, supportInfoLoading, error: supportError } = supportStore;
    const { sendingSupportRequest } = helpStore;
    const [showSentModal, setShowSentModal] = React.useState(false);


    useEffect(() => {
        supportStore.loadSupportInfo();
    }, []);

    const closeSentDialog = () => {
        setShowSentModal(false);
    };


    const localStore = useLocalStore(() => ({
        formSubmitting: false,
        _form: ContactSupportFormDef({}, {
            onSuccess: async (form) => {
                let values = form.values();

                await helpStore.sendSupportRequest({ ...values, onStarfish });
                setShowSentModal(true);
                form.clear();
                form.reset();
                form.showErrors(false);
                localStore.formSubmitting = false;
            },
            onError: async () => {
                this.formSubmitting = false;
            },
            formSubmitting: false
        }),
        submit() {
            this.formSubmitting = true;
            this._form.submit();
        },
        get error() {
            return this._form.error;
        },
        update(values) {
            this._form.update(values);
        }
    }));





    return <div className={classes.whiteColor}>
        <Typography variant="h5">{t("helpAndSupport")}</Typography>
        {(helpError || supportError) ? <Alert severity="error">{helpError || supportError}</Alert> : null}
        <Grid spacing={4} container>
            <Grid item xs={12}>
                <MaterialTextField field={localStore._form.$("subject")}></MaterialTextField>
            </Grid>
            {!commonStore.onStarfishOrRoyalStarfish && <Grid item xs={12}>
                <Alert variant="outlined" severity="info">
                    <AlertTitle> If you are experiencing a technical issue please be sure to include the following in your message.</AlertTitle>
                    Platform (Apple, Android, Desktop)<br />
                    Device Type (iPhone 12, Samsung 10, etc.)<br />
                    Device Operating System (iOS 15.2.1)<br />
                    If App, the version you're using.<br />
                    If desktop, the browser you're using.<br />
                    Provide the user steps that create the issue.
                </Alert>
            </Grid>
            }

            <Grid item xs={12}>
                <MaterialTextField multiline inputProps={{ rows: 5 }} field={localStore._form.$("message")}></MaterialTextField>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={e => { localStore._form.submit(); }}
                    disabled={localStore.formSubmitting || sendingSupportRequest} color={"primary"} variant={"contained"} fullWidth>
                    {(localStore.formSubmitting || sendingSupportRequest) ? <><CircularProgress size={20} />{t("sending")}...</> : "Send"}
                </Button>
                <Grid container item xs={12} style={{ width: '100%', marginTop: '10px' }} >
                    <Typography variant="subtitle1">{t("help.description")}</Typography>
                </Grid>
            </Grid>

            <Dialog open={showSentModal} onClose={closeSentDialog}>
                <DialogTitle>{t("help.supportrequest-sent")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("help.supportrequest-sent-text")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeSentDialog} color="primary" autoFocus variant={"contained"}>
                        {t("help.supportrequest-sent-ok")}
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>
    </div>;
});