import plugins from './plugins';
import MobxReactForm from "mobx-react-form";


const createFieldsUS = {
    accountNumber: {
        label: 'Account Number',
        rules: 'numeric|required|digits_between:6,17'
    },
    routingNumber: {
        label: 'ABA / Routing Number',
        rules: 'numeric|required|digits:9',
    }
};

const createFieldsCA = {
    accountNumber: {
        label: 'Account Number',
        rules: 'numeric|required|digits_between:5,12'
    },
    routingNumber: {
        label: 'Transit and Institution Number (use digits only)',
        rules: 'numeric|required|digits:8',
    }
};


export const bankFields = {
    friendlyName: {
        label: 'Bank Nickname',
        rules: 'string|required'
    },
    accountType: {
        label: 'Account Type',
        rules: 'string|required'
    },
};

export const billingFields = {
    "firstName": {
        label: 'Account Holder First Name',
        rules: 'string|required',
    },
    "lastName": {
        label: 'Account Holder Last Name',
        rules: 'string|required',
    },
    /* Address info not required, so use empty strings */
    address1: {
        label: 'Address 1',
        rules: 'string',
        placeholder: 'Address 1',
        initialValue: ''
    },
    address2: {
        label: 'Address 2 / Apt# / Unit#',
        rules: 'string',
        placeholder: 'Address 2 / Apt# / Unit#',
        initialValue: ''
    },
    city: {
        label: 'City',
        rules: 'string',
        placeholder: 'City',
        initialValue: ''
    },
    zip: {
        label: 'Zip',
        rules: 'string|between:1,10',
        placeholder: 'Zip',
        initialValue: ''
    },
    region: {
        label: 'State / Province',
        rules: 'string',
        placeholder: 'State / Province',
        initialValue: ''
    },
    country: {
        label: 'Country',
        rules: 'string',
        placeholder: 'Country',
        initialValue: ""
    }
};




export default function create(forCreate, country, hooks) {
    const forCanadianBankAccount = ['CA', 'ca'].includes(country)
    const createFields = forCanadianBankAccount ? createFieldsCA : createFieldsUS;

    return new MobxReactForm({ fields: { ...(forCreate ? createFields : {}), ...bankFields, ...billingFields } }, { plugins, hooks });
}

