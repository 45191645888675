import React from 'react';
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import { green } from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
    info: {
        padding: theme.spacing(2),
    },
    divider: {
        margin: "20px 0"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2)
    },
    stepper: {
        display: 'none',
        background: "transparent",
        paddingTop: 0,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    title: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    searchContainer: {
        display: "flex"
    },
    alertTitle: {
        paddingBottom: `${theme.spacing(2)}px`,
    },
    alert: {
        minHeight: `${theme.spacing(15)}px`,
        minWidth: `${theme.spacing(44)}px`,
        width: `${theme.spacing(44)}px`,
    },
    alertCloseButton: {
        position: 'absolute',
        top: `-${theme.spacing(1)}px`,
        right: `-${theme.spacing(0)}px`,
    },
    acceptBtn: {
        backgroundColor: green[500],
        color: "white",
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: green[700],
            color: "white",
        },
        '&:active': {
            backgroundColor: green[700],
            color: "white"
        }
    },
    qrCodeSpacing: {
        marginBottom: `${theme.spacing(2)}px`
    }
}));

const Search = observer(({searchQuery, onSearchQueryChanged}) => {
    const classes = useStyles();
    const {t} = useTranslation("shopping");

    const [searchResults, setSearchResults] = React.useState(null);
    const [searchError, setSearchError] = React.useState(null)

    return (
        <div>
            <div className={classes.searchContainer}>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <OutlinedInput classes={{ notchedOutline: classes.notchedOutline, input: classes.colorWhite }}
                        id="outlined-adornment-amount"
                        placeholder={ t("home.search") + "..."}
                        value={searchQuery}
                        autoComplete={"off"}
                        onChange={e => { onSearchQueryChanged(e.target.value); }}
                        // endAdornment={(
                            // searchString && <InputAdornment position="end">
                            //     <IconButton
                            //         aria-label="toggle password visibility"
                            //         onClick={e => { setSearchString(""); }}
                            //     ><CancelIcon /></IconButton>
                            // </InputAdornment>)}
                        startAdornment={<InputAdornment className={classes.colorWhite} position="start"><SearchIcon /></InputAdornment>}

                    />

                </FormControl>
            </div>
            {searchResults &&
                <>
                    {(searchResults && searchResults.length > 0)
                        ? <div>Result</div>
                        : <Alert variant={"filled"} severity={"warning"}>Nothing Found</Alert>
                    }
                </>}

        </div>
    )
})

export default Search