import React, { useCallback, useState, useEffect } from 'react';
import { Box, Container, Grid, ListItemText, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Route, Switch, useHistory } from 'react-router';
import IFrame from '../../components/IFrame';
import { useStores } from '../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import LearnAboutWallet from '../Profile/LearnAboutWallet';
import Wallet from '../../components/Wallet/Wallet';
import {joinPath} from "../../utils/helpers";
import {useRouteMatch} from "react-router-dom";
import GiftCards from "../Wallet/GiftCards";
import EWalletTransactions from "../Wallet/EWalletTransactions";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '60px'
    },
    gridItem: {
        '&:last-child': {
            paddingRight: 0,
        },
    },
    walletBalance: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        padding: theme.spacing(2),
        minHeight: '100%',
    },
    walletHeader: {
        fontWeight: 'bold',
        fontSize: '.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        }
    },
    menuItem: {
        cursor: 'pointer',
        marginTop: theme.spacing(2),
        borderRadius: 20,
        border: "1px solid #007BFF",
        display: "flex",
        padding: "15px 0 15px 15px",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: 105,
        color: "white",
        flexGrow: 1,
        textDecoration: "none",
        "&:hover": {
            color: "white",
            textDecoration: "none",
        }

    },
    redBorder: {
        border: '1px solid #007BFF',
        borderRadius: '20px'
    },
    menuTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold'
    },
    menuSubtitle: {
        color: 'rgb(109,109,109)'
    }
}));



const StarfishWallet = ({parentMatch}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const { shoppingBossStore, authStore } = useStores();


    const goToGiftCards = useCallback(() => {
        history.push(joinPath( match.path, '/giftcards') );
    }, []);

    const goToTransactions = useCallback(() => {
        history.push(joinPath( match.path, '/transactions'));
    }, []);

    const addMoney = useCallback(() => {
        history.push(joinPath( parentMatch.path, '/add_more_money'));
    }, []);

    const managePayments = useCallback(() => {
        history.push(joinPath( parentMatch.path, '/userprofile/profile/billinginfo'));
    }, []);

    const learnAboutWallet = useCallback(() => {
        history.push(joinPath( match.path, '/learnAboutWallet'));
    }, []);

    useEffect(() => {
        (async () => {
            await shoppingBossStore.fetchUserBalance();
        })();
    }, []);

    return (
        <Switch>
            <Route path= { joinPath( match.path, '/giftcards_legacy')}
                render={() => <IFrame url="https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1698&returnTabID=1698&status=active&ssl=1&language=en" />} />
            <Route path={ joinPath( match.url, `giftcards`)}  render={props=>{
                return <GiftCards/>
            }} />
            <Route path={joinPath(match.url, `transactions`)} render={props => {
                return <EWalletTransactions/>
            }} />
            <Route path= { joinPath( match.path, '/transactions_legacy')}
                render={() => <IFrame url="https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1771&status=active&ssl=1&language=en" />} />
            <Route path= { joinPath( match.path, '/learnAboutWallet')}
                render={() => <LearnAboutWallet />}
            />
            <Route path="*"
                render={() =>
                    <Container className={classes.container}>
                        <Wallet
                            availableBalance={shoppingBossStore.balance}
                            lifetimeBalance={shoppingBossStore.lifetimePerks}
                            onGoToGiftCards={goToGiftCards}
                            onGoToTransactions={goToTransactions}
                            onAddMoney={addMoney}
                            onManagePayments={managePayments}
                            onLearnAboutWallet={learnAboutWallet}
                            country =  {authStore.country}
                        />
                    </Container>
                }
            />
        </Switch>
    );
};

export default StarfishWallet;