import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from "@material-ui/core/TableBody";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
import React from "react";
import {ContentPageStyles} from "../styles";
import {useTranslation} from "react-i18next";



const useStyles = makeStyles((theme) => ({

    table: {

    }
}));




function TicketTable({currentUser, loading, tickets, onTransfer}){
    const {t} = useTranslation("starfish");
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleTransfer = (item) => () => {
        if ( onTransfer) onTransfer(item);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return  <TableContainer className={classes.table}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left">{t("tickets.Ticket Holder Name")}</TableCell>
                    <TableCell align="left">{t("tickets.Qty")}</TableCell>
                    <TableCell align="left">{t("tickets.Purchase By")}</TableCell>
                    <TableCell align="left">{t("tickets.Options")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading
                    ?  <CircularProgress/>
                    :  <>
                        {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">{(item.DisplayName||"").trim() || `${currentUser?.firstName} ${currentUser?.lastName}`}</TableCell>
                                    <TableCell align="left">{item.TicketQty}</TableCell>
                                    <TableCell align="left">{item.PurchaseMethod}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleTransfer(item) }
                                        >
                                            {t("tickets.Transfer")}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}</>
                }

            </TableBody>
        </Table>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tickets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </TableContainer>
}


export default TicketTable;