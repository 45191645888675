import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {
    ReactReader, // A simple epub-reader with left/right button and chapter navigation
} from "react-reader";
import {useFetch} from "use-http";


const useStyles = makeStyles((theme) => ({
    root: props=> ({
        position: "relative",
        width: "100%",
        height: props.fullScreen?"100%": "90vh"
    })
}));

export default observer(({url, mediaID, title, fullScreen }) => {
    const classes = useStyles({fullScreen});
    const [firstRenderDone, setFirstRenderDone] = useState(false)
    const renditionRef = useRef(null)
    const [location, setLocation] = useState(null)
    const [startLocation, setStartLocation] = useState(null)

    const { get, patch, response, loading, error } = useFetch()


    async function loadState() {
        const result = await get(`/api/library/ebooks/${mediaID}/read`)
        if( result && response.ok)
        {
            setStartLocation(result)
        }

    }

    async function updateLocation(location) {
        await patch(`/api/library/ebooks/${mediaID}/read`, {location} )
    }

    useEffect(()=>{
        void loadState()
    }, [])


    const locationChanged = (epubcifi) => {
        // Since this function is also called on initial rendering, we are using custom state
        // logic to check if this is the initial render.
        // If you block this function from running (i.e not letting it change the page on the first render) your app crashes.
        if (!firstRenderDone){
            setFirstRenderDone(true)
            setLocation(startLocation)
            return;
        }

        // This is the code that runs everytime the page changes, after the initial render.
        // Saving the current epubcifi on storage...
        void updateLocation(epubcifi)
        // And then rendering it.
        setLocation(epubcifi) // Or setLocation(localStorage.getItem("book-progress"))
    }

    return <div className={classes.root}>
        {loading && !firstRenderDone
            ? <></>
            :<ReactReader
                url={url}
                title={title}
                swipeable={true}
                location={location}
                locationChanged={ locationChanged }
                getRendition={(rendition) => renditionRef.current = rendition}
            />}
    </div>
})


