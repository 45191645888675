import RestService from "./RestService";
import querystring from'qs'

const API_ROOT = import.meta.env.REACT_APP_API_URL;
class TransactionsService extends RestService {
    constructor(parentService) {
        if (parentService) {
            super(`/api/wallet/transactions`);
        }
        else
            throw new Error("Can be only nested service");
    }

    list(year, month, transactionType ) {
        return this.get(`${this.baseUrl}?year=${year}&month=${month}`);
    }

    details(year, month, transactionType) {
        return this.get(`${this.baseUrl}/details?year=${year}&month=${month}`,);
    }

    export(year, month, transactionType, showPurchases, showDeposits) {
        return this.get(`${this.baseUrl}/export?year=${year}&month=${month}`);
    }

}

export default class WalletService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId }/wallet`)
        }
        else if (parentService ) {
            super(`${parentService.baseUrl}/wallet`)
        }
        else
            throw new Error("Wallet service  cannot be used in standalone mode")
    }


    async stats(){
        return this.get( `${this.baseUrl}/stats`)
    }


    deposit(cardId, amount) {
        return this.post(`${this.baseUrl}/deposit`, {
            amount,
            cardId
        })
    }

    depositBank(id, amount, loginGuid) {
        return this.post(`https://shopapi.mainhomepage.com/api/bank/eWalletDeposit?${querystring.stringify({id, amount, loginGuid})}`, {
            amount,
            id
        })
    }

    depositBankCA(amount) {
        return this.post(`${this.baseUrl}/depositRequest`, {
            amount: Number(amount),
        })
    }

    withdraw(btcDepositAddress) {
        return this.post(`${this.baseUrl}/withdraw`, {
            btcDepositAddress,
        })
    }

    recordDepositBankAccount(paymentId, amount, userId) {
        return this.post(`${this.baseUrl}/recordDepositBankAccount`, {
            userId,
            paymentId,
            amount: Number(amount),
        })
    }

    Transactions() {
        return new TransactionsService(this);
    }
}

