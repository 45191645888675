import React, { useEffect, useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import IFrame from "../../components/IFrame";
import WalletHome from "../../components/Wallet/Wallet";
import { useStores } from "../../hooks/use-stores";
import { joinPath } from "../../utils/helpers";
import BillingInfo from "../Profile/BillingInfo";
import EWallet from "./eWallet";
import { BalanceMenuItem, SatsBalanceMenuItem } from "../../components/Wallet/MenuItem";
import LearnAboutWallet from "../Profile/LearnAboutWallet";
import LearnAboutSats from "../Profile/LearnAboutSats";
import BTCWithdrawal from "./BTCWithdrawal/";
import GiftCards from "./GiftCards";
import Transactions from "../Profile/Transactions";
import EWalletTransactions from "./EWalletTransactions";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        color: "white",
        backgroundColor: "black",
    },
    grow: {
        flexGrow: 1
    }
}));

const formatNumber = (num) => {
    try {
        return Number.parseFloat(num).toFixed(2);
    }
    catch (e) {
        return "0.00";
    }
}

const Wallet = () => {
    const classes = useStyles();
    const { shoppingBossStore, statStore, menuStore, authStore } = useStores();
    const { statistics } = statStore;
    const walletMatch = useRouteMatch();
    const history = useHistory();

    const { topLevelLinks } = menuStore.walletMenu;


    const satsWithdrawalPending = useMemo(() => {
        if (!statistics) {
            return false;
        }

        return !!statistics.wallet?.sats?.satsDepositAddress && !statistics.wallet?.sats?.satsWithdrawalCompleted;
    }, [statistics]);


    const additionalMenuItems = useMemo(() => {
        if (!statistics) {
            return null;
        }

        const tripCredits = topLevelLinks.find(def => def.tag == 'tripcredits');
        const tripCreditsBalance = statistics.wallet?.tripCredit?.balance;

        const sats = topLevelLinks.find(def => def.tag == 'sats');
        const satsBalance = statistics.wallet?.sats?.balance;

        return (
            <>
                {tripCredits && <BalanceMenuItem
                    title={tripCredits.title}
                    balance={formatNumber(tripCreditsBalance)}
                    link={joinPath(walletMatch.url, 'tradecredits')}
                />}
                {sats &&
                    <SatsBalanceMenuItem
                        title={sats.title}
                        caption={""}
                        balance={formatNumber(satsBalance)}
                        isWithdrawalPending={satsWithdrawalPending}
                    />
                }
            </>
        )
    }, [statistics, topLevelLinks, shoppingBossStore.balance, satsWithdrawalPending]);

    const goToGiftCards = useCallback(() => {
        history.push(`${walletMatch.url}/giftcards`);
    }, []);

    const goToTransactions = useCallback(() => {
        history.push(`${walletMatch.url}/transactions`);
    }, []);

    const addMoney = useCallback(() => {
        history.push(`${walletMatch.url}/ewallet/add_more_money`);
    }, []);

    const managePayments = useCallback(() => {
        history.push(`${walletMatch.url}/paymentsources`);
    }, []);

    const learnAboutWallet = useCallback(() => {
        history.push(`${walletMatch.url}/learnAboutWallet`);
    }, []);

    useEffect(() => {
        shoppingBossStore.fetchUserBalance();
    }, []);


    return (!menuStore.menuLoading && menuStore.walletMenu) ?
        <Switch>
            <Route path={joinPath(walletMatch.url, `paymentsources`)} render={props => {
                return <BillingInfo />
            }} />

            <Route path={ joinPath( walletMatch.url, `giftcards`)}  render={props=>{
                return <GiftCards/>
            }} />

            <Route path={joinPath(walletMatch.url, `ewallet`)} render={props => {
                return <EWallet walletMatch={walletMatch} />
            }} />

            <Route path={joinPath(walletMatch.url, `transactions`)} render={props => {
                return <EWalletTransactions/>
            }} />
            <Route path={joinPath(walletMatch.url, `transactions_legacy`)} render={props => {
                return <div className={clsx(classes.mainContent, classes.grow)}>
                    <IFrame url={'https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1771&status=active&ssl=1&language=en'} />
                </div>
            }} />

            <Route path={joinPath(walletMatch.url, `giftcards_legacy`)} render={props => {
                return <div className={clsx(classes.mainContent, classes.grow)}>
                    <IFrame url={'https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1698&returnTabID=1698&status=active&ssl=1&language=en'} />
                </div>
            }} />

            <Route path={joinPath(walletMatch.url, `tradecredits`)} render={props => {
                return <div className={clsx(classes.mainContent, classes.grow)}>
                    <IFrame url={'https://legacy.lifeinfoapp.com/loginapp.aspx?pageid=1683&returnTabID=1678&accountType=tripcredits&ssl=1'} />
                </div>
            }} />

            <Route path={joinPath(walletMatch.url, `sats`)} render={props => {
                return <LearnAboutSats />
            }} />

            <Route path={joinPath(walletMatch.url, `withdraw`)} render={props => {
                return <BTCWithdrawal />
            }} />

            <Route path={joinPath(walletMatch.url, `learnAboutWallet`)} render={props => {
                return <LearnAboutWallet notOnStarfish />
            }} />

            <Route path='*' render={() => {
                return <WalletHome
                    additionalMenuItems={additionalMenuItems}
                    availableBalance={shoppingBossStore.balance}
                    lifetimeBalance={shoppingBossStore.lifetimePerks}
                    onGoToGiftCards={goToGiftCards}
                    onGoToTransactions={goToTransactions}
                    onAddMoney={addMoney}
                    onManagePayments={managePayments}
                    onLearnAboutWallet={learnAboutWallet}
                    isWithdrawalPending={satsWithdrawalPending}
                    country={authStore.country}
                />
            }} />
        </Switch>
        : null
}

export default observer(Wallet);
