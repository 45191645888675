import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStores } from "../../hooks/use-stores";
import { ContentPageStyles } from "./styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  ".MuiAutocomplete-option": {
    padding: 0,
  },
  page: {
    "& .MuiAccordion-root.Mui-expanded": {
      marginBottom: 0,
    },
    "& .MuiAccordion-root.MuiPaper-root": {
      backgroundColor: "black",
    },
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: 700,
    margin: 0,
  },
  accordion: {},
  filtersArea: {
    display: "flex",
    flexDirection: "column",
  },
  filters: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  filtersLabel: {
    width: "100px",
    color: "white",
  },
  filtersInput: {
    width: "500px",
    "& .MuiSelect-select": {
      padding: "10.5px 14px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    }
  },
  leaderFormArea: {
    padding: "0 2rem 1rem 2rem",
    width: "100%",
  },
  formArea: {
    padding: "1rem",
    width: "100%",
  },
  formCategoryTitle: {
    fontSize: "18px",
    padding: "0.5rem 0",
    borderBottom: "1px solid white",
  },
  formLink: {
    display: "block",
    marginBottom: "1rem",
  },
  suggestionLink: {
    width: "100%",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none !important",
  },
  accordionDetails: {
    padding: 0,
  },
}));

const FORM_COUNTRIES = [
  { label: "Canada", value: "ca" },
  { label: "United States", value: "us" },
];

const FORM_CATEGORIES = [
  { label: "All", value: "All" },
  { label: "Enrollment", value: "Enrollment" },
  { label: "Compensation", value: "Compensation" },
  { label: "PoliciesAndProcedures", value: "Policies and Procedures" },
  { label: "EventsAndOpenMeetings", value: "Events and Open Meetings" },
  { label: "FrequentlyAskedQuestions", value: "Frequently Asked Questions" },
  { label: "SpeakerAdmin", value: "Speaker Admin" },
  { label: "FinancialFitness", value: "Financial Fitness" },
  {
    label: "ProductInfoAndSalesAids",
    value: "Product Information & Sales Aids",
  },
  { label: "EventSpeakerAdmin", value: "Event Speaker Admin" },
  { label: "BrandedLetterHead", value: "Branded Letter Head" },
  { label: "IDCardsCanada", value: "ID Cards - Canada" },
  {
    label: "MFCChallengeQuestions",
    value: "MFC Challenge - Group Questions 1-13",
  },
  { label: "Miscellaneous", value: "Miscellaneous Forms" },
  { label: "LLRCorporate", value: "LLR Corporate" },
  { label: "SpanishForms", value: "Spanish Forms" },
  { label: "FrenchForms", value: "French Forms" },
  { label: "Pacific", value: "Pacific" },
  { label: "GettingStarted", value: "Getting Started" },
  { label: "Compliance", value: "Compliance" },
];

// group forms by category, then map to FORM_CATEGORIES order
const DisplayForms = ({ forms }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const grouped = groupBy(forms, (form) => form.category);
  const displayForms = FORM_CATEGORIES.map((category) => ({
    category: t("myForms."+category.label),
    forms: grouped[category.value]
      ? sortBy(grouped[category.value], ["display"])
      : undefined,
  }));

  return (
    <div>
      {displayForms.map((item) =>
        item.forms ? (
          <div key={item.category}>
            <h2 className={clsx(classes.formCategoryTitle)}>{item.category}</h2>
            {item.forms.map((form) => (
              <div key={form.docid} className={clsx(classes.formLink)}>
                <Link target={"_blank"} href={form.url}>
                  {form.display}
                </Link>
              </div>
            ))}
          </div>
        ) : null
      )}
    </div>
  );
};

function MyForms() {
  const classes = useStyles();
  const { t } = useTranslation();

  const { myFormsStore, authStore } = useStores();
  const { myFormsInfo, myFormsLoading, searchFormInfo, searchFormsLoading } =
    myFormsStore;
  const { currentUser } = authStore;

  const leaderOnlyForms = myFormsInfo?.filter((forms) => forms.leaderonly);
  const commonForms = myFormsInfo?.filter((forms) => !forms.leaderonly);

  const [openSuggestions, setOpenSuggestions] = useState(false);
  // "All" means category query is undefined - "no category filter"
  const [country, setCountry] = useState("us");
  const [category, setCategory] = useState("All");
  const [keyword, setKeyword] = useState(" ");

  // load forms list at initial load and re-load after filter
  const loadMyForms = () => {
    if (!myFormsLoading) {
      void myFormsStore.loadForm({
        country,
        category: category === "All" ? undefined : category,
      });
    }
  };
  useEffect(() => {
    loadMyForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, category]);

  // fetching suggestions for searching (debounced: 500ms)
  const debounceSearching = debounce(() => {
    void myFormsStore.searchForm({
      country,
      category: category === "All" ? undefined : category,
      keyword: keyword || undefined,
    });
  }, 500);
  useEffect(() => {
    if (keyword.length > 2 || keyword === "") {
      debounceSearching();
    }
    return () => {
      // cancel debounce to hold the API fetching while user is typing
      debounceSearching.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <div className={clsx(classes.page)}>
      {/* Filters section*/}
      <Accordion expanded className={clsx(classes.accordion)}>
        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
          <Typography>{t("myForms.filterOrSearch")}</Typography>
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.filtersArea)}>
          <div className={clsx(classes.filters)}>
            <InputLabel
              id="country-select-label"
              className={clsx(classes.filtersLabel)}
            >
              {t("country")}:
            </InputLabel>
            <Select
              labelId="country-select-label"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className={clsx(classes.filtersInput, classes.filtersSelect)}
              variant="outlined"
              disabled={myFormsLoading}
            >
              {FORM_COUNTRIES.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  {t(country.label)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={clsx(classes.filters)}>
            <InputLabel
              id="category-select-label"
              className={clsx(classes.filtersLabel)}
            >
              {t("category")}:
            </InputLabel>
            <Select
              labelId="country-select-label"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className={clsx(classes.filtersInput)}
              variant="outlined"
              disabled={myFormsLoading}
            >
              {FORM_CATEGORIES.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {t("myForms."+category.label)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={clsx(classes.filters)}>
            <InputLabel className={clsx(classes.filtersLabel)}>
              {t("Search")}:
            </InputLabel>
            <Autocomplete
              freeSolo
              size="small"
              disableClearable
              clearOnBlur={false}
              className={clsx(classes.filtersInput)}
              open={openSuggestions}
              onOpen={() => setOpenSuggestions(true)}
              onClose={() => setOpenSuggestions(false)}
              loading={searchFormsLoading}
              disabled={myFormsLoading}
              options={searchFormInfo ? searchFormInfo.suggestions : []}
              getOptionLabel={(option) => option.display}
              getOptionSelected={(option, value) =>
                option.display === value.display
              }
              filterOptions={(options, params) => {
                // Autocomplete's default filter only match one field
                // Matching 2 fields implementation: either display or tag
                return options.length > 0
                  ? options.filter(
                      (option) =>
                        option?.display
                          .toLowerCase()
                          .includes(params.inputValue.toLowerCase()) ||
                        option?.tags
                          .map((tag) => tag.toLowerCase())
                          .includes(params.inputValue.toLowerCase())
                    )
                  : [];
              }}
              renderOption={(form, { inputValue }) => {
                // highlight characters which are matched with current input
                const matches = match(form.display, inputValue);
                const parts = parse(form.display, matches);
                return (
                  <Link
                    href={form.url ?? ""}
                    target="_blank"
                    rel="noreferrer"
                    className={clsx(classes.suggestionLink)}
                  >
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                    {/* non-matching tags are hidden */}
                    {form.tags?.map((tag, index) =>
                      inputValue.includes(tag) ? (
                        <Chip variant="outlined" label={tag} key={index} />
                      ) : null
                    )}
                  </Link>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {searchFormsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
              )}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Leader only forms section */}
      {currentUser.isLeader && (
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <h2 className={clsx(classes.sectionTitle)}>{t("myForms.LeaderOnly")}</h2>
          </AccordionSummary>
          <AccordionDetails className={clsx(classes.accordionDetails)}>
            <div className={clsx(classes.leaderFormArea)}>
              {myFormsInfo === null || myFormsLoading ? (
                <CircularProgress />
              ) : (
                <DisplayForms forms={leaderOnlyForms} />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Common forms section */}
      <div className={clsx(classes.formArea)}>
        {myFormsInfo === null || myFormsLoading ? (
          <CircularProgress />
        ) : (
          <DisplayForms forms={commonForms} />
        )}
      </div>
    </div>
  );
}

export default observer(MyForms);
