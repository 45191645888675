import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react";
import {
    Button,
    ButtonGroup,
    Box,
    Divider,
    Card,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    Link,
} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabContext, TabPanel} from "@material-ui/lab";
import PanelProcessedDeclined from "./components/PanelProcessedDeclined";
import Variance from "./components/Variance";
import UsersList from "./components/UsersList";
import {capitalize, formatNumber} from "../../utils/helpers";
import PanelRow from "./components/PanelRow";
import ColoredCardContent from "./components/ColoredCardContent";
import {reaction} from "mobx";
import CustomerRequirements from "../Members/controls/CustomerRequirements";
import Periods from "./components/Periods";

const useStyles = makeStyles((theme) => ({
    cards: {
        margin: 10,
        display: 'grid',
        rowGap: theme.spacing(1),
        columnGap: theme.spacing(1),
        gridTemplateColumns: "1fr 1fr 1fr",
        [theme.breakpoints.down(900)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down(640)]: {
            gridTemplateColumns: "1fr",
        }
    },
    coloredCards: {
        margin: 10,
        display: 'grid',
        rowGap: theme.spacing(1),
        columnGap: theme.spacing(1),
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        [theme.breakpoints.down(900)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down(640)]: {
            gridTemplateColumns: "1fr",
        }
    },
    coloredCarHeader:{
        height:32,
        display:'flex',
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    },


    cardWithIconHeader: {
        height: 55,
        display: "flex",
        alignItems: "center",
    },

    icon: {
        width: 40,
        height: 40,
        marginLeft: 5,
        marginRight: 5
    },

    colorCard: {
        borderRadius: 3,
        minHeight: 147
    },

    cardHeader: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRowStart: 0,
        gridRowEnd: 0,
    },

    gridWithTabs: {
        display: "grid",
        gridGap: "10px",
        gridTemplateColumns: "1fr  3fr 1fr",
        gridTemplateRows: "64px 3fr 1fr"
    },


    cardTab: {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        borderRadius: 0,
        whiteSpace: "nowrap",
    },
    cardTabActive: {
        backgroundColor: "rgba(255, 255, 255, 0)",
        borderColor: "rgba(255, 255, 255, 0)",
    },

    iconCircle: {
        borderRadius: "50%",
        width: 48,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    iconMedium: {
        width: 36,
        height: 36,
    },
    iconSmall: {
        width: 30,
        height: 30,
    },
    bgGray: {
        backgroundColor: "#383f48",
    },

    bgGreen: {
        backgroundColor: "#008650",
    },
    bgBlue: {
        backgroundColor: "#337ab7",
    },
    bgCardBlue: {
        backgroundColor: "#00a4e3",
    },
    bgRed: {
        backgroundColor: "#d2322d",
    },
    bgWhite: {
        backgroundColor: "white",
    },

    colorGreen: {
        color: "#008650",
    },
    colorRed: {
        color: "#d2322d",
    },

    tabWrapper: {
        flexDirection: "row",
        gap: 5,
        whiteSpace: "nowrap"
    },
    tabRoot: {
        minWidth: "initial !important",
        overflow: "initial !important",
        padding: 5,
        flexGrow: 0,
        marginRight:30

    },
    paddedTab: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    tabPanelRoot: {
        padding: 10,
        paddingBottom: 5,
        paddingTop: 5
    },
    CustomerRequirementsHeader:{
        position:"absolute",
        top:0,
        left:0,
        right:0,
    },
    capitalize:{
        textDecoration:"underline",
        "div::first-letter": {
            textTransform: "capitalize"
        }
    }
}));

function MembersDashboard() {
    const classes = useStyles();
    const {t} = useTranslation("dashboard");
    const {commonStore, authStore, dashboardStore, periodsStore} = useStores();
    const [starfishTab, setStarfishTab] = React.useState("starfish");
    const [msTab, setMSTab] = React.useState("ms");
    const [productTab, setProductTab] = React.useState("ffs");
    const [attendanceTab, setAttendanceTab] = React.useState("opens");
    const [memberEnrollTab, setMemberEnrollTab] = React.useState("completed");
    const [customerEnrollTab, setCustomerEnrollTab] = React.useState("customer_enrollments");
    const [cbTab, setCbTab] = React.useState("cpb");



    useEffect(() => {
        const reaction1 = reaction(
            () => dashboardStore.forUser,
            forUser => {
                if (forUser) {
                    void dashboardStore.refresh();
                }
            }
        );


        const reaction2 = reaction(
            () => dashboardStore.PVPeriodID,
            PVPeriodID => {
                if (PVPeriodID) {
                    void dashboardStore.refresh();
                    void dashboardStore.loadPersonalData();
                }
            }
        );

        const reaction3 = reaction(
            () => dashboardStore.TeamUserId,
            TeamUserId => {
                void dashboardStore.refresh();
            }
        );

        return () => {
            reaction1();
            reaction2();
            reaction3();
        }
    })

    const handleChangePeriod = (e) => {
        dashboardStore.setPVPeriodID(e.target.value)
    }

    const handleChangeTeamUserId = (e) => {
        dashboardStore.setTeamUserId(e.target.value)
    }

    const {
        PVPeriodID,
        TeamUserId,
        teams,
        dashboardData,
        dashboardPersonalData,
        userDialogContext,
        usersDialogFor,
    } = dashboardStore;

    const {periodsLoading} = periodsStore;

    const {currentUser, isAsOrin} = authStore;

    useEffect(() => {
        if (currentUser) {
            (async () => {
                dashboardStore.setUser(currentUser.userID, true);
                await periodsStore.loadPeriods(currentUser.userID);
                await dashboardStore.loadTeamList();
            })();
        }
    }, [currentUser])


    const handleStarfishTabChange = (event, newValue) => {
        setStarfishTab(newValue)
    }

    const handleMSTabChange = (event, newValue) => {
        setMSTab(newValue)
    }

    const handleProductTabChange = (event, newValue) => {
        setProductTab(newValue)
    }

    const handleAttendanceTabChange = (value) => () => {
        setAttendanceTab(value)
    }
    const handleCustomerEnrollChange = (value) => () => {
        setCustomerEnrollTab(value)
    }
    const handleMemberEnrollChange = (value) => () => {
        setMemberEnrollTab(value)
    }

    const handleCbTabChange = (value) => () => {
        setCbTab(value)
    }

    const attendanceValue = useMemo(() => {
        if (!dashboardData) return 0;
        switch (attendanceTab) {
            case "opens":
                return dashboardData.OpenAttendance;
            case "seminars":
                return dashboardData.SeminarAttendance;
            case "majors":
                return dashboardData.ConventionAttendance;
        }
    }, [dashboardData, attendanceTab])

    const attendanceValueVar = useMemo(() => {
        if (!dashboardData) return 0;
        switch (attendanceTab) {
            case "opens":
                return dashboardData.OpenAttendanceVar;
            case "seminars":
                return dashboardData.SeminarAttendanceVar;
            case "majors":
                return dashboardData.ConventionAttendanceVar;
        }
    }, [dashboardData, attendanceTab])


    const seminarTicketPercentage = useMemo(() => {
        if (!dashboardData) return 0;
        switch (attendanceTab) {
            case "opens":
                return null;
            case "seminars":
                return dashboardData.SeminarTotal
                    ?  <>{`${formatNumber(100*(dashboardData.SeminarAttendance/dashboardData.SeminarTotal), 1 ) }%`}</>
                    : '0%'
            case "majors":
                return null;
        }
    }, [dashboardData, attendanceTab])

    const memberEnrollValue = useMemo(() => {
        if (!dashboardData) return 0;
        switch (memberEnrollTab) {
            case "completed":
                return dashboardData.MemberEnroll;
            case "waiting_room":
                return dashboardData.WaitingRoomRollup;
        }
    }, [dashboardData, memberEnrollTab])

    const memberEnrollValueVar = useMemo(() => {
        if (!dashboardData) return 0;
        switch (memberEnrollTab) {
            case "completed":
                return dashboardData.MemberEnrollVar;
            case "waiting_room":
                return dashboardData.WaitingRoomRollupVar;
        }
    }, [dashboardData, memberEnrollTab])


    const cbValue = useMemo(() => {
        if (!dashboardPersonalData) return 0;
        if (cbTab === "ccb") {
            return dashboardPersonalData.customerBonus.CCB;
        }
        if (dashboardPersonalData.customerBonus.Q4)
            return dashboardPersonalData.customerBonus.Q4
        if (dashboardPersonalData.customerBonus.Q3)
            return dashboardPersonalData.customerBonus.Q3
        if (dashboardPersonalData.customerBonus.Q2)
            return dashboardPersonalData.customerBonus.Q2
        if (dashboardPersonalData.customerBonus.Q1)
            return dashboardPersonalData.customerBonus.Q1

        return 0;
    }, [dashboardPersonalData, cbTab])

    const cpbLabel = useMemo(() => {
        if (!dashboardPersonalData) return "";
        if (cbTab === "ccb") {
            return "";
        }
        if (dashboardPersonalData.customerBonus.Q4)
            return "(Q4)"
        if (dashboardPersonalData.customerBonus.Q3)
            return "(Q3)"
        if (dashboardPersonalData.customerBonus.Q2)
            return "(Q2)"
        if (dashboardPersonalData.customerBonus.Q1)
            return "(Q1)"
        return "";
    }, [dashboardPersonalData, cbTab])


    const registeredSalesPct = useMemo(() => {
        if (!dashboardPersonalData || !dashboardPersonalData.sales) return 0;
        return (dashboardPersonalData.sales.RegisteredCustomerSales || 0) / (dashboardPersonalData.sales.RegisteredSalesRequired || 50) * 100

    }, [dashboardPersonalData])

    const customerRequirementsPassed = useMemo(() => {
        return registeredSalesPct >= 100;
    }, [registeredSalesPct])


    const hideUsersDialog = () => {
        dashboardStore.closeUsersDialog()
    }

    const handleSendMessages = (userIds, from, subject, message) => {
        dashboardStore.sendMessages(userIds, from, subject, message)
    }


    const handleProcessedClick = (type) => () => {
        dashboardStore.setOpenUsersDialog(type);
    }

    return <>
        {periodsLoading
            ? <></>
            : <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                <Select variant={"outlined"} value={TeamUserId} onChange={handleChangeTeamUserId}>
                    <MenuItem value={-1}>{t("allTeams")}</MenuItem>
                    {teams && teams.map(t => {
                        return <MenuItem key={t.UserID}
                                         value={t.UserID}>{t.UserID === t.TeamUserID ? `Team  ${t.TeamLastName}` : ` -- ${t.LastName} ${t.FirstName}`}</MenuItem>
                    })}
                </Select>
                    <Periods periods={periodsStore.periods} PVPeriodID={PVPeriodID} handleChangePeriod={handleChangePeriod}/>
                </Box>
                {dashboardData && <Typography>
                    {t("data_as_of")}: { moment.utc(dashboardData.LastUpdatedDate).format("MM/DD/YYYY h:mmA")}
                </Typography>}

            </Box>}


        {dashboardData
            ? <>
                <Box className={classes.cards}>
                    <Card>
                        <TabContext value={starfishTab}>
                            <Box className={classes.cardWithIconHeader}>
                                <img
                                    src="https://resources.lifeinfoapp.com/public/welcomeemail/starfish/starfishBlueLogo.png"
                                    className={classes.icon}/>
                                <Tabs variant="fullWidth" onChange={handleStarfishTabChange} value={starfishTab}
                                      indicatorColor="primary">
                                    <Tab classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}}
                                         color={"primary"} label={t("starfish")} value="starfish"
                                         selected={"starfish" === starfishTab}/>
                                    <Tab classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}}
                                         color={"primary"} label={t("royal")} value="royal"
                                         selected={"royal" === starfishTab}/>
                                </Tabs>
                            </Box>
                            <TabPanel value={"starfish"} classes={{root: classes.tabPanelRoot}} className={classes.tab}>
                                <PanelProcessedDeclined
                                    prefix={"starfish"}
                                    data={{
                                        processed: dashboardData.StarfishProcessed,
                                        processedVar: dashboardData.StarfishProcessedVar,
                                        pending: dashboardData.StarfishPending,
                                        declined: dashboardData.StarfishDeclined,
                                        declinedVar: dashboardData.StarfishDeclinedVar,
                                        canceled: dashboardData.StarfishCanceled,
                                        canceledVar: dashboardData.StarfishCanceledVar,
                                        removed: dashboardData.StarfishRemoved,
                                        removedVar: dashboardData.StarfishRemovedVar,
                                        total: dashboardData.StarfishTotal,
                                        totalVar: dashboardData.StarfishTotalVar

                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={"royal"} classes={{root: classes.tabPanelRoot}} className={classes.tab}>
                                <PanelProcessedDeclined
                                    prefix={"appSuperPlus"}
                                    data={{
                                        processed: dashboardData.AppSuperPlusProcessed,
                                        processedVar: dashboardData.AppSuperPlusProcessedVar,
                                        pending: dashboardData.AppSuperPlusPending,
                                        declined: dashboardData.AppSuperPlusDeclined,
                                        declinedVar: dashboardData.AppSuperPlusDeclinedVar,
                                        canceled: dashboardData.AppSuperPlusCanceled,
                                        canceledVar: dashboardData.AppSuperPlusCanceledVar,
                                        removed: dashboardData.AppSuperPlusRemoved,
                                        removedVar: dashboardData.AppSuperPlusRemovedVar,
                                        total: dashboardData.AppSuperPlusTotal,
                                        totalVar: dashboardData.AppSuperPlusTotalVar,
                                        free: dashboardData.AppSuperPlusFree,
                                        freeVar:dashboardData.AppSuperPlusFreeVar
                                    }}
                                />
                            </TabPanel>
                        </TabContext>
                    </Card>
                    <Card>
                        <TabContext value={msTab}>
                            <Box className={classes.cardWithIconHeader}>
                                <img src="https://resources.lifeinfoapp.com/public/icon/lt.png"
                                     className={classes.icon}/>
                                <Tabs variant={"fullWidth"} onChange={handleMSTabChange} value={msTab}
                                      indicatorColor="primary">
                                    <Tab classes={{
                                        wrapper: classes.tabWrapper,
                                        root: clsx(classes.tabRoot, classes.paddedTab)
                                    }}
                                         color={"primary"} label={t("ms")} value="ms" selected={"ms" === msTab}

                                    />
                                    <Tab classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}}
                                         color={"primary"} label={t("mspremium")} value="mspremium"
                                         selected={"mspremium" === msTab}/>
                                </Tabs>
                            </Box>

                            <TabPanel value={"ms"} classes={{root: classes.tabPanelRoot}} className={classes.tab}>

                                <PanelProcessedDeclined
                                    prefix={"ms"}
                                    data={{
                                        processed: dashboardData.MSProcessed,
                                        processedVar: dashboardData.MSProcessedVar,
                                        pending: dashboardData.MSPending,
                                        declined: dashboardData.MSDeclined,
                                        declinedVar: dashboardData.MSDeclinedVar,
                                        canceled: dashboardData.MSCanceled,
                                        canceledVar: dashboardData.MSCanceledVar,
                                        removed: dashboardData.MSRemoved,
                                        removedVar: dashboardData.MSRemovedVar,
                                        total: dashboardData.MSTotal,
                                        totalVar: dashboardData.MSTotalVar
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={"mspremium"} classes={{root: classes.tabPanelRoot}}
                                      className={classes.tab}>
                                <PanelProcessedDeclined
                                    prefix={"msPremium"}
                                    data={{
                                        processed: dashboardData.MSPremiumProcessed,
                                        processedVar: dashboardData.MSPremiumProcessedVar,
                                        pending: dashboardData.MSPremiumPending,
                                        declined: dashboardData.MSPremiumDeclined,
                                        declinedVar: dashboardData.MSPremiumDeclinedVar,
                                        canceled: dashboardData.MSPremiumCanceled,
                                        canceledVar: dashboardData.MSPremiumCanceledVar,
                                        removed: dashboardData.MSPremiumRemoved,
                                        removedVar: dashboardData.MSPremiumRemovedVar,
                                        total: dashboardData.MSPremiumTotal,
                                        totalVar: dashboardData.MSPremiumTotalVar
                                    }}
                                />
                            </TabPanel>
                        </TabContext>
                    </Card>
                    <Card>
                        <Box className={classes.cardWithIconHeader}>
                            <Box marginLeft={1} marginRight={1} alignSelf={"center"}
                                 className={clsx(classes.iconCircle, classes.iconMedium, classes.bgBlue)}>
                                <i className="fa fa-light fa-ticket-alt" style={{rotate: "-45deg"}}></i>
                            </Box>
                            <Typography>{t("tickets")}</Typography>
                        </Box>

                        <Box className={classes.tabPanelRoot}>
                            <PanelProcessedDeclined
                                prefix={"sot"}
                                data={{
                                    processed: dashboardData.SOTProcessed,
                                    processedVar: dashboardData.SOTProcessedVar,
                                    pending: dashboardData.SOTPending,
                                    declined: dashboardData.SOTDeclined,
                                    declinedVar: dashboardData.SOTDeclinedVar,
                                    canceled: dashboardData.SOTCanceled,
                                    canceledVar: dashboardData.SOTCanceledVar,
                                    removed: dashboardData.SOTRemoved,
                                    removedVar: dashboardData.SOTRemovedVar,
                                    total: dashboardData.SeminarTotal,
                                    totalVar: dashboardData.SeminarTotalVar
                                }}
                                labels={{
                                    processed: "sot_processed",
                                    pending: "sot_pending",
                                    declined: "sot_declined",
                                    user_canceled: "sot_user_canceled",
                                    decline_canceled: "sot_decline_canceled",
                                }}
                                moreRows={
                                    <PanelRow handleProcessedClick={handleProcessedClick("sotSingleTickets")}
                                              value={dashboardData.SeminarSingle}
                                              varValue={dashboardData.SeminarSingleVar}
                                              label={t('single_tickets')}
                                              reverseColor={false}
                                    />}
                            />
                        </Box>
                    </Card>
                    <Card>
                        <TabContext value={starfishTab}>
                            <Box className={classes.cardWithIconHeader}>
                                <img
                                    src="https://resources.lifeinfoapp.com/public/welcomeemail/starfish/starfishBlueLogo.png"
                                    className={classes.icon}/>
                                {t("stargetters")}
                            </Box>
                            <Box className={classes.tabPanelRoot} >
                                <Box display="flex" justifyContent="center" alignItems="center" padding={"5px"}>
                                    <Typography
                                        variant={"h6"}> {formatNumber(dashboardData.StarGetters)}</Typography>
                                    <Variance amount={dashboardData.StarGettersVar} reverseColor={false}/>
                                </Box>
                                <Divider/>
                                <Box display="flex" alignItems="center" justifyContent={"center"} style={{gap: "5px"}} paddingTop={3}  paddingBottom={3}>
                                    {t("forDetailedReporting")}:
                                    <Link
                                          href={"https://legacy.lifeinfoapp.com/MyBusiness/MyReports.aspx"}
                                          target={"_blank"}>
                                        {t("customerReports")}
                                    </Link>
                                </Box>
                            </Box>
                        </TabContext>
                    </Card>
                    <Card>
                        <Box className={classes.cardWithIconHeader}>
                            <img src="https://resources.lifeinfoapp.com/public/icon/life.png"
                                 className={classes.icon}/>
                            <Typography>{t("life_platform")}</Typography>
                        </Box>
                        <Box className={classes.tabPanelRoot}>
                            <PanelProcessedDeclined
                                prefix={"appSuper"}
                                data={{
                                    processed: dashboardData.AppSuperProcessed,
                                    processedVar: dashboardData.AppSuperProcessedVar,
                                    pending: dashboardData.AppSuperPending,
                                    declined: dashboardData.AppSuperDeclined,
                                    declinedVar: dashboardData.AppSuperDeclinedVar,
                                    canceled: dashboardData.AppSuperCanceled,
                                    canceledVar: dashboardData.AppSuperCanceledVar,
                                    removed: dashboardData.AppSuperRemoved,
                                    removedVar: dashboardData.AppSuperRemovedVar,
                                    total: dashboardData.AppSuperTotal,
                                    totalVar: dashboardData.AppSuperTotalVar
                                }}
                            />
                        </Box>
                    </Card>
                    {isAsOrin &&
                        <Card>
                            <TabContext value={productTab}>
                                <Box className={classes.cardWithIconHeader}>
                                    <Tabs variant="fullWidth" onChange={handleProductTabChange} value={productTab}
                                          indicatorColor="primary">
                                        <Tab color={"primary"}
                                             classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}} label={<>
                                            <img src="https://resources.lifeinfoapp.com/public/icon/ffs.png"
                                                 className={clsx(classes.icon, classes.iconSmall)}/>
                                            {t("ffs")}
                                        </>} value="ffs" selected={"ffs" === productTab}/>

                                        <Tab color={"primary"}
                                             classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}} label={<>
                                            <img src="https://resources.lifeinfoapp.com/public/icon/tpd.png"
                                                 className={clsx(classes.icon, classes.iconSmall)}/>
                                            {t("tpd")}
                                        </>} value="tpd"
                                             selected={"tpd" === productTab}/>

                                        <Tab color={"primary"}
                                             classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}} label={<>
                                            <img src="https://resources.lifeinfoapp.com/public/icon/product_en.jpg"
                                                 className={clsx(classes.icon, classes.iconSmall)}/>
                                            {t("product")}
                                        </>} value="product"
                                             selected={"product" === productTab}/>
                                    </Tabs>
                                </Box>

                                <TabPanel value={"ffs"} classes={{root: classes.tabPanelRoot}} className={classes.tab}>
                                    <PanelProcessedDeclined
                                        prefix={"ffs"}
                                        data={{
                                            processed: dashboardData.FFSProcessed,
                                            processedVar: dashboardData.FFSProcessedVar,
                                            pending: dashboardData.FFSPending,
                                            declined: dashboardData.FFSDeclined,
                                            declinedVar: dashboardData.FFSDeclinedVar,
                                            canceled: dashboardData.FFSCanceled,
                                            canceledVar: dashboardData.FFSCanceledVar,
                                            removed: dashboardData.FFSRemoved,
                                            removedVar: dashboardData.FFSRemovedVar,
                                            total: dashboardData.FFSTotal,
                                            totalVar: dashboardData.FFSTotalVar
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel value={"tpd"} classes={{root: classes.tabPanelRoot}} className={classes.tab}>
                                    <PanelProcessedDeclined
                                        prefix={"tpd"}
                                        data={{
                                            processed: dashboardData.TPDProcessed,
                                            processedVar: dashboardData.TPDProcessedVar,
                                            pending: dashboardData.TPDPending,
                                            declined: dashboardData.TPDDeclined,
                                            declinedVar: dashboardData.TPDDeclinedVar,
                                            canceled: dashboardData.TPDCanceled,
                                            canceledVar: dashboardData.TPDCanceledVar,
                                            removed: dashboardData.TPDRemoved,
                                            removedVar: dashboardData.TPDRemovedVar,
                                            total: dashboardData.TPDTotal,
                                            totalVar: dashboardData.TPDTotalVar
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel value={"product"} classes={{root: classes.tabPanelRoot}}
                                          className={classes.tab}>
                                    <PanelProcessedDeclined
                                        prefix={"mpb"}
                                        data={{
                                            processed: dashboardData.ProductBundleProcessed,
                                            processedVar: dashboardData.ProductBundleProcessedVar,
                                            pending: dashboardData.ProductBundlePending,
                                            declined: dashboardData.ProductBundleDeclined,
                                            declinedVar: dashboardData.ProductBundleDeclinedVar,
                                            canceled: dashboardData.ProductBundleCanceled,
                                            canceledVar: dashboardData.ProductBundleCanceledVar,
                                            removed: dashboardData.ProductBundleRemoved,
                                            removedVar: dashboardData.ProductBundleRemovedVar,
                                            total: dashboardData.ProductBundleTotal,
                                            totalVar: dashboardData.ProductBundleTotalVar
                                        }}
                                    />
                                </TabPanel>
                            </TabContext>
                        </Card>}
                </Box>

                <Divider/>
                <Box className={classes.coloredCards}>
                    <Box className={clsx( classes.colorCard, classes.bgRed) }>
                        <Box className={classes.coloredCarHeader}>
                            <Typography align={"center"}>PV</Typography>
                        </Box>
                        <ColoredCardContent
                            value={dashboardData.PV}
                            varValue={dashboardData.PVVar}
                            image={"https://resources.lifeinfoapp.com/public/icon/pv.png"}
                        />
                    </Box>
                    <Box className={clsx( classes.colorCard, classes.bgGreen) }>
                        <Box className={classes.coloredCarHeader} style={{ borderBottom: "none"}}>
                            <ButtonGroup fullWidth size="small" aria-label="small outlined button group">
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: customerEnrollTab === "customer_enrollments"})}
                                    style={{flex: "2"}}
                                    onClick={handleCustomerEnrollChange("customer_enrollments")}>{t("customer_enrollments")}</Button>
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: customerEnrollTab === "qualified"})}
                                    style={{flex: "1"}}
                                    onClick={handleCustomerEnrollChange("qualified")}>{t("qualified")}</Button>
                            </ButtonGroup>
                        </Box>
                        <ColoredCardContent
                            value={ customerEnrollTab === "customer_enrollments" ?  dashboardData.CustomerEnroll :dashboardData.CustomerQualifiedMembers }
                            varValue={ customerEnrollTab === "customer_enrollments" ?  dashboardData.CustomerEnrollVar: dashboardData.CustomerQualifiedMembersVar }
                            icon={ <i className="fa fa-users fa-2x"></i>}
                        />

                    </Box>
                    <Box className={clsx( classes.colorCard, classes.bgCardBlue)}>
                        <Box className={classes.coloredCarHeader} style={{ borderBottom: "none"}}>
                            <ButtonGroup fullWidth size="small" aria-label="small outlined button group">
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: memberEnrollTab === "completed"})}
                                    onClick={handleMemberEnrollChange("completed")}>{t("completed")}</Button>
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: memberEnrollTab === "waiting_room"})}
                                    onClick={handleMemberEnrollChange("waiting_room")}>{t("waiting_room")}</Button>
                            </ButtonGroup>
                        </Box>

                        <ColoredCardContent
                            value={memberEnrollValue}
                            varValue={memberEnrollTab === "completed" ? memberEnrollValueVar : null}
                            label={memberEnrollTab === "completed" ? t("member_enrollments") : null }
                            icon={ <i className="fa fa-users fa-2x"></i>}
                            footer={memberEnrollTab === "waiting_room" && <Typography variant={"body1"}>
                                Personal: {formatNumber(dashboardData.WaitingRoomPersonal)}
                            </Typography>}
                        />
                    </Box>
                    <Box className={clsx( classes.colorCard, classes.bgGray)}>
                        <Box className={classes.coloredCarHeader} style={{ borderBottom: "none"}}>
                            <ButtonGroup fullWidth size="small" aria-label="small outlined button group">
                                <Button
                                    style={{width:"45%"}}
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: attendanceTab === "opens"})}
                                    onClick={handleAttendanceTabChange("opens")}>{t("opens")}</Button>
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: attendanceTab === "seminars"})}
                                    onClick={handleAttendanceTabChange("seminars")}>{t("seminar")}</Button>
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: attendanceTab === "majors"})}
                                    onClick={handleAttendanceTabChange("majors")}>{t("majors")}</Button>
                            </ButtonGroup>
                        </Box>
                        <ColoredCardContent
                            value={attendanceValue}
                            varValue={attendanceValueVar}
                            footer={seminarTicketPercentage}
                            label={attendanceTab === "majors" ? t("tickets_sold") : t("attendance")}
                        />
                    </Box>
                </Box>
                <Divider/>
                {dashboardPersonalData && <Box className={classes.cards}>
                    <Card>

                        <Box className={classes.coloredCarHeader}>
                            <Typography align={"center"}>{t("sendouts")}</Typography>
                        </Box>
                        <ColoredCardContent
                            value={dashboardPersonalData?.sendouts?.Available}
                            icon={ <Box className={clsx(classes.iconCircle, classes.bgBlue)}>
                                <i className="fa fa-share-square fa-2x"></i></Box>}
                            footer={<Typography>({t("available credits")})</Typography> }
                        />
                        <Divider/>
                        <Link href={"https://legacy.lifeinfoapp.com/LifeTraining/MessageLog.aspx?nomenu=true"}
                              target={"_blank"}>
                            <Typography style={{paddingLeft:10}} >{t("VIEW PRIOR SENDOUTS")}</Typography>
                        </Link>
                    </Card>

                    <Card style={{position:"relative"}}>

                        <Box className={classes.coloredCarHeader}>
                            <Typography align={"center"}> </Typography>
                        </Box>
                        <ColoredCardContent
                            rightColumn={false}
                            icon={ <Box className={clsx(classes.iconCircle, classes.bgGreen)} >
                                <i className="fa fa-users fa-2x"></i></Box>}
                            footer={
                            <CustomerRequirements
                                headerClass={classes.CustomerRequirementsHeader}
                                value={dashboardPersonalData.requirements?.RegisteredCustomerPV}
                                requiredValue={dashboardPersonalData.requirements?.PVRequirement}
                                showDetails={dashboardPersonalData.requirements?.isForCurrentUser}
                                requirements={dashboardPersonalData.requirements}
                                pvShort={dashboardPersonalData.requirements?.PVRequirement - dashboardPersonalData.requirements?.MaxSelfReportPV }
                                Container={Box}
                                fullWidth={true}

                            /> }
                        />


                    </Card>

                    <Card>
                        <Box className={classes.coloredCarHeader} style={{ borderBottom: "none"}}>
                            <ButtonGroup fullWidth size="small" aria-label="small outlined button group">
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: cbTab === "cpb"})}
                                    onClick={handleCbTabChange("cpb")}>{t("cpb")}</Button>
                                <Button
                                    className={clsx(classes.cardTab, {[classes.cardTabActive]: cbTab === "ccb"})}
                                    onClick={handleCbTabChange("ccb")}>{t("ccb")}</Button>
                            </ButtonGroup>
                        </Box>
                        <ColoredCardContent
                            icon={ <Box className={clsx(classes.iconCircle, classes.bgGray)} >
                                <i className="fa fa-star fa-2x"></i></Box>}
                            label={cbTab === "cpb" ? t("cpb_full") : t("ccb_full")}
                            value={<><strong>{formatNumber(cbValue)} PV </strong> {cpbLabel}</> }
                        />
                    </Card>

                </Box>}

            </>
            : <CircularProgress/>
        }


        {usersDialogFor && <UsersList context={userDialogContext}
                   usersDialogFor={usersDialogFor}
                   hideUsersDialog={hideUsersDialog}
                   onSendMessages={handleSendMessages}
        />}


    </>

}


export default observer(MembersDashboard)