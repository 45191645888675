import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import services from "../services";
import { useStores } from "../hooks/use-stores";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import LanguageIcon from "@material-ui/icons/Language";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import PersonalForm from "../components/enrollment/personalForm";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BillingInfoForm from "../components/enrollment/billingInfoForm";
import BillingAddressForm from "../components/enrollment/billingAddressForm";
import ShippingAddressForm from "../components/enrollment/enrollmentShippingAddressForm";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormHelperText from '@material-ui/core/FormHelperText';
import CartView from "../components/enrollment/cartView";
import ItemDialog from "../components/enrollment/itemDialog";
import ErrorModal from '../components/ErrorModal';
import {STARFISH_KIT_EN_SKU, STARFISH_KIT_ES_SKU, STARFISH_KIT_FR_SKU} from "../utils/constants";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";

const headerHeight = 60;
const navMobileHeight = 50;
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
    },
    logo: {
        width: 50,
        height: 50,
        backgroundColor: 'black',
    },
    header: {
        height: headerHeight
    },
    navigationWrapper: {
        background: "#f8fafd",
        boxShadow: "7px 0 10px #aeaeae;",
        zIndex: 1,
        padding: "0 8px",
    },
    navigation: {
        [theme.breakpoints.down('md')]: {
            height: navMobileHeight,
        },
    },
    contentPanel: {
        position: "relative",
        background: "#ebebeb",
        flexGrow: 1,

        height: "100vh",
        overflow: "scroll",
        [theme.breakpoints.down(1180)]: {
            height: `calc( 100vh - ${headerHeight + navMobileHeight}px )`,
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5),
        },
    },
    contentPanelFade: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(200,200,200, 0.4)"
    },
    referralInfo: {
        fontSize: "11px"
    },
    tabPanel: {
        width: "100%"
    },
    captionWithButton: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"

    },
    termsTitle: {
        padding: 20,
        borderLeft: "5px solid #007BFF",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },

    large: {
        width: theme.spacing(16),
        height: theme.spacing(16)
    }

    , removeConfirmText: {
        marginTop: 10,
        marginBottom: 10,
        textAlign: "center",
        width: "100%"
    }
}));


const pages = {
    "1": "chooseyourcountry",
    "2": "createyouraccount",
    "3": "billinginfo",
    "4": "billingaddress",
    "5": "shippingaddress",
    "6": "summary"
};
export default (observer(({ component: C, ...rest }) => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const { enrollmentStore, memberEnrollmentStore, commonStore, userProfileStore, authStore } = useStores();
    const { allCountries, regionsByCountry } = userProfileStore;
    const { currentUser } = authStore;
    const {
        expired, referralInfo, loadingReferralInfo, loadingCartInfo, loadingAdditionalItems
        , effectiveAdditionalItems, error, creating, activeStep, countries
        , personalForm, personalFormEdit, personalFormEditSubmitting, personalFormSubmitting
        , billingInfoForm, billingInfoFormEdit, billingInfoFormEditSubmitting, billingInfoFormSubmitting
        , billingAddressForm, billingAddressFormEdit, billingAddressFormEditSubmitting, billingAddressFormSubmitting
        , shippingAddressForm, shippingAddressFormSubmitting, shippingAddressFormEdit, shippingAddressFormEditSubmitting
        , cart, cartTotal, oneTimeItems, recurringItems, isStarfishSignup,
        havePhysicalItems
    } = enrollmentStore;


    let product = new URLSearchParams(location.search).get("product");
    let upline = new URLSearchParams(location.search).get("upline");
    let uplineName = new URLSearchParams(location.search).get("uplineName");
    let uid = new URLSearchParams(location.search).get("uid");
    let language = new URLSearchParams(location.search).get("language");

    const [title, setTitle] = React.useState(pages[1]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [country, setCountry] = useState("US");
    const [refundAcknowledge, setRefundAcknowledge] = useState(false);
    const [fundEWallet, setFundEWallet] = useState(false);
    const [subscriptionAcknowledge, setSubscriptionAcknowledge] = useState(false);
    const [preEnroll, setPreEnroll] = useState(product !== "starfish" );
    const [complete, setComplete] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [debugEnrollID, setDebugEnrollID] = useState("");
    const [showEditPersonalModal, setShowEditPersonalModal] = useState(false);
    const [showEditBillingInfoModal, setShowEditBillingInfoModal] = useState(false);
    const [showEditBillingAddressModal, setShowEditBillingAddressModal] = useState(false);
    const [showEditShippingAddressModal, setShowEditShippingAddressModal] = useState(false);
    const [showItemDetails, setShowItemDetails] = useState(null);
    const [addItemModal, setAddItemModal] = useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorModal, setErrorModal] = React.useState(false);
    const [allowErrorModalToClose, setAllowErrorModalToClose] = React.useState(true);

    const changePreEnroll = (e) => {
        setPreEnroll(!preEnroll);
    }

    useEffect(() => {
        let country = "US";
        if (!!referralInfo)
            country = referralInfo.referralCountry;
        setCountry(country);
        enrollmentStore.initPersonalForm(commonStore.language, country);
        enrollmentStore.initBillingInfoForm(country);
        enrollmentStore.initShippingInfoForm(country);
        enrollmentStore.loadCountries();
        userProfileStore.loadAllCountries();
        userProfileStore.loadRegionsByCountry(country);

    }, []);

    useEffect(() => {
        enrollmentStore.setProduct(product);
    }, [product]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNextStep = () => {
        enrollmentStore.handleNextStep();
    };

    const handlePrevStep = () => {
        enrollmentStore.handlePrevStep();
    };

    const handleFinishEnroll = async () => {
        setInProgress(true);
        const billingInfo = billingInfoForm.values();
        const billingAddress = billingAddressForm.values();
        const shippingAddress = shippingAddressForm.values();
        let dto = {
            id: uid,
            cartID: cart.cartID,
            ...personalForm.values(),
            ...(havePhysicalItems?shippingAddress:{ "address1": "",
                "address2": "", //optional
                "city": "",
                "region": "",
                "country": "",
                "postalCode": ""}),
            ...billingInfo,
            ...billingAddress,
            fundEWallet,
            billName: `${billingAddress.billFirstName} ${billingAddress.billLastName}`,
            lang: commonStore.language,
            ipAddress: await services.Utility.getIPAddress().then(response => response.ip),
            product,
            upline,
            preEnrollment: product === "starfish" ? false: preEnroll,
            ...(process.env.REACT_APP_DEBUG_ENROLL?{debugEnrollID, REACT_APP_DEBUG_ENROLL:process.env.REACT_APP_DEBUG_ENROLL }:{})
        };
        if (dto.phone && dto.phone.trim().length > 0) {
            dto.phone = "+1" + dto.phone;
        }
        try {
            hideErrorModal();
            const result = await enrollmentStore.create(dto);
            if ( preEnroll && product !== "starfish"  ){
                if( result.access_token){
                    await authStore.pullUser(result.access_token);
                    history.push("/member/preenroll")
                    setInProgress(false);
                }
            }
            else {
                setInProgress(false);
                setComplete(true);
            }
        } catch (e) {
            let errorMessage = 'Error processing Customer Enrollment. Please contact support@lifeinfoapp.com';
            if (!!e.response && !!e.response.data && !!e.response.data.message)
                errorMessage = e.response.data.message;
            showErrorModal(errorMessage);
            setInProgress(false);
        }
    };

    const showErrorModal = React.useCallback((errorMessage, bAllowErrorModalToClose = true) => {
        setErrorMessage(errorMessage);
        setErrorModal(true);
        setAllowErrorModalToClose(bAllowErrorModalToClose);
    }, []);


    const hideErrorModal = () => {
        setErrorMessage("");
        setErrorModal(false);
    };

    const switchLanguage = (lang) => {
        handleClose();
        commonStore.setLanguage(lang, true);
        if (!personalForm.isDirty) {
            personalForm.update({ preferredLanguage: lang });
        }

    };


    useEffect(
        () => {
            setTitle(pages[activeStep]);
        }, [activeStep]);



    useEffect(
        () => {
            if (uid) {
                (async () => {
                    await enrollmentStore.verify(uid);
                })();
            }

        }, [product, uid]);

    useEffect(
        () => {
            if (language && personalForm) {
                commonStore.setLanguage(language, true);
                if (!personalForm.isDirty) {
                    personalForm.update({ preferredLanguage: language });
                }
            }

        }, [language, personalForm, currentUser]);


    useEffect(
        () => {
            enrollmentStore.resetCart();
            enrollmentStore.initBillingAddressForm(country);
            userProfileStore.loadRegionsByCountry(country);
        }, [country]);

    useEffect(
        () => {
            (async () => {
                await enrollmentStore.verify(uid);
            })();
            if (activeStep === "3" && cart === null) {
                (async () => {
                    await Promise.all([enrollmentStore.loadCart({ product, lang: commonStore.language, country })
                        , enrollmentStore.loadAdditionalItems({ country, lang: commonStore.language })]);
                })();
            }
        }, [activeStep]);

    useEffect(
        () => {
            (async () => {
                if (cart) {
                    void loadCartTotal();
                }
            })();

        }, [billingAddressForm, country, cart, oneTimeItems, recurringItems]);


    const loadCartTotal = () => {
        (async () => {
            const billingAddress = !!billingAddressForm && !!billingAddressForm.values() ? billingAddressForm.values() : {};
            await enrollmentStore.loadCartTotal({
                cartID: cart.cartID,
                lang: commonStore.language,
                country: billingAddress?.billCountry || country,
                city: billingAddress?.billCity,
                region: billingAddress?.billRegion,
                postalCode: billingAddress?.billPostalCode,
            });
        })();
    };

    const showAddItemModal = (x) => {
        setAddItemModal(x);
    };
    const hideAddItemModal = (x) => {
        setAddItemModal(null);
    };

    const hideItemDetails = (x) => {
        setShowItemDetails(null);
    };

    const addItemToCart = (x) => {
        (async () => {
            await enrollmentStore.addToCart({
                cartID: cart.cartID,
                productID: x.effectiveProductID || x.productID,
                lang: commonStore.language,
                qty: 1
            });
            hideAddItemModal();
            await enrollmentStore.refreshItems({ cartID: cart.cartID, country, lang: commonStore.language });
        })();
    };

    //====  Personal Edit
    const showEditPersonalFormModal = () => {
        enrollmentStore.initPersonalFormEdit(hideEditPersonalFormModal);
        setShowEditPersonalModal(true);

    };

    const hideEditPersonalFormModal = () => {
        setShowEditPersonalModal(false);
        enrollmentStore.closePersonalFormEdit();
    };


    //====  Billing Info Edit
    const showBillingInfoModal = () => {
        enrollmentStore.initBillingInfoFormEdit(hideBillingInfoModal);
        setShowEditBillingInfoModal(true);
    };

    const hideBillingInfoModal = () => {
        setShowEditBillingInfoModal(false);
        enrollmentStore.closeBillingInfoFormEdit();
    };


    //====  Billing Address Edit
    const showBillingAddressModal = () => {
        enrollmentStore.initBillingAddressFormEdit(hideBillingAddressModal);
        setShowEditBillingAddressModal(true);

    };

    //====  Shipping Address Edit
    const showShippingAddressModal = () => {
        enrollmentStore.initShippingInfoFormEdit(hideShippingAddressModal);
        setShowEditShippingAddressModal(true);

    };

    const hideBillingAddressModal = () => {
        setShowEditBillingAddressModal(false);
        enrollmentStore.closeBillingAddressFormEdit();
    };

    const hideShippingAddressModal = () => {
        setShowEditShippingAddressModal(false);
        enrollmentStore.closeShippingAddressFormEdit();
    };

    const handleIconClick = (item) => {
        setShowItemDetails(item);
    };


    const handleSwitchAlternative = (item, alternative) => {
        enrollmentStore.switchAlternative(item, alternative, country)
    };

    const handleRemoveAlternative = (item, alternative) => {
        enrollmentStore.removeAlternative(item, alternative, country)
    };


    const handleToggleItem = (item, showConfirm) => {
        if (item.removed) {
            return addItemToCart(item);
        }
        const action = (async () => {
            await enrollmentStore.removeFromCart({ cartID: cart.cartID, productID: item.effectiveProductID || item.productID, lang: commonStore.language });
            await enrollmentStore.refreshItems({ cartID: cart.cartID, country, lang: commonStore.language });
        });
        if (item.additionalWarning && product === "topcustomer") //enforce confirm for predefined  items
            showConfirm = true;


        if (showConfirm)
            commonStore.showConfirm(
                <>
                    <Typography variant={"subtitle1"} className={classes.removeConfirmText}> {t("enrollment:removeconfirm", { cartitem: item.productTitle })}</Typography>
                    {(item.additionalWarning && product === "topcustomer") && <Alert variant={"standard"} color={"error"}>{t("enrollment:removecauselossofstatus")}</Alert>}
                </>
                , t("enrollment:remove")
                , t("enrollment:remove")
                , action);
        else
            action();

    };

    const getCountries = () => {
        return countries.map(x => {
            if (product && (product === 'starfish' || product.includes('topcustomer'))) {
                if (['US', 'CA'].includes(x.countryShortCode)) {
                    return <MenuItem key={x.countryShortCode}
                        value={x.countryShortCode}>{x.countryName}</MenuItem>;
                }
            }
            else {
                return <MenuItem key={x.countryShortCode}
                    value={x.countryShortCode}>{x.countryName}</MenuItem>;
            }
        });
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        personalForm.update({ country: e.target.value });
        billingInfoForm.update({ country: e.target.value });

    };

    const handleBillingCountryChanged = (newVal) => {
        if (newVal)
            userProfileStore.loadRegionsByCountry(newVal);
    };

    const copyBillingToShipping = (e) => {
        if ( e.target.checked ){
            const billingAddress = billingAddressForm.values();
                shippingAddressForm.update({
                address1: billingAddress.billAddress,
                city: billingAddress.billCity,
                region: billingAddress.billRegion,
                    postalCode: billingAddress.billPostalCode,
                country: billingAddress.billCountry
            });
        }
        else {
            shippingAddressForm.clear();
        }
    }

    const patchedOneTimeItems  = useMemo(()=>{
        const kit = oneTimeItems.find(x => x.productSKU === STARFISH_KIT_EN_SKU
            || x.productSKU === STARFISH_KIT_FR_SKU
            || x.productSKU === STARFISH_KIT_ES_SKU);
        if (kit) {
            switch (product){
                case "starfish.kitmember":
                case "starfish.kitupline":
                case "starfish.kitmember.fr":
                case "starfish.kitupline.fr":
                case "starfish.kitmember.es":
                case "starfish.kitupline.es":
                    kit.productTitleAjusted  = kit.productTitle + ` (kit will be shipped to ${uplineName})`;
                    break;
                default:
                    kit.productTitleAjusted  = kit.productTitle;
            }

        }

        return oneTimeItems;

    },[oneTimeItems, product, upline, uplineName])


    if (complete)
        return <Container maxWidth={false} disableGutters>
            <Grid container className={classes.root}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <Avatar alt="Logo" src={`/starfish.png`} variant={"rounded"} className={classes.logo} />
                </Grid>
                <Grid container item xs={12} direction="column" alignItems={"center"} justifyContent={"center"} spacing={5}>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h5"}>{t("enrollment:congratulations")}</Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}>{t("enrollment:congratulations2")}  </Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}> {t("enrollment:congratulations3")} </Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"body1"}> {t("enrollment:congratulations4")} </Typography></Grid>
                    <Grid item>
                        <Button component={"a"} href={`https://my.lifeinfoapp.com/signin?redirect=/starfish`}
                            variant={"contained"}
                            color={"primary"}>Login</Button>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.header} alignItems={"center"} justifyContent={"center"}>

                </Grid>
            </Grid>
        </Container>;
    if (expired)
        return <Container maxWidth={false} disableGutters>
            <Grid container className={classes.root}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <Avatar alt="Logo" src={`/starfish.png`} variant={"rounded"} className={classes.logo} />
                </Grid>
                <Grid container item xs={12} className={classes.header} style={{ "color": "white" }} alignItems={"center"} justifyContent={"center"}>
                    {t("enrollment:expired")}
                </Grid>
            </Grid>
        </Container>;


    return <Container maxWidth={false} disableGutters>
        <Grid container className={classes.root}>
            <Grid container item xs={12} md={5} className={classes.navigationWrapper} alignItems={"flex-start"}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    {referralInfo && <ListItem disableGutters dense>
                        <ListItemAvatar>
                            <Avatar alt="Logo" src={`/starfish.png`} variant={"rounded"} className={classes.logo} />
                        </ListItemAvatar>
                        <div className={classes.referralInfo}>
                            <div><strong>{t("enrollment:referredby")}</strong></div>

                            <React.Fragment>
                                <div>{referralInfo.referralName}</div>
                                <div>{referralInfo.referralEmail}</div>
                                <div>{referralInfo.referralPhone}</div>
                            </React.Fragment>

                        </div>
                    </ListItem>}
                    <div>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <LanguageIcon /><ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={e => {
                                switchLanguage("en");
                            }}>English</MenuItem>
                            <MenuItem onClick={e => {
                                switchLanguage("fr");
                            }}>Français</MenuItem>
                            <MenuItem onClick={e => {
                                switchLanguage("es");
                            }}>Español</MenuItem>

                        </Menu>
                    </div>
                </Grid>
                <Grid container item xs={12} className={classes.navigation} justifyContent={"center"} direction={"column"}>

                    <div>
                        <Typography variant={"h5"}>
                            {activeStep > 1 && <IconButton onClick={handlePrevStep}><KeyboardArrowLeftIcon /></IconButton>}
                            {t(`enrollment:${title}`)}
                        </Typography>
                    </div>
                    {error && <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}
                </Grid>
            </Grid>
            <Grid container item xs={12} md={7} className={classes.contentPanel}>
                <TabContext value={activeStep}>
                    <TabPanel value={"1"} className={classes.tabPanel}>
                        <Grid container spacing={3} justifyContent={"center"}>
                            <Grid item xs={12} lg={7}><InputLabel>{t("enrollment:country")}</InputLabel></Grid>
                            <Grid item xs={12} lg={7}><Select
                                fullWidth variant={"outlined"} size={"small"}
                                value={country || ""}
                                onChange={handleCountryChange}>
                                {getCountries()}
                            </Select></Grid>
                            <Grid item xs={12} lg={7}>
                                <Button fullWidth onClick={handleNextStep} variant={"contained"}
                                    color="primary">{t("enrollment:continue")}</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={"2"} className={classes.tabPanel}>
                        <PersonalForm form={personalForm} onSubmit={() => {
                            enrollmentStore.submitPersonalForm();
                        }} submitting={personalFormSubmitting} />
                    </TabPanel>
                    <TabPanel value={"3"} className={classes.tabPanel}>
                        <BillingInfoForm form={billingInfoForm} onSubmit={() => {
                            enrollmentStore.submitBillingInfoForm();
                        }} submitting={billingInfoFormSubmitting} securityNameBanner={true} />
                    </TabPanel>
                    <TabPanel value={"4"} className={classes.tabPanel}>
                        <BillingAddressForm form={billingAddressForm}
                            allCountries={allCountries}
                            regionsByCountry={regionsByCountry}
                            handleCountryChanged={handleBillingCountryChanged}
                            onSubmit={() => {
                                enrollmentStore.submitBillingAddressForm();
                                void loadCartTotal();
                            }} submitting={billingAddressFormSubmitting} />
                    </TabPanel>
                    <TabPanel value={"5"} className={classes.tabPanel}>
                        <Grid container spacing={3} justifyContent={"center"}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={ <Checkbox color="primary"  onClick={copyBillingToShipping}/>}
                                    label="Use same address as Billing Information"
                                />

                            </Grid>
                          <ShippingAddressForm form={shippingAddressForm}
                            allCountries={allCountries}
                            regionsByCountry={regionsByCountry}
                            handleCountryChanged={handleBillingCountryChanged}
                            onSubmit={() => {
                                enrollmentStore.submitShippingAddressForm();
                            }} submitting={shippingAddressFormSubmitting} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value={"6"} className={classes.tabPanel}>
                        <Grid container spacing={3} justifyContent={"center"}>

                            <Grid container item xs={12} spacing={3} alignItems={"flex-start"}>
                                {personalForm && <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:personalinfo")}</Typography>
                                        <Button variant={"contained"} color={"primary"}
                                            onClick={showEditPersonalFormModal}
                                            size={"small"}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:name")}{personalForm.$("firstName").value} {personalForm.$("lastName").value}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"}>{t("enrollment:email2")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"}>{personalForm.$("email").value}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:password2")}{personalForm.$("password").value}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                {havePhysicalItems && <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:shippingaddress")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                                onClick={showShippingAddressModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {billingAddressForm && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{shippingAddressForm.values().address1}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{shippingAddressForm.values().address2}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{shippingAddressForm.values().city}, {shippingAddressForm.values().region}, {shippingAddressForm.values().postalCode}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{shippingAddressForm.values().country}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>}
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid container item md={12} lg={6} >
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:billinginfo")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                            onClick={showBillingInfoModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {billingInfoForm && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardtype2")}{billingInfoForm.values().cardType}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardnumber2")}******** {billingInfoForm.values().cardNumber && billingInfoForm.values().cardNumber.substr(billingInfoForm.values().cardNumber.length - 4)}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardexpiration")}{billingInfoForm.values().cardExpMonth}/{billingInfoForm.values().cardExpYear}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cvv")}{billingInfoForm.values().cardCode}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                                <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:billingaddress")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                            onClick={showBillingAddressModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {billingAddressForm && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingAddressForm.values().billFirstName} {billingAddressForm.values().billLastName}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingAddressForm.values().billAddress}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingAddressForm.values().billCity}, {billingAddressForm.values().billRegion}, {billingAddressForm.values().billPostalCode}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingAddressForm.values().billCountry}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid container item xs={12} lg={12} spacing={3}>
                                {(loadingCartInfo || loadingAdditionalItems)
                                    ? <CircularProgress />
                                    : <CartView
                                        isStarfishSignup={isStarfishSignup}
                                        oneTimeItems={patchedOneTimeItems}
                                        recurringItems={recurringItems}
                                        handleToggleItem={handleToggleItem}
                                        handleIconClick={handleIconClick}
                                        handleRemoveAlternative={handleRemoveAlternative}
                                        handleSwitchAlternative={handleSwitchAlternative}
                                        handleAdditionalItemSelect={showAddItemModal}
                                        cartTotal={cartTotal}
                                        effectiveAdditionalItems={effectiveAdditionalItems} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.termsTitle}>
                                    {t(`enrollment:completeenrollmentacknowledge`)}
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox style={{ color: '#007BFF' }} />}
                                    value={refundAcknowledge}
                                    onChange={() => { setRefundAcknowledge(!refundAcknowledge); }}
                                    label={t("enrollment:refundacknowledgelabel")} />
                                <FormHelperText>{t("enrollment:refundacknowledge")}</FormHelperText>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox style={{ color: '#007BFF' }} />}
                                    value={subscriptionAcknowledge}
                                    onChange={() => { setSubscriptionAcknowledge(!subscriptionAcknowledge); }}
                                    label={t("enrollment:subscriptionacknowledgelabel")} />
                                <FormHelperText>{isStarfishSignup === true ? t("enrollment:subscriptionacknowledgeyearly") : t("enrollment:subscriptionacknowledge")}</FormHelperText>
                            </Grid>
                            { product !=="starfish" &&
                                <>
                                    <Grid item xs={12}>
                                        <FormControlLabel control={<Checkbox style={{ color: '#007BFF' }} checked={preEnroll} />}
                                            value={preEnroll}
                                            onChange={changePreEnroll}
                                            label={t("enrollment:memberPreEnroll")} />
                                        <FormHelperText>{t("enrollment:memberPreEnrollText")}</FormHelperText>
                                    </Grid>
                                    {process.env.REACT_APP_DEBUG_ENROLL &&
                                    <Grid item xs={12}>
                                        <FormControlLabel control={<Input   />} variant={"outlined"}
                                                          value={debugEnrollID}
                                                          onChange={e=>{ setDebugEnrollID(e.target.value); }}
                                                          debug enroll id />
                                        <FormHelperText>DEBUG ENROLL ID</FormHelperText>
                                    </Grid>}

                                </>}
                            <Grid item xs={12}>
                                <FormHelperText>{t("enrollment:personal_info_disclaimer")}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} lg={7}>
                                {creating
                                    ? <CircularProgress />
                                    : <Button onClick={handleFinishEnroll}
                                        disabled={inProgress || !subscriptionAcknowledge || !refundAcknowledge || (cart ? cart.cartItems : []).length === 0}
                                        fullWidth variant={"contained"}
                                        color="primary">{t("enrollment:completeenrollment")}</Button>}
                            </Grid>
                        </Grid>

                    </TabPanel>
                </TabContext>
                {loadingReferralInfo && <div className={classes.contentPanelFade}><CircularProgress size={50} /></div>}

            </Grid>
        </Grid>
        {showEditPersonalModal && <Dialog maxWidth={"lg"} open={true} onClose={hideEditPersonalFormModal}>
            <DialogContent>
                <PersonalForm form={personalFormEdit} />
            </DialogContent>
            <DialogActions>
                {personalFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideEditPersonalFormModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitPersonalEditForm();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}

            </DialogActions>
        </Dialog>}
        {showEditBillingInfoModal && <Dialog maxWidth={"md"} open={true} onClose={hideBillingInfoModal}>
            <DialogContent>
                <BillingInfoForm form={billingInfoFormEdit} />
            </DialogContent>
            <DialogActions>
                {billingInfoFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideBillingInfoModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitBillingInfoEditForm();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {showEditBillingAddressModal && <Dialog maxWidth={"md"} open={true} onClose={hideBillingAddressModal}>
            <DialogContent>
                <BillingAddressForm form={billingAddressFormEdit}
                    allCountries={allCountries}
                    regionsByCountry={regionsByCountry}
                    handleCountryChanged={handleBillingCountryChanged} />
            </DialogContent>
            <DialogActions>
                {billingAddressFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideBillingAddressModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitBillingAddressEditForm();
                            void loadCartTotal();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {showEditShippingAddressModal && <Dialog maxWidth={"md"} open={true} onClose={hideShippingAddressModal}>
            <DialogContent>
                <ShippingAddressForm form={shippingAddressFormEdit}
                    allCountries={allCountries}
                    regionsByCountry={regionsByCountry}
                    handleCountryChanged={handleBillingCountryChanged} />
            </DialogContent>
            <DialogActions>
                {shippingAddressFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideShippingAddressModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitShippingAddressEditForm();
                            void loadCartTotal();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {addItemModal && <ItemDialog item={addItemModal} hideItemModal={hideAddItemModal} onConfirm={addItemToCart} />}
        {showItemDetails && <ItemDialog item={showItemDetails} hideItemModal={hideItemDetails} />}
        {!!errorModal
            ? (<ErrorModal allowUserToClose={allowErrorModalToClose} errorTitle={'Error'} handleClose={hideErrorModal} open={errorModal} error={errorMessage} />)
            : null
        }    </Container>;

}));