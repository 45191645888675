import React, {useCallback, useEffect, useMemo,} from "react";
import { observer } from "mobx-react-lite";
import {Box, Container} from '@material-ui/core';
import menu from './menu.json';
import {useRouteMatch} from "react-router-dom";
import LinksMenu from "./LinksMenu";
import {useStores} from "../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelPreEnroll from "../Starfish/CancelPreEnroll";
import {common} from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";

function Royal ( {parentMatch} ){
    const match = useRouteMatch();
    const { t } = useTranslation();
    const { authStore, commonStore, shoppingBossStore, supportStore } = useStores();
    const { eAcceleratorValidityLoading  } = shoppingBossStore;
    const { supportInfoLoading } = supportStore;
    const { currentUser, isShoppingOnlyUser  } = authStore;
    const MainLinks = useMemo(()=>{
         const items = menu.find(x=>x.tag==="userprofile").items
        return isShoppingOnlyUser? items.filter(x=>x.tag !=="step-member"): items
    },[menu, isShoppingOnlyUser])




    const cancelPreEnroll = useCallback(() => {
        commonStore.showConfirm( t("starfish:home.cancelPreEnrollConfirm"), "Cancel", t("starfish:home.cancelPreEnrollConfirmTitle")
            ,() => {
            void authStore.cancelPreEnroll();
        },
             "Close"

        );
    }, []);

    useEffect(() => {
        void shoppingBossStore.verifyEAccelerator();
        void supportStore.loadSupportInfo();
    }, []);

    if ( eAcceleratorValidityLoading == null || eAcceleratorValidityLoading || supportInfoLoading == null || supportInfoLoading  )
        return <Container><Box display={"flex"} justifyContent={"center"}><CircularProgress size={40}/></Box></Container>;
    return (
        <Container>
            <>
                {(currentUser.pendingMemberScheduledDate)
                    && <CancelPreEnroll onCancelPreEnroll={cancelPreEnroll} date={currentUser.pendingMemberScheduledDate} />}

                <LinksMenu items={MainLinks} showBack={false} parentMatch={match} />
            </>
        </Container>
    );
}

export default observer(Royal);