import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useStores } from "../../../hooks/use-stores";
import { Box, CircularProgress, Collapse, ListItemText, MenuItem, Select } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {makeStyles, ThemeProvider, withStyles} from "@material-ui/core/styles";
import { greenColor, redColor } from "../../../components/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from "@material-ui/core/IconButton";
import { useLocalObservable } from "mobx-react-lite";
import PageTitle from "../../../components/PageTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import services from "../../../services";
import PrimarySwitch from "../../../components/PrimarySwitch";
import StyledTableRow from "../../../components/StyledTableRow";
import yellowText from "../../../themes/yellowText";
import Dialog from "@material-ui/core/Dialog";
import TransactionInfo from "./TransactionInfo";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import range from "lodash/range";

const formatterUSD = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

function transactionName(id) {
    switch (id) {
        case 1:
            return "Shopping Cart";
        case 22:
            return "Marketing System Subscription";
        case 24:
            return "Leadership Convention Tickets";
        case 39:
            return "Total Personal Development";
        case 45:
            return "Volume From Partner Apps - Merchants";
        case 50:
            return "Gift Cards";
        case 51:
            return "Life App";
        case 52:
            return "Energy Drinks";
        case 58:
            return "Autoship";
        case 62:
            return "Thing or 2 Travel - Resorts";
    }
}

const useStyles = makeStyles((theme) => ({
    amountCell: {
        gridTemplateColumns: "120px [col-start] 50px [col-start]"
    },
    greenLabel: {
        ...greenColor(theme),
        gridColumn: 1
    },
    total: {
        gridColumnStart: "col-start 1"

    },
    amountAdditionalValue: {
        gridColumn: 2
    },
    deposit: { ...greenColor(theme) },
    withdrawal: { ...redColor(theme) },
    pendingEWallet: {
        marginBottom: theme.spacing(5),
    },
    filter: {
        gap: theme.spacing(5),
    },
    inputLabel: {
        fontSize: '1.2rem'
    },
    receiptButtons:{
        gap:theme.spacing(2),
        alignItems:"flex-end",
        [theme.breakpoints.down('sm')]: {
            alignItems:"flex-start",
            paddingBottom:theme.spacing(2)
        }
    }
}));




export default observer(function TransactionsList() {
    const { transactionType } = useParams();
    const { t } = useTranslation("transactions");
    const { authStore, commonStore, transactionsStore } = useStores();
    const { currentUser } = authStore;
    const classes = useStyles();
    const { transactionsLoading, transactions, transactionsTypes
        , allTransactionDetails
        , pendingeWallet
    } = transactionsStore;
    const [selectedYear, setSelectedYear] = useState(2025);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [showDeposits, setShowDeposits] = useState(false);
    const [showReceipt, setShowReceipt] = useState(null);


    const localStore = useLocalObservable(() => ({
        transactionsTypeFilter: -1,
        open: -1,
        setTransactionsTypeFilter(value) {
            this.transactionsTypeFilter = value;
        },
        setOpen(value) {
            this.open = value;
        },
        get effectiveItems() {
            /*
                It's possible that the current filter doesn't exist for the new result set.
               Attempting to filter on a non-existant type would return nothing and cause a blank to show
               in the type dropdown. If the type doesn't exist in the new set, fall back to showing 'all'
            */
            if (!transactions.some(t => t.transactionTypeID == this.transactionsTypeFilter)) {
                this.transactionsTypeFilter = -1;
            }
            if (this.transactionsTypeFilter === -1) {
                return transactions;
            }
            else
                return transactions.filter(item => item.transactionTypeID === this.transactionsTypeFilter);
        },

        get details() {
            return allTransactionDetails[this.open]  &&  allTransactionDetails[this.open].orderDetails;
        },
        get detailsProducts() {
            return allTransactionDetails[this.open]  &&  allTransactionDetails[this.open].products;
        },
        get trackingInfo() {
            return allTransactionDetails[this.open] && allTransactionDetails[this.open].tracking
        }
    }));

    useEffect(() => {
        transactionsStore.loadTransactions({ transactionType, year: selectedYear, month: selectedMonth, showDeposits, showPurchases: true });
        transactionsStore.loadPendingeWalletTransactions();
    }, [showDeposits, selectedYear, selectedMonth]);

    const downloadCSV = async () => {
        try {
            const csvResponse = await services.UserProfile.Transactions().export(selectedYear, selectedMonth, transactionType, true, showDeposits);

            let hiddenDownloadLink = document.createElement('a');
            const blob = new Blob([csvResponse], { type: 'text/csv' });
            hiddenDownloadLink.href = window.URL.createObjectURL(blob);
            hiddenDownloadLink.download = 'transactions.csv';
            hiddenDownloadLink.click();
        } catch (e) {
            commonStore.warn("There was an error downloading your file. Please try again.");
        }
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleTypeFilterChange = (e) => {
        localStore.setTransactionsTypeFilter(e.target.value);
    };

    const handleExport = (e) => {
        transactionsStore.exportData({ transactionType, year: selectedYear, month: selectedMonth });
    };

    const toggleOpenDetails = (groupID) => {
        if ( !allTransactionDetails[groupID]) {
            transactionsStore.loadTransactionDetails(groupID);
        }

        if (localStore.open === groupID) {
            localStore.setOpen(-1);
        } else {
            localStore.setOpen(groupID);
        }
    };


    const showSatsColumn = useMemo(() => {
        if (selectedMonth && selectedYear && selectedMonth < 12 && selectedYear <= 2022) {
            return true;
        } else {
            return false;
        }
    }, [selectedYear, selectedMonth]);

    return <>
        {pendingeWallet.length > 0 && <>
            <PageTitle>{t("pendingeWalletTransactions")}</PageTitle>
            <TableContainer className={classes.pendingEWallet}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("date")}</TableCell>
                            <TableCell>{t("type")}</TableCell>
                            <TableCell align="right">{t("amount")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pendingeWallet.map(p => {
                            return <TableRow key={p.TransactionID}>
                                <TableCell>{moment(p.CreatedDate).format("LL")}</TableCell>
                                <TableCell>{p.Amount > 0 ? "Deposit" : "Withdrawal"}</TableCell>
                                <TableCell align="right" className={clsx({
                                    [classes.deposit]: p.Amount > 0,
                                    [classes.withdrawal]: p.Amount < 0
                                })}>
                                    {p.Amount > 0 ? "+$" : "-$"}{p.Amount}</TableCell>
                            </TableRow>;
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>}
        {(transactionsLoading || transactionsLoading == null)
            ? <CircularProgress />
            : <>
                <PageTitle>{t("transactionsHistory")}</PageTitle>
                <Box><PrimarySwitch checked={showDeposits} onChange={e => { setShowDeposits(!showDeposits);
                    localStore.setOpen(-1); }} />{t("showDeposits")}</Box>
                <Box display="flex" justifyContent="flex-end" className={classes.filter}>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("month")}</InputLabel>
                        <Select value={selectedMonth} onChange={handleMonthChange} label={t("month")} style={{textTransform:"capitalize"}} >
                            <MenuItem value={-1}>{t("all")}</MenuItem>
                            {range(1, 13).map(m => <MenuItem key={m} value={m} style={{textTransform:"capitalize"}}>
                                {new Date( 2000, m-1).toLocaleString(commonStore.language, { month: "long" })}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("year")}</InputLabel>
                        <Select value={selectedYear} onChange={handleYearChange} label={t("year")}>
                            <MenuItem value={2025}>2025</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin={"normal"} >
                        <InputLabel className={classes.inputLabel}>{t("type")}</InputLabel>
                        <Select value={localStore.transactionsTypeFilter} onChange={handleTypeFilterChange} label={t("type")}>
                            <MenuItem value={-1}>{t("all")}</MenuItem>
                            {transactionsTypes.map((type) => <MenuItem key={type} value={type}>{transactionName(type)}</MenuItem>)}

                        </Select>
                    </FormControl>
                    {!commonStore.isNativeApp && <Button onClick={downloadCSV} startIcon={<CloudDownloadIcon />}>{t("export")}</Button>}
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("orderNo")}</TableCell>
                                <TableCell>{t("name")}</TableCell>
                                {!!showSatsColumn && <TableCell>{t("satsEarned")}</TableCell>}
                                <TableCell align="right">{t("cashEarned")}</TableCell>
                                {currentUser.isMember && <TableCell align="right">{t("totalPV")}</TableCell>}
                                <TableCell align="right">{t("tax")}</TableCell>
                                <TableCell align="right">{t("shipping")}</TableCell>
                                <TableCell align="right">{t("amount")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {localStore.effectiveItems.map((row) => (
                                <>
                                    <TableRow key={row.transactionID} onClick={() =>!row.isDeposit && toggleOpenDetails(row.orderID)}>
                                        <TableCell>
                                            { !row.isDeposit  && <IconButton aria-label="expand row" size="small"
                                                onClick={(e) =>  {
                                                    toggleOpenDetails(row.orderID)
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}>
                                                {localStore.open === row.groupID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>}
                                        </TableCell>
                                        <TableCell>
                                            {moment(row.orderDate).format("LL")}
                                        </TableCell>
                                        <TableCell>
                                            {row.orderID}
                                        </TableCell>
                                        <TableCell>{row.transactionName}</TableCell>
                                        {!!showSatsColumn && <TableCell align="right">{formatterUSD.format(row.satsEarned)}</TableCell>}
                                        <TableCell align="right">{formatterUSD.format(row.cashEarned)}</TableCell>
                                        {currentUser.isMember && <TableCell align="right">{formatterUSD.format(row.totalPV)}</TableCell>}
                                        <TableCell align="right">{formatterUSD.format(row.tax)}</TableCell>
                                        <TableCell align="right">{formatterUSD.format(row.shipping)}</TableCell>
                                        <TableCell align="right">{formatterUSD.format(row.total)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={"100%"}>
                                            <Collapse in={localStore.open === row.groupID} timeout="auto" unmountOnExit>
                                                {!localStore.details
                                                    ? <CircularProgress />
                                                    : <Box margin={1}>
                                                        <Grid container spacing={2}>
                                                            {
                                                                (row.orderStatusID === 110 || row.orderStatusID  === 111 ) &&
                                                                <ThemeProvider theme={yellowText}>
                                                                    <Grid container item sm={12} direction="column" alignItems={"flex-start"}  style={{padding:10, paddingLeft:0}}>
                                                                    { row.orderStatusID  === 110  && <Typography color={"primary"} >This order was fully refunded on {moment(row.refundDate).format("YYYY-MM-DD")}</Typography>}
                                                                    { row.orderStatusID  === 111  && <Typography color={"primary"} >This order was partially refunded  on {moment(row.refundDate).format("YYYY-MM-DD")}</Typography>}
                                                                    </Grid>
                                                                </ThemeProvider>
                                                            }
                                                            <Grid container item sm={5} direction="column">
                                                                <Typography variant={"h6"}>{t("global:shippingInfo")}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddressName}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddress1}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingAddress2}</Typography>
                                                                <Typography variant={"body1"}>{row.shippingCity} {row.shippingState} {row.shippingZip} {row.shippingCountry}</Typography>
                                                            </Grid>
                                                            <Grid container item sm={5} direction="column">
                                                                <Typography variant={"h6"}>{t("global:billingInfo")}</Typography>
                                                                <Grid container item direction="column">
                                                                    { localStore?.details?.AmountEWallet >0 &&  <Typography variant={"body1"}>{t("cashRedeemed")}: {formatterUSD.format(localStore?.details?.AmountEWallet )}</Typography>}
                                                                    { localStore?.details?.AmountCC >0 && <Typography variant={"body1"}>{t("cc")}: {formatterUSD.format(localStore?.details?.AmountCC )}  ( {t("last4")} {row.last4} )</Typography>}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item sm={2} direction="column" className={ classes.receiptButtons}>
                                                                <Button variant={"contained"} color={"primary"} onClick={ ()=>{setShowReceipt(row.groupID) }}>{t("showReceipt")}</Button>
                                                                <Button variant={"contained"} color={"primary"} to={`/order/${row.groupID}`} target={"_blank"} component={RouterLink} >{t("printReceipt")}</Button>
                                                            </Grid>
                                                        </Grid>
                                                        {(localStore.trackingInfo && localStore.trackingInfo.length>0) ? <Grid container>
                                                            <Grid container item sm={6}>
                                                                <Box display={"grid"} gridTemplateColumns={"auto 1fr"} gridRowGap={3} gridColumnGap={8}>
                                                                    <Box ><Typography>Tracking#</Typography></Box>
                                                                    {( localStore.trackingInfo||[]).map((t, i) => {
                                                                        return  <Link style={{gridColumnStart:2, alignSelf:"center"}} key={i} href={t.TrackingLink}
                                                                                      target={"_blank"}> {t.TrackingNumber}</Link>

                                                                        })}
                                                                </Box>




                                                            </Grid>
                                                        </Grid> : null}
                                                        <Table size={"small"}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell align="right">{t("qty")}</TableCell>
                                                                    <TableCell align="right">{t("cost")}</TableCell>
                                                                    <TableCell align="right">{t("amount")}</TableCell>
                                                                    <TableCell align="right">{t("pv")}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {localStore.detailsProducts && localStore.detailsProducts.map((d, i) => {
                                                                    return <StyledTableRow key={i}>
                                                                        <TableCell>
                                                                            {d.ImageUrl && <Avatar variant={"rounded"} src={d.ImageUrl} alt={d.ProductName}
                                                                                style={{ width: "75px", height: "75px" }} />}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <ListItemText primary={d.ProductName}
                                                                                secondary={
                                                                                    `${d.SKU ? `SKU: ${d.SKU}` : ""}
                                                                                              | Format: ${d.IsDigital ? "Digital" : "Physical"} 
                                                                                              | Price: $${d.UnitCost.toFixed(2)}
                                                                                                ${currentUser.isMember ? `PV: ${d.UnitPV.toFixed(2)}` : ''}`


                                                                                } />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {d.Quantity}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {d.UnitCost.toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {(d.TotalCost).toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {d.TotalPV.toFixed(2)}
                                                                        </TableCell>
                                                                    </StyledTableRow>;
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                }
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>}

        {showReceipt && <Dialog open={showReceipt} fullWidth={true} maxWidth={"md"}

                                onClose={()=>setShowReceipt(null)}>
            <DialogContent>
                <TransactionInfo  transactionDetails={{ orderID: showReceipt, ...allTransactionDetails[showReceipt]}} onClose={()=>setShowReceipt(null)} />
            </DialogContent>
            <DialogActions>

                <Button onClick={()=>setShowReceipt(null)} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>}

    </>;
});