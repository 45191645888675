import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";

import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {starFishColor} from "../../components/styles";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
    addIcon:{
      fontWeight: "bold",
      paddingTop:42,
        [theme.breakpoints.down("xs")]: {
            paddingTop:22,

        }
    }

}));


function StarGetter(){

    const classes = useStyles();
    const {commonStore} = useStores();
    const {t} = useTranslation("starfish");
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down(700));

    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={`/img/stargetter/img1.jpg`}
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("stargetter.action_breeds")}<span className={classes.linkColor}>{t("stargetter.results")}</span></Typography>
                <Typography align="center" className={ clsx(classes.mainText, classes.preWrapText)}  variant="body1">{t("stargetter.benchmark_for_generating")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section, classes.noBottomMargin)}>
            <img
                src={`/img/stargetter/img2.jpg`}
                className={clsx( classes.middleImage, classes.noBottomMargin)} role="img"/>
        </section>

        <section className={clsx( classes.section, classes.inverse)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("stargetter.why_star_getter")}</Typography>
             </div>
        </section>

        <section className={clsx( classes.section)}>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item sm={3} container alignItems={"center"} direction={"column"}>
                    <Box className={classes.bigCircle} marginBottom={2} fontSize={40}>
                        <i className="fal fa-solid fa-hand-holding-usd"></i>
                    </Box>
                    <Typography align="center" className={classes.mainText} variant="body2">
                        {t("stargetter.earn1")}
                    </Typography>
                </Grid>
                <Grid item sm={3} container alignItems={"center"} direction={"column"}>
                    <Box className={classes.bigCircle} marginBottom={2} fontSize={40}>
                        <i className="fal fa-solid fa-award"></i>
                    </Box>
                    <Typography align="center" className={classes.mainText} variant="body2">
                        {t("stargetter.earn2")}
                    </Typography>
                </Grid>
                <Grid item sm={3} container alignItems={"center"} direction={"column"}>
                    <Box className={classes.bigCircle} marginBottom={2} fontSize={40}>
                         <i className="fal fa-solid fa-users"></i>
                    </Box>
                    <Typography align="center" className={classes.mainText} variant="body2">
                        {t("stargetter.earn3")}
                    </Typography>
                </Grid>
            </Grid>
        </section>

        <section className={clsx( classes.section)}>
            <Divider className={classes.divider}/>
        </section>

        <section className={clsx( classes.section)}>
            <img
                src={`/img/stargetter/img3.png`}
                className={classes.middleImage} role="img"/>
        </section>

        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3"><span className={classes.linkColor}>{t("stargetter.track_your_progress1")}</span>{t("stargetter.track_your_progress2")}</Typography>
                <Typography align="center" className={clsx(classes.mainText, classes.preWrapText )}  variant="body1">{t("stargetter.track_your_progress_description")}</Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default StarGetter