import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button, CircularProgress, List, ListItem, ListItemText,
    MenuItem,
    Select, Table, TableBody, TableHead,
    Typography, withStyles
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStores} from "../../hooks/use-stores";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableRow from "@material-ui/core/TableRow";
import {accordion} from "../../components/styles";
import {formatNumberOrZero} from "../../utils/helpers";
import TableCell from "./controls/TableCell";
import clsx from "clsx";
import BonusTable from "./controls/BonusTable";
import Divider from "@material-ui/core/Divider";
import TrainingTable from "./controls/TrainingTable";
import {Alert} from "@material-ui/lab";
import {reaction} from "mobx";
import Periods from "../RoyalStarfish/components/Periods";


function getAdjustmentName(reason, locationName, eventDate) {
    let name = "";

    switch (reason) {
        case "SemStipend":
            name = "Seminar Stipend";
            break;
        case "OMSpeak":
            name = "Open Meeting Speaker";
            break;
        case "SemSpeak":
            name = "Seminar Speaker";
            break;
        case "OMStipend":
            name = "Open Meeting Stipend";
            break;
        default:
            name = reason;
    }

    if (eventDate !== null && eventDate !== undefined && !isNaN(new Date(eventDate).getTime())) {
        name += " - " + new Date(eventDate).toLocaleDateString();
    }

    if (locationName !== "") {
        name += " - " + locationName;
    }

    return name;
}

const useStyles = makeStyles((theme) => ({
    qualifyingTeam: {
        display: "table-cell",
        background: "#e3e3e3",
        verticalAlign: "top",
        textAlign: "center",
        minWidth: 75,


    },

    row: {
        border: "1px dashed #fff",
        minWidth: "80px",
        maxWidth: 90,
        paddingTop: "15px",
        paddingBottom: "5px",
        minHeight: "70px",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        fontSize: 13
    },
    cellName: {
        overflow: "hidden",
        padding: "0 5px",
        textOverflow: "ellipsis"
    },
    rowTeam: {
        paddingTop: "5px",
        minHeight: "80px",
        lineHeight: "20px",
        backgroundColor: "#000",
        color: "#fff !important",
    },

    rowQualify: {
        backgroundColor: "#47a447",
        color: "#fff"
    },

    rowPending: {
        border: "1px solid #da2127 !important"
    },

    rowEmpty: {
        backgroundColor: "#fff"
    },

    clickable: {
        cursor: "pointer"
    },
    ...accordion(theme),

    text: {
        width: "90%",
        overflow: "hidden",
        position: "relative",
        "&::after": {
            content: '"......................................................................................................................................................................................................................................................................... "',
            position: "absolute",
            paddingLeft: 5
        }
    },
    pad1: {
        paddingLeft: 40
    },
    pad2: {
        paddingLeft: 70
    },

    printBasic:{
        '@media print': {
            "& .MuiAccordion-root": {
                display: "none !important"
            },
            "& .MuiAccordion-root.mainSummaryBlock": {
                display: "block !important"
            }
        }
    },

    printDetails:{
        '@media print': {
            "& .MuiCollapse-hidden": {
                height: "auto !important",
                visibility: "visible !important"
            }
        }
    },

    printOnly:{
        display:"none",
        '@media print': {
            display:"block",
        }
    },
    bonusSummaryList:{
        width: "100%"
    }

}));


function MyIncome() {
    const classes = useStyles();
    const {t} = useTranslation("mytrainingincome");
    const {authStore, myTrainingIncomeStore, periodsStore} = useStores();
    const {periodsLoading} = periodsStore;
    const {currentUser} = authStore;
    const [printWithDetails, setPrintWithDetails] = React.useState(false);
    const {
        PVPeriodID, data, dataLoading, msbasic, msbasicYTD
        , mspremium, mspremiumYTD,seminarTickets, seminarTicketsYTD
        , conventionTickets, conventionTicketsYTD, events, eventsYTD, adjustments, adjustmentsYTD, rptEventsSource,  _EventsBonus
    } = myTrainingIncomeStore;

    reaction(
        () => myTrainingIncomeStore.PVPeriodID,
        PVPeriodID => {
            if (PVPeriodID) {
                void myTrainingIncomeStore.loadData();
            }
        }
    );

    useEffect(() => {
        if (currentUser) {
            (async () => {
                await periodsStore.loadPeriods(currentUser.userID);
                await myTrainingIncomeStore.loadData();
            })();
        }
    }, [currentUser])

    const handleChangePeriod = (e) => {
        myTrainingIncomeStore.setPVPeriodID(e.target.value)
    }


    const selectedPeriod = periodsStore.periods.find(p => p.PVPeriodID === PVPeriodID)



    const handlePrint=(withDetails)=>()=>{
        setPrintWithDetails(withDetails)
        window.setTimeout(()=>window.print(), 1000)
    }

    return (
        <>
            {periodsLoading
                ? <> <CircularProgress/> </>
                : <Box className={"noprint"} display={"flex"} alignItems={"center"}  justifyContent={"space-between" }>
                    <Box  style={{gap: 16}} display={"flex"} alignItems={"center"}>
                        <Typography variant={"subtitle1"}>{t("my_income")}</Typography>
                        <Periods  margin={"dense"} periods={periodsStore.paidPeriods} PVPeriodID={PVPeriodID} handleChangePeriod={handleChangePeriod}/>
                    </Box>
                    <Box  style={{gap: 16}} display={"flex"} alignItems={"center"}>
                        <Button  variant={"contained"} color={"primary"}  className={"noprint"} onClick={handlePrint(false)}>{t("printSummary")}</Button>
                        <Button  variant={"contained"} color={"primary"}  className={"noprint"} onClick={handlePrint(true)}>{t("printDetails")}</Button>
                    </Box>
                </Box>}

            <br/>
            <br/>
            {dataLoading && <CircularProgress/>}
            {dataLoading !== null && !dataLoading && periodsLoading !== null && !periodsLoading && <>

                <Box paddingLeft={2} className={clsx("printContent", {
                    [classes.printBasic]:!printWithDetails,
                    [classes.printDetails]:printWithDetails})}>
                    {selectedPeriod && <Typography className={classes.printOnly} variant={"subtitle1"}>Statement for {moment.utc(selectedPeriod.StartDate).format("MMMM yyyy")}</Typography>}

                    <Accordion classes={{root: clsx( classes.accordion, "mainSummaryBlock" )}} defaultExpanded expanded >
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                        >
                            <Typography className={classes.heading}>{t(`bonus_summary`)}</Typography>
                        </AccordionSummary>
                        {data?.bonusSummary &&
                            <AccordionDetails className={classes.accordionDetailsColumn}>
                                <Table stickyHeader size="small" style={{marginBottom: 20}}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{backgroundColor:"initial"}}
                                            className={classes.text}>{t("Rank")}</TableCell>
                                        <TableCell colspan={2} style={{backgroundColor:"initial"}}
                                                   align={"right"}>{data?.bonusSummary?.PerformanceRank}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('type')}</TableCell>
                                        <TableCell align={"right"}>{t('bonus_amount')}</TableCell>
                                        <TableCell align={"right"} style={{minWidth: 100}}>{t('ytd')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.text}>{t("MSBHeading")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(msbasic, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(msbasicYTD, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            className={classes.text}>{t("MSPHeading")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(mspremium, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(mspremiumYTD, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.text}>{t("SOToolsHeading")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummary?.DiscountSOTools, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummaryYTD?.DiscountSOTools, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.text}>{t("SeminarTickets")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(seminarTickets, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(seminarTicketsYTD, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.text}>{t("ConventionTickets")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(conventionTickets, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(conventionTicketsYTD, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.text}>{t("Events")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(events, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(eventsYTD, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.text}>{t("Adjustments")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(adjustments, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(adjustmentsYTD, 2)}</TableCell>
                                    </TableRow>
                                    {(data?.bonusSummary?.CurrencyAdjustment!==0 || data?.bonusSummaryYTD?.CurrencyAdjustment ) && <TableRow>
                                        <TableCell className={classes.text}>{t("Currency Adjustment")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummary?.CurrencyAdjustment, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummaryYTD?.CurrencyAdjustment, 2)}</TableCell>
                                    </TableRow>}
                                    {(data?.bonusSummary?.Tax!==0 || data?.bonusSummaryYTD?.Tax) && <TableRow>
                                        <TableCell className={classes.text}>{t("Tax Withheld")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummary?.Tax, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummaryYTD?.Tax, 2)}</TableCell>
                                    </TableRow>}
                                    {(data?.bonusSummary?.IGV!==0 ||data?.bonusSummaryYTD?.IGV) && <TableRow>
                                        <TableCell className={classes.text}>{t("IGV")}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummary?.IGV, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummaryYTD?.IGV, 2)}</TableCell>
                                    </TableRow>}
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('bonus_total')}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummary?.Total + data?.bonusSummary?.AdjustedPreviousPeriod, 2)}</TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(data?.bonusSummaryYTD?.Total + data?.bonusSummaryYTD?.AdjustedPreviousPeriod, 2)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>{" "}</TableCell>
                                </TableRow>

                            </Table>
                            </AccordionDetails>}

                    </Accordion>

                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`Events`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <Table stickyHeader size="small" style={{marginBottom: 20}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('name')}</TableCell>
                                        <TableCell align={"right"}>{t('amount')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rptEventsSource.map((item, index) => {
                                        return <TableRow key={index}>
                                            <TableCell>
                                                {getAdjustmentName(item.Reason, item.LocationName, item.EventDate)}
                                            </TableCell>
                                            <TableCell align={"right"}>${formatNumberOrZero(item.Amount, 2)}</TableCell>
                                        </TableRow>
                                    })
                                    }
                                    {rptEventsSource.length ===0 && <TableRow>
                                        <TableCell colSpan={2} align={"center"}>{t('no_data')}</TableCell>
                                    </TableRow>}
                                </TableBody>

                                {events!=0 && <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell
                                            align={"right"}>${formatNumberOrZero(_EventsBonus, 2)}</TableCell>
                                    </TableRow>
                                </TableHead>}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Box>

            </>}

        </>


);
}


export default observer(MyIncome);