import {
    Chip,
    Button,
    CircularProgress,
    MenuItem,
    Paper,
    Grid,
    Select,
    makeStyles, Box, Accordion, AccordionSummary, Typography, AccordionDetails,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from "@material-ui/core/Tabs";
import clsx from "clsx";
import {observer} from "mobx-react";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useStores} from "../../hooks/use-stores";
import {ContentPageStyles} from "./styles";
import {TransferTicketDialog} from "./components/TransferTicketDialog";
import {formatNumber} from "../../utils/helpers";
import TicketTable from "./components/TicketTable";
import TransfersTable from "./components/TransfersTable";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
    selectWrapper: {display: "flex", alignItems: "center", gap: "12px"},
    select: {width: "160px", display: "block", paddingLeft: "12px"},
    ticketWrapper: {
        display: "grid",
        gridTemplateColumns: `repeat(4, 1fr)`,
        gap: "24px",
        margin: "24px 0",
        [theme.breakpoints.down(900)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down(640)]: {
            gridTemplateColumns: "1fr",
        }

    },
    ticket: {
        display: "flex",
        flexDirection: "column",
        textTransform: "uppercase",
        paddingBottom: "6px",
        border: "1px solid gray",
    },
    ticketInfo: {
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 12px",
    },
    ticketHeader: {background: "black", borderRadius: "4px"},
    tabWrapper: {
        width: "100%",
        "& .MuiTabs-flexContainer": {
            width: "100%",
            justifyContent: "space-between",
        },
    },
    tabTrigger: {
        width: "33.33%",
        maxWidth: "none",
        whiteSpace: "nowrap",
    },
    showDetailsButton: {
        fontSize: "12px",
        padding: "4px 6px",
        margin: "0.5rem 0",
    },
}));

function TicketManagement() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    const [openDialog, setOpenDialog] = useState(null);

    const {authStore, commonStore, ticketStore} = useStores();

    const {currentUser} = authStore;
    const {t} = useTranslation("starfish");
    const {
        currentPeriod, PVPeriodID, userTickets,
        userTicketsTransfers, userTicketsTransfersNext,
        conventionTickets, conventionTicketsLoading, conventionTicketsTransfers, conventionInfo,
        userTicketsLoading, userTicketsNext, userTicketsNextLoading,
        transferringTicket,transferTicketError,ticketForName

    } = ticketStore;

    useEffect(() => {
        ticketStore.setUserFor(currentUser.userID, true)
        ticketStore.loadPeriods(currentUser.userID)
    }, []);


    const handleChange = (_event, newValue) => {
        setSelectedTab(newValue);
    };

    //TODO: Implement
    const handleShowTicketDetails = (item) => {

        ticketStore.setUserFor(item ? item.UserID : currentUser.userID)
    };


    const handleChangePeriod = (e) => {
        ticketStore.setUserFor(currentUser.userID)
        ticketStore.setPVPeriodID(e.target.value)
    }


    const handleTransferOpen = (item) => {
        ticketStore.setError(null, "transferTicket")
        setOpenDialog(item)

    }

    const handleCloseDialog = (item) => {
        setOpenDialog(null)

    }

    const handleTransfer = async (item) => {
        const result = await ticketStore.transferTicket(item)

        if ( result )
            setOpenDialog(null)
    }

    function ShowDetailsButton({item}) {
        return (
            <Grid container justifyContent="center">
                <Grid item>
                    <Button color={"primary"}
                            onClick={e => {
                                handleShowTicketDetails(item)
                            }}
                            className={classes.showDetailsButton}
                            variant="contained"
                    >
                        {t("tickets.Show Ticket Details")}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    return ticketStore.ticketInfo ? (
        <div className={clsx(classes.page)}>
            <div className={clsx(classes.selectWrapper)}>
                <span>{t("tickets.displaying")}</span>
                <Select
                    className={clsx(classes.select)}
                    value={PVPeriodID}
                    onChange={handleChangePeriod}
                >
                    {ticketStore.periods.map((period) => {
                        return (
                            <MenuItem
                                value={period.PVPeriodID}
                                key={period.PVPeriodID}
                            >{`${moment.utc(period.StartDate).format("MMMM (YYYY)")}`}</MenuItem>
                        );
                    })}
                </Select>
                <span>{t("tickets.ticket")}</span>
            </div>

            {ticketStore.ticketInfo && (
                <div className={classes.ticketWrapper}>
                    {ticketStore.ticketInfo.map((item) => (
                        <Paper className={classes.ticket} key={item.UserID}>
                            <div className={clsx(classes.ticketInfo, classes.ticketHeader)}>
                                <span>{item.isTotal ? "Total" : item.LastName}</span>
                                <Chip size={"small"} color={"primary"} label={formatNumber(item.SeminarTotal)}/>
                            </div>
                            <div className={classes.ticketInfo}>
                                <span>SOT</span>
                                <Chip size={"small"} label={formatNumber(item.SOT)}/>
                            </div>
                            <div className={classes.ticketInfo}>
                                <span>Processed SOT</span>
                                <Chip size={"small"} color={"primary"} label={formatNumber(item.SOTProcessed)}/>
                            </div>
                            <div className={classes.ticketInfo}>
                                <span>Single</span>
                                <Chip size={"small"} color={"primary"} label={formatNumber(item.TicketSeminarSingle)}/>
                            </div>
                            <div className={classes.ticketInfo}>
                                <span>Convention</span>
                                <Chip size={"small"} color={"secondary"} label={formatNumber(item.TicketConvention)}/>
                            </div>
                            <ShowDetailsButton item={item}/>
                        </Paper>
                    ))}
                </div>
            )}

            <div>
                <h3>
                    {t("tickets.Ticket Details For")}:{" "}
                    {currentUser.spouse.firstName
                        ? `${currentUser.firstName} & ${currentUser.spouse.firstName} ${currentUser.lastName}`
                        : ""}
                    {ticketForName? ` - ${ticketForName}` : ""}
                </h3>
            </div>

            {(conventionTicketsLoading || userTicketsNextLoading || userTicketsLoading)
                ? <CircularProgress/>
                : <>
                    <AppBar position="static">
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            className={classes.tabWrapper}
                        >
                            <Tab
                                label={
                                    <Box display={"flex"} style={{gap: 8}}>
                                        {moment.utc(
                                            currentPeriod.StartDate
                                        ).format("MMMM") + " - Seminar"}
                                        <Chip size={"small"} color={"secondary"}
                                              label={formatNumber(userTickets.length > 0 ? userTickets.reduce((sum, number) => {
                                                  return sum + parseInt(number.TicketQty, 10);
                                              }, 0) : 0)}/>
                                    </Box>

                                }
                                className={classes.tabTrigger}
                            />
                            <Tab
                                label={
                                    <Box display={"flex"} style={{gap: 8}}>
                                        {moment.utc(
                                            currentPeriod.StartDate
                                        ).add(1, "M").format("MMMM") + " - Seminar"}
                                        <Chip size={"small"} color={"secondary"}
                                              label={formatNumber(userTicketsNext.length > 0 ? userTicketsNext.reduce((sum, number) => {
                                                  return sum + parseInt(number.TicketQty, 10);
                                              }, 0) : 0)}/>
                                    </Box>
                                }
                                className={classes.tabTrigger}
                            />
                            <Tab
                                label={
                                    <Box display={"flex"} style={{gap: 8}}>
                                        {conventionInfo?.conventionTitle}
                                        <Chip size={"small"} color={"secondary"}
                                              label={formatNumber(conventionTickets.length > 0 ? conventionTickets.reduce((sum, number) => {
                                                  return sum + parseInt(number.TicketQty, 10);
                                              }, 0) : 0)}/>
                                    </Box>

                                }
                                className={classes.tabTrigger}
                            />
                        </Tabs>
                    </AppBar>

                    <div>
                        {selectedTab === 0 && (
                            <>
                                <TicketTable loading={userTicketsLoading} tickets={userTickets}
                                             onTransfer={handleTransferOpen} currentUser={currentUser}/>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>{t("tickets.My Transfers")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TransfersTable loading={userTicketsLoading} tickets={userTicketsTransfers}
                                                        onTransfer={handleTransferOpen}/>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )}
                    </div>
                    <div>
                        {selectedTab === 1 && <>
                            <TicketTable loading={userTicketsNextLoading} tickets={userTicketsNext}
                                         onTransfer={handleTransferOpen} currentUser={currentUser}/>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>{t("tickets.My Transfers")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TransfersTable loading={userTicketsLoading} tickets={userTicketsTransfersNext}
                                                    onTransfer={handleTransferOpen}/>
                                </AccordionDetails>
                            </Accordion>
                        </>}
                    </div>
                    <div>{selectedTab === 2 && <>
                        <TicketTable loading={conventionTicketsLoading} tickets={conventionTickets}
                                     onTransfer={handleTransferOpen} currentUser={currentUser}/>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{t("tickets.My Transfers")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TransfersTable loading={userTicketsLoading} tickets={conventionTicketsTransfers}
                                                onTransfer={handleTransferOpen}/>
                            </AccordionDetails>
                        </Accordion>
                    </>}</div>
                </>}

            {openDialog!==null && <TransferTicketDialog open={openDialog!==null}
                                                        currentUser={currentUser}
                                                        item={openDialog}
                                                        transferringTicket={transferringTicket}
                                                        transferTicketError={transferTicketError}
                                                        handleCloseDialog={handleCloseDialog}
                                                        handleTransfer={handleTransfer}/>}
        </div>
    ) : (
        <CircularProgress/>
    );
}

export default observer(TicketManagement);
