import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";

class TransactionsStore {
    constructor() {
        makeAutoObservable(this)
    }

    listError = null
    detailError = null
    eWalletTransactionsLoading = null
    transactionsDetailsLoading = null
    transactionsTrackingLoading = null
    pendingeWalletTransactionsLoading = null
    summary = null
    transactions = []
    eWalletPendingTransactions = []
    eWalletTransactions = []
    transactionsDetails = []
    transactionsTracking = []
    pendingeWallet = []

    allTransactionDetails = {}
    allTransactionDetailsLoading = {}
    transactionDetails = null
    transactionDetailsLoading = null


    setError(error, type = "list") {
        error = extractErrorMessage(error)
        this.listError = (type === "list" ? error : null);
        this.detailError = (type === "detail" ? error : null);
        this.detailError = (type === "transactionDetails" ? error : null);
     }

    loadTransactions = generateLoadList( "transactions", this, "transactionsLoading"
        , ( {year, month, transactionType, showPurchases, showDeposits})=>{
                    return services.UserProfile.Transactions()
                    .list(year, month, transactionType, showPurchases, showDeposits)
        }, "transactions", '[0]' )



    loadEwalletTransactions = generateLoadList( "eWalletTransactions", this, "eWalletTransactionsLoading"
        , async ( {year, month})=>{
              const result =  await  services.UserProfile.Wallet().Transactions().list(year, month)
            this.summary = result?.summary
            this.eWalletPendingTransactions = result?.transactions.filter(x=> x.transactionName === "Pending Deposit" )
            return result?.transactions.filter(x=> x.transactionName !== "Pending Deposit" )
        }, "eWalletTransactions",  )




    loadTransactionsDetails = generateLoadList( "transactionsDetails", this, "transactionsDetailsLoading"
        , ( {year, month, transactionType})=>{
                    return services.UserProfile.Transactions().details(year, month, transactionType)
        }, "transactionsDetails" )



    loadTransactionDetails = generateLoadEntity( "transactionDetails", this, "transactionDetailsLoading"
        , async ( orderId)=>{
                    this.allTransactionDetailsLoading[orderId] = true
                    try {
                        console.log("loading order details", orderId)
                        const result = await services.UserProfile.Transactions().orderDetails(orderId)
                        this.allTransactionDetails[orderId] = result
                        console.log("loaded order details", orderId)
                        return result
                    }
                    finally {
                        this.allTransactionDetailsLoading[orderId] = false
                    }
        }, "transactionDetails" )


    loadTransactionsTracking = generateLoadList( "transactionsTracking", this, "transactionsTrackingLoading"
        , ( {year, month, transactionType})=>{
                    return services.UserProfile.Transactions().tracking(year, month, transactionType)
        }, "transactionsTracking" )



    loadPendingeWalletTransactions = generateLoadList( "pendingeWallet", this, "pendingeWalletTransactionsLoading"
        , ()=>{
                    return services.UserProfile.Transactions().pendingeWallet()
        }, "pendingeWallet" )



    get transactionsDetailsIndex() {
        return groupBy(this.transactionsDetails, 'groupID')
    }

    get transactionsTrackingIndex() {
        return groupBy(this.transactionsTracking, 'groupID')
    }

    get transactionsTypes() {
        return  uniq ( this.transactions.map(x=>  x.transactionTypeID ) )
    }



}

export default TransactionsStore