import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
import React from "react";
import {ContentPageStyles} from "../styles";
import moment from "moment";
import {useTranslation} from "react-i18next";



const useStyles = makeStyles((theme) => ({

    table: {

    }
}));




function TicketTable({loading, tickets, onTransfer}){
    const {t} = useTranslation("starfish");
    const classes = useStyles();

    const handleTransfer = (item) => () => {
        if ( onTransfer) onTransfer(item);
    }

    return  <TableContainer className={classes.table}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left">{t("tickets.Ticket Holder Name")}</TableCell>
                    <TableCell align="left">{t("tickets.Qty")}</TableCell>
                    <TableCell align="left">{t("tickets.Transfer Date")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {loading
                    ?  <CircularProgress/>
                    :  <>
                        {tickets.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">{item.DisplayName}</TableCell>
                                    <TableCell align="left">{item.TicketQty}</TableCell>
                                    <TableCell align="left">{moment (item.UpdatedOn).format("MM/DD/YYYY h:mmA")}</TableCell>
                                </TableRow>
                            );
                        })}</>
                }

            </TableBody>
        </Table>
    </TableContainer>
}


export default TicketTable;