import React from "react";
import {observer} from "mobx-react";
import {
    Box,
    Button, Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PlusIcon from "@material-ui/icons/Add";
import Thermometer from "./thermometer";
import clsx from "clsx";
import {useStores} from "../../../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import {starFishColor} from "../../../components/styles";
import {formatNumber} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    thermometer: (props)=>({
        ...( props.fullWidth ? {width: "100%"} : {minWidth: 320}),
        margin: 10
    }),
    thermometerBody: {
        padding: 10
    },
    thermometerHeader: {
        // ...starFishColor(theme, "backgroundColor"),
        borderTopRightRadius: 3,
        borderTopLeftRadius: 3,
        padding: 6,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },

    primaryIcon:{
        ...starFishColor(theme)
    },

    requirementsHead:{
        ...starFishColor(theme, "backgroundColor"),
    },

    requirementsRow:{
        "&:nth-child(odd) ":{
            backgroundColor: "rgb(51,51,51)",
        },
        "&:nth-child(even) ":{
            fontWeight: "bold",
        },
        "&> td":{
            padding: 5,
            borderBottom: "none"
        }
    },

    requirementsDescription:{
        whiteSpace: "pre-wrap"
    },
    dialogPaper:{
        maxWidth: "800px",
        [theme.breakpoints.down("sm")]:{
            margin:"5px",
            maxWidth:"unset"
        }
    },
    dialogContent:{
        [theme.breakpoints.down("sm")]:{
         padding:"10px"
        }
    }
}));


function CustomerRequirements( {value, pvShort, requiredValue, showDetails, requirements, Container=Box, headerClass, fullWidth=false} ) {
    const {t} = useTranslation("teams");
    const {commonStore, myTeamsStore} = useStores();
    const classes = useStyles({fullWidth});
    const toggleConfirm = ()=> {
        commonStore.toggleConfirmOk( );
    }

    const showInfoPopup = (e)=>{
        e.stopPropagation();
        e.preventDefault();

        commonStore.showConfirm(<>
                <p className={classes.requirementsDescription}>
                    {t("customerRequirementsDescriptionPre")}
                </p>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.requirementsHead}>{t("bussines_status")}</TableCell>
                            <TableCell className={classes.requirementsHead}>{t("customer_pv_requirements")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("enrollmentMonthPlusNext4Months")}</TableCell>
                            <TableCell align={"center"}>50 PV</TableCell>
                        </TableRow>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("noSecondSalesTeamVolume")}</TableCell>
                            <TableCell align={"center"}>100 PV</TableCell>
                        </TableRow>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("hasSecondSalesTeamVolume")}</TableCell>
                            <TableCell align={"center"}>150 PV</TableCell>
                        </TableRow>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("qualifiedLeaderOrAbove")}</TableCell>
                            <TableCell align={"center"}>200 PV</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <p className={classes.requirementsDescription}>
                    {t("customerRequirementsDescription")}
                </p>
            </>
            , "OK"
            , null
            , null
            , null
            , undefined
            , {hideHeader:true, dialogProps:{ maxWidth:"sm", classes:{paper: classes.dialogPaper}}, dialogContentProps:{ classes: {root: classes.dialogContent}} })
    }


    const showMasterMerchantPopup = (e)=>{
        e.stopPropagation();
        e.preventDefault();

        commonStore.showConfirm(  <>
                <Typography>
                    {t("masterMerchantDescription")}
                </Typography>
            </>
            , "OK"
            , `${t("master")} ${t("merchant")}`
            , null
            , null
            , undefined
            , {  })
    }
    const selfReportPV = async () => {

        if ( requirements?.CanSelfReport ) {
            commonStore.showConfirm(  <>
                    <>
                        <FormControlLabel
                            control={<Checkbox color="primary" onClick={toggleConfirm} />}
                            label={t("SelfReportedDescription")}
                        />
                    </>
                </>
                , "Proceed"
                , null
                , ()=>{
                    myTeamsStore.selfReportPV()
                }
                , null
                , undefined
                , { hideHeader:true,  disabledOkButton: true})
        }
        else{
            commonStore.showConfirm(  <>
                    <>
                        {t("SelfReportImpossible")}
                        <br/>
                        <br/>
                        {t("SelfReportImpossibleRemain", {pv: formatNumber( pvShort,2 )})}
                    </>
                </>
                , "Close"
                , null
                , null
                , null
                , undefined
                , { hideHeader:true})
        }


    }


    return <>
        <Box className={clsx( classes.thermometerHeader, headerClass )}>
            {t("CustomerRequirements")}
            <InfoIcon onClick={showInfoPopup} style={{cursor: "pointer", marginLeft:10 }}/>
        </Box>
      <Container className={classes.thermometer}>
          <Box justifyContent= "center" display= "flex" alignItems= "center">
              {t("PartA")}
          </Box>
          <Thermometer hideValue={requirements?.CustomerRequirementsMet} value={requirements?.CustomerRequirementsMet? requiredValue : value} requiredValue={requiredValue}/>
          {showDetails &&
          <Box flexDirection="column" justifyContent= "center" display= "flex" alignItems= "center">
              { requirements?.CustomerRequirementsMet
                  ? <>
                      <i className={clsx(classes.primaryIcon, "fa", "fa-2x fa-check-circle")}></i>
                      <Box paddingTop={1} paddingBottom={1} >
                          {t("SelfReported")}
                      </Box>
                  </>
                  : <>
                      { !requirements?.CanSelfReport
                          && <Box paddingTop={1} paddingBottom={1}>{t("PVNeeded")}: {formatNumber(
                              requirements?.PVRequirement
                              - requirements?.RegisteredCustomerPV, 2)} PV</Box>}

                      <Button onClick={selfReportPV}
                              color={"primary"} variant={"contained"}>{t("SelfReport")}</Button>
                  </>
              }
          </Box>}
      </Container>
        <Container className={classes.thermometer}>
            <Box justifyContent= "center" display= "flex" alignItems= "center">
                {t("PartB")}
            </Box>
            <Thermometer hideValue={requirements?.GiftCardRequirementsMet}
                         labelKey={"CustomerStarfishUsage"}
                            currency={"$"}
                         value={requirements?.GiftCardRequirementsMet? requirements?.GiftCardNeeded : requirements?.GiftCardAmount}
                         requiredValue={requirements?.GiftCardNeeded}/>
        </Container>


      {showDetails && <>

          <Box paddingTop={1} paddingBottom={1} display={"flex"} justifyContent={"space-between"} style={{gap:"8px"}} >
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <Typography variant={"h5"}>{ requirements?.CustomerRequirementsMet ? " ✅ " : "❌ "}</Typography>
                  {t("PartA")}
              </Box>
              <Typography variant={"h5"}>+</Typography>
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  <Typography variant={"h5"}>{ requirements?.GiftCardRequirementsMet ? " ✅ " : "❌ "}</Typography>
                  {t("PartB")}
              </Box>
              <Typography variant={"h5"}>=</Typography>
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
                  <Typography>{t("master")}</Typography>
                  <Typography>{t("merchant")}</Typography>
              </Box>
              <InfoIcon onClick={showMasterMerchantPopup} style={{cursor: "pointer", marginLeft:10 }}/>
              <Typography variant={"h5"}>{ ( requirements?.CustomerRequirementsMet  && requirements?.GiftCardRequirementsMet ) ? " ✅ " : "❌ "}</Typography>
          </Box>
      </>}
  </>
}


export default observer(CustomerRequirements)