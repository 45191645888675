import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../hooks/use-stores";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      gap: "16px",
    },
  },
  selectedWrapper: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  inputLabel: {
    width: "300px",
    lineHeight: "40px",
    margin: "0 0 12px 0",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      margin: "0",
    },
  },
  searchWrapper: { position: "relative" },
  searchSuggesstions: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    maxHeight: "300px",
    overflow: "auto",
    zIndex: 10,
  },
  hideSearchSuggesstions: {
    display: "none",
  },
  input: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
  },
  error: {
    color: "#f50057",
    margin: "8px 0",
  },
}));

const UserSearch = ({
  selectedUser,
  setSelectedUser,
  labelClassName,
  toAccountLabel,
  isCustomerSearch,
}) => {
  const { t } = useTranslation("teams");
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const { userSearchStore, authStore } = useStores();
  const { currentUser } = authStore;
  const { searchDownlineCustomer, userSearchInfo, userSearchLoading } =
    userSearchStore;
  const [suggestions, setSuggestions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [mounted, setMounted] = useState(false);

  const handleSearch = () => {
    void searchDownlineCustomer(
      isCustomerSearch
        ? {
            userID: currentUser.userID,
            keyword: searchValue,
            isCustomerSearch,
          }
        : { userID: currentUser.userID, keyword: searchValue }
    );
  };

  const handleKey = (e) => {
    if (e.key === "Enter" && searchValue) {
      handleSearch();
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      if (userSearchInfo?.suggestions && userSearchInfo?.suggestions?.length) {
        setShowError(false);
        setShowSuggestions(true);
        setSuggestions(
          userSearchInfo.suggestions.map((item) => ({
            ...item,
            userId: item.userID || item.UserID,
            firstName: item.firstName || item.FirstName,
            lastName: item.lastName || item.LastName,
          }))
        );
      } else {
        setShowError(true);
      }
    }
  }, [userSearchInfo?.suggestions]);

  useEffect(() => {
    if (!searchValue) {
      setSuggestions([]);
    }
  }, [searchValue]);

  return (
    <Box className={classes.wrapper}>
      <p className={clsx(classes.inputLabel, labelClassName)}>
        {toAccountLabel || "Transfer To Account#:"}
      </p>
      {selectedUser ? (
        <div className={classes.selectedWrapper}>
          <span>
            {`${selectedUser.firstName} ${selectedUser.lastName} (${selectedUser.userId})`}
          </span>
          <Button
            color={"primary"}
            variant="contained"
            onClick={() => {
              setSelectedUser(null);
            }}
            className={classes.changeButton}
          >
            {t("Change")}
          </Button>
        </div>
      ) : (
        <div className={classes.searchWrapper}>
          <OutlinedInput
            autoComplete="off"
            disabled={userSearchLoading}
            className={classes.input}
            id="user-search-input"
            type={"text"}
            value={searchValue}
            margin={"dense"}
            fullWidth
            autoFocus
            onFocus={(e) => {
              setShowSuggestions(true);
            }}
            onBlur={(e) => {
              if (
                !e?.relatedTarget ||
                !e?.relatedTarget.classList.contains("user-suggestion")
              ) {
                setShowSuggestions(false);
              }
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={(e) => handleKey(e)}
            placeholder={t("searchPlaceholder")}
            endAdornment={
              <InputAdornment position="end">
                {userSearchLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconButton
                    disabled={searchValue === "" || userSearchLoading}
                    aria-label=""
                    onClick={handleSearch}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />

          <Paper
            className={clsx(
              classes.searchSuggesstions,
              showSuggestions ? "" : classes.hideSearchSuggesstions
            )}
          >
            {suggestions?.map((item) => {
              const firstName = item.FirstName || item.firstName;
              const lastName = item.LastName || item.lastName;
              const fullName = firstName + " " + lastName;
              return (
                <MenuItem
                  className="user-suggestion"
                  key={item.userID || item.UserID}
                  onClick={() => {
                    setSelectedUser(item);
                    setSuggestions([]);
                    setShowSuggestions(false);
                    setSearchValue("");
                  }}
                >
                  {fullName} ({item.userID || item.UserID})
                </MenuItem>
              );
            })}
          </Paper>
          {showError && (
            <p className={clsx(classes.error)}>
              {isCustomerSearch
                ? "Sorry, we can't locate that Customer in your upline or downline."
                : "Sorry, we can't locate that Member in your upline or downline."}
            </p>
          )}
        </div>
      )}
    </Box>
  );
};

export default observer(UserSearch);
