import { reaction, makeAutoObservable, runInAction } from 'mobx';
import services from "../services";
import { backOffDelay, extractErrorMessage, parseJwt } from "../utils/helpers";
import { setItem, removeItem, getItem } from "../utils/storage";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { boolean } from "boolean";
import { deleteCookie } from "../utils/cookieUtil";
import {generateDeleteEntity, generateLoadEntity} from "../utils/mobx";



class AuthStore {
    constructor(commonStore, menuStore, statStore) {
        this.commonStore = commonStore;
        this.menuStore = menuStore;
        this.statStore = statStore;
        makeAutoObservable(this, { commonStore: false });
        reaction(
            () => this.token,
            token => {
                if (token) {
                    console.log("token changed")
                    if (!this.currentUser && !this.inProgress )
                        return this.pullUser();
                } else {
                    removeItem('access_token');
                }
            }
        );

        reaction(
            () => this.currentUser,
            user => {
                if (user ) {
                    if ( !user.isConventionGuest) {
                        this.statStore.loadStats();
                        this.menuStore.loadMenu();
                        this.commonStore.loadNotifications();
                        if (user.customerOnboarding === false)
                            this.menuStore.showCustomerOnboardingBanner();
                    }
                } else {
                  //   this.companyStore.logout()
                }
            }
        );


    }

    token = boolean(getItem('is_guest')) ? null : getItem('access_token');
    guestPayload = null;
    guestTokenInvalid = false;
    guestToken = boolean(getItem('is_guest')) ? getItem('access_token') : null;
    inProgress = false;
    baseLoad = false;
    currentUser = null;
    subscriptions = null;

    loginError = null;
    emailLoginError = null;

    signupError = null;
    profileError = null;
    ltaiTokenError = null;
    email = null;
    updatingUser = false;

    ltaiData = null;
    ltaiTokenLoading = null;

    cancelPreEnrollLoading = null;

    setError(error, type = "login") {
        if (error instanceof Error) {
            error = this.extractErrorMessage(error);
            console.log(error);
        }
        this.loginError = (type === "login" ? error : null);
        this.emailLoginError = (type === "emailLogin" ? error : null);
        this.signupError = (type === "signup" ? error : null);
        this.profileError = (type === "profile" ? error : null);
        this.ltaiTokenError = (type === "ltaiToken" ? error : null);
    }
    async logout( code ) {
        this.inProgress = false;
        if ( this.isConventionGuest && code ===401 ) return; //not logout for conventional user
        this.forgetUser();
        this.setToken(null);
    }
    setToken(token, persist = false) {
        this.token = token;
        if ( persist ){
            setItem('access_token', token, 1);
        }
    }
    setEmail(email) {
        this.email = email;
    }
    get displayName() {
        if (!this.currentUser) return "";
        return this.currentUser.name || this.currentUser.email;
    }


    get isAuthenticated() {
        if ( this.currentUser &&  this.currentUser.isConventionGuest) return false
        return this.currentUser != null
    }

    get isConventionGuest() {
        if ( this.currentUser &&  this.currentUser.isConventionGuest) return true
        return false
    }


    get isGuest() {
        return this.guestPayload && this.guestPayload.isGuest;
    }

    // Returns true if the user is limited, for example, only having starfish access
    get isRestrictedUser() {
        //TODO: Removed Starfish restriction
        //return (this.isStarfish && !this.isFullMember);
    }


    get isShoppingOnlyUser() {
        return  Object.values( omit( this.subscriptions, "hasAutoshipPro") ).every( v => !v)
    }

    get isStarfish() {
        return this.currentUser?.isStarfish;
    }

    get isStarfishHomeEnabled() {
        return ["US", "CA"].includes( this.currentUser?.country)
        && (this.currentUser?.isStarfish || this.currentUser?.isSuperApp|| this.currentUser?.isSuperAppPlus)
    }

    isAccessibleStarfish(route) {
        let accessible = false;
        this.allowedStarfishRoutes.forEach(sfRoute => {
            if (route.includes(sfRoute)) {
                accessible = true;
                return;
            }
        });
        return accessible;
    }

    get patchedUserId(){
        if ( !this.currentUser) return null;
        if ([22, 82, 61487006, 61848996, 61371202, 61435203, 61314755 ].includes(this.currentUser.userID))
            return 61241449
    }

    get isAsOrin(){
        return true
        return this.patchedUserId === 61241449
    }

    get allowedStarfishRoutes() {
        return ['/news', '/share/shareenrollment', '/wallet/ewallet/add_more_money'];
    }

    get isFullMember() {
        return this.currentUser?.isSuperApp || this.currentUser?.isSuperAppPlus;
    }

    get isQuickBuy() {
        return this.guestPayload && this.guestPayload.isQuickBuy;
    }

    get shoppingUserDetails() {
        return this.currentUser && this.currentUser.shoppingUserDetails;
    }

    get guestCountry() {
        if (this.isGuest && this.guestPayload) return this.guestPayload.guestCountry;
    }

    get guestCartId() {
        if (this.isGuest && this.guestPayload) return this.guestPayload.cartID;
    }

    get country() {
        if (this.isGuest) return this.guestCountry;
        if (this.currentUser && this.currentUser.address) return this.currentUser.address.country;
    }

    get sbSetupCompleted() {
        if (this.currentUser) return this.currentUser.isShoppingBossSetup === true;
        return false;
    }
    setSBSetupCompleted() {
        if (this.currentUser)
            this.currentUser.isShoppingBossSetup = true;

    }


    get isMemberExpired() {
        return this.currentUser && this.currentUser.isMemberExpired === true;
    }

    forgetUser() {
        this.currentUser = undefined;
    }

    async resetGuestToken() {
        this.guestPayload = null;
        this.guestToken = null;
        this.guestTokenInvalid = true;
        setItem('is_guest', JSON.stringify(false));
    }

    async setGuestToken(token, payload) {

        this.guestPayload = payload;
        this.guestToken = token;
        this.guestTokenInvalid = false;
        if (token)
            setItem('access_token', token);
        if ( payload.lang ){
            this.commonStore.setLanguage(payload.lang, true);
        }
        setItem('is_guest', JSON.stringify(!!token));
    }

    async checkGuest(token) {
        this.guestPayload = null;
        try {
            let response = await services.Auth.checkGuest(token);
            runInAction(() => {
                if (response && response.payload) {
                    this.setGuestToken(token, response.payload);
                }
                else {
                    this.resetGuestToken();
                }
            });
        }
        catch (e) {
            runInAction(() => {
                this.resetGuestToken();
                this.commonStore.showError(extractErrorMessage(e));
            });
        }
    }

    async refreshUser() {
        try {
            let response = await services.Web.list();
            let user = response[2].userInfo;
            let subscriptions = response[0].subscriptions;
            Object.assign(this.currentUser, user);
            Object.assign(this.subscriptions, subscriptions);
        }
        catch (e) {
            console.error(e);
        }
    }

    async loginByGuid(loginGuid) {

        let response = await services.Auth.AndroidSignIn(loginGuid);
        if (await response.access_token) {
            setItem('access_token', response.access_token, 1);
            this.setToken(response.access_token);
            reaction(
                () => this.token,
                token => {
                    if (token) {
                        this.forgetUser(); //clear out previous user
                        return this.pullUser();
                    } else {
                        removeItem('access_token');
                    }
                }
            );
            reaction(
                () => this.currentUser,
                user => {
                    if (user) {
                        this.statStore.loadStats();
                        this.menuStore.loadMenu();
                        this.commonStore.loadNotifications();
                    } else {
                        // this.companyStore.logout()
                    }
                }
            );
        }
    }
    async loadShopUserDetails() {
        try {
            let response = await services.ShoppingCart.userDetails();
            runInAction(() => {
                if (response && response.length > 0)
                    this.currentUser.shoppingUserDetails = response[0];
            });
        }
        catch (e) {
            runInAction(() => {
                this.commonStore.showError(extractErrorMessage(e));
            });
        }

    }

    async pullUser(forceToken, counter = 0) {
        this.inProgress = true;
        this.baseLoad = true;
        if ( forceToken )
            this.setToken(forceToken, true);
        try {
            if (this.token) {

                if (this.parsedJwt  && this.parsedJwt.isConventionGuest ){
                    await services.Auth.verifyConventionGuest();
                    let user = {...this.parsedJwt}

                    if (user)
                        runInAction(() => {
                            this.currentUser = user;
                        });

                }
                else {
                    await services.Auth.verify();
                    //let baseLoad = await services.Base.fetch();
                    let response = await services.Web.list();
                    let profile = await services.UserProfile.list();
                    let ticketInfo = await services.ConventionProgramService.fetchHeaderInfo();

                    let subscriptions = response[0].subscriptions;
                    let user = response[2].userInfo;
                    Object.assign(user, pick(profile, "address", "preferredLanguage", "isLeader"));
                    let menuItemsStatus = response[1].menuItemsStatus;


                    if (user)
                        runInAction(() => {
                            this.currentUser = user;
                            this.commonStore.setLanguage(this.currentUser.preferredLanguage, true);
                            this.subscriptions = subscriptions && subscriptions[0];
                            this.menuStore.setMenuItemsStatus(menuItemsStatus);
                            this.currentUser.hasVIPTicket = ticketInfo.hasVIPTicket;
                            this.currentUser.hasStandardTicket = ticketInfo.hasStandardTicket;
                            this.currentUser.hasConventionTicket = ticketInfo.hasStandardTicket || ticketInfo.hasVIPTicket;
                        });
                }
            }
            else {
                this.logout();
            }
        }
        catch (e) {
            runInAction(() => {
                this.inProgress = false;
                this.baseLoad = false;
            });
            if (counter < 10 && e && (!e.response || (e.response.status !== 403 || e.response.status !== 401))) {
                setTimeout(async () => { await this.pullUser(forceToken,counter + 1); }, backOffDelay(500, counter));
            }
            else {
                return this.logout();
            }
        }
        finally {
            // console.log("done")
            runInAction(() => {
                this.inProgress = false;
                this.baseLoad = false;
                this.signingIn = false;
            });
        }
    }

    async signIn(userName, password, rememberMe) {

        if (this.inProgress) return;
        this.inProgress = true;
        this.signingIn = true;
        try {
            let result = await services.Auth.signIn(userName, password);
            if (result.access_token) {
                this.setToken(result.access_token);
                deleteCookie("cart_guid");
                deleteCookie("autoship_cart_guid");
                setItem('access_token', result.access_token, rememberMe ? 30 : 1);
                await this.commonStore.loadNotifications();
                //this.commonStore.
                this.pullUser()
            }
            runInAction(() => {
                this.baseLoad = true; //set it here not not blink  spinner on button

            });
        }
        catch (e) {
            this.setError(extractErrorMessage(e));
        }
        finally {
            runInAction(() => {
                this.inProgress = false;
            });

        }
    }

    async setCountry(country) {
        this.currentUser.address.country = country;
    }

    async updatePassword(currentPassword, newPassword) {
        if (this.updatingUser) return;
        this.updatingUser = true;
        try {
            let result = await services.UserProfile.PasswordService().update({ currentPassword, newPassword });
            runInAction(() => {
                this.commonStore.success(result.message);
            });
            return true;
        }
        catch (e) {
            runInAction(() => {
                this.commonStore.showError("Check your current password and try again.");
            });
            return false;
        }
        finally {
            runInAction(() => {
                this.updatingUser = false;
            });
        }
    }


    async updateUsername(newUsername, password) {
        if (this.updatingUser) return;
        this.updatingUser = true;
        try {
            let result = await services.UserProfile.UsernameService().update({ newUsername, password });
            runInAction(() => {
                this.commonStore.success(result.message);
            });
            return true;
        }
        catch (e) {
            runInAction(() => {
                this.commonStore.showError("Check your current password and try again.");
            });
            return false;
        }
        finally {
            runInAction(() => {
                this.updatingUser = false;
            });
        }
    }


    async declineMembership() {

        try {
            await services.UserProfile.declineEnroll();
            runInAction(() => {
                this.currentUser.hasMemberInvite = false;
            });
        }
        catch (e) {
            runInAction(() => {
                this.commonStore.showError(extractErrorMessage(e));
            });

        }
    }

    get loginUrl() {
        return `/signin?redirect=${window.location.pathname}${ encodeURIComponent(window.location.search)}`;
    }

    get loginGuid() {
        if (!this.token) return null;
        let access_token = parseJwt(this.token);
        return access_token.loginGuid;
    }

    get parsedJwt() {
        if (!this.token) return null;
       return parseJwt(this.token);
    }

    get spouseUsername() {
        return this.currentUser && this.currentUser.spouse && this.currentUser.spouse.username;
    }


    loadLTAIToken = generateLoadEntity("ltaiToken", this, "ltaiTokenLoading"
        , ()=>{
            return services.Auth.getLTAIToken();
        }, "ltaiData" )


    cancelPreEnroll = generateDeleteEntity("cancelPreEnroll", this, "cancelPreEnrollLoading",
        async ()=>{
            await services.UserProfile.cancelPreEnroll();
            runInAction(()=>{
                this.currentUser.pendingMemberScheduledDate = null;
            });
        }

        )
    setScheduled(date){
        this.currentUser.pendingMemberScheduledDate = date;
    }


}

export default AuthStore;