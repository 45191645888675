import React, {useMemo} from 'react';
import {Box, makeStyles} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {useStores} from "../../../hooks/use-stores";
import {starFishColor} from "../../../components/styles";



const useStyles = makeStyles((theme) => ({
    whiteBanner:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        height:30,
        backgroundColor:"white",
        color:"black",
        fontSize:16,
        fontWeight:"bold",
        padding: theme.spacing(1),
        gap: 4,

    },
    popupText:{
        whiteSpace:"pre-wrap",
    }

}));

function RewardPanel(){
    const { t } = useTranslation();
    const classes = useStyles();
    const {commonStore, statStore, authStore} = useStores();
    const {currentUser} = authStore;
    const { bonusPerks, giftCardsStats} = statStore;
    const showReward =  !currentUser.isMember  && currentUser?.isStarfish && !currentUser?.isSuperAppPlus;


    const  level1Reward =  useMemo(() => {
        return bonusPerks?.spendAmount1
    },[bonusPerks])

    const  level2Reward =  useMemo(() => {
        return bonusPerks?.spendAmount2
    },[bonusPerks])

    const  level1RewardSize =  useMemo(() => {
        return bonusPerks?.earnBonus1
    },[bonusPerks])

    const  level2RewardSize =  useMemo(() => {
        return bonusPerks?.earnBonus2
    },[bonusPerks])

    const level1RewardPassed = useMemo(() => {
        return giftCardsStats?.giftCardTotal >= level1Reward
    },[level1Reward, giftCardsStats ])

    const level2RewardPassed = useMemo(() => {
        return giftCardsStats?.giftCardTotal >= level2Reward
    },[level2Reward, giftCardsStats ])

    const showRewardInfoPopup = ()=>{

        commonStore.showConfirm( <p className={classes.popupText}>{t("infoReward")} </p>
            , "OK"
            , t("infoRewardTitle")
            , null
            , null
            , undefined
            , {  })
    }

    return  <>
        { showReward && giftCardsStats &&
            <Box display="flex" justifyContent="center" alignItems={"center"} className={classes.whiteBanner}>
                <span> {t("spend")} ${level1Reward} {t("earnBonus")} ${level1RewardSize} </span>
                {level1RewardPassed &&  <img src={"/img/ok.png"} width={16} height={16}/> }
                <InfoIcon onClick={showRewardInfoPopup} style={{cursor: "pointer"}}/>
            </Box>
        }
        { showReward && giftCardsStats && level1RewardPassed &&
            <Box display="flex" justifyContent="center" alignItems={"center"} className={classes.whiteBanner}>
                <span> {t("spend")} ${level2Reward} {t("earnAdditional")}  ${level2RewardSize} </span>
                {level2RewardPassed &&  <img src={"/img/ok.png"} width={16} height={16}/> }
            </Box>
        }
    </>


}


export default observer(RewardPanel);