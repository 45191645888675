import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class ProductSearchStore {
  productSearchInfo = null;
  productSearchLoading = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "productSearchError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  productSearch = generateLoadEntity(
    "productSearchInfo",
    this,
    "productSearchLoading",
    async (params) => {
      const result = await services.ProductService.search(params);
      return {suggestions: result.suggestions.filter((product) => !product.Archived  && !product.IsHidden && !product.IsDeleted  )};
    },
    "productSearchInfo"
  );
}

export default ProductSearchStore;
