import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faShare, faTimes} from "@fortawesome/pro-light-svg-icons";
import React, {useState} from "react";
import {observer} from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {Box, CircularProgress, Link} from "@material-ui/core";
import {useStores} from "../../../../hooks/use-stores";
import CopyLinksPage from "../../../../components/CopyLinksPage";



const useStyles = makeStyles((theme) => ({
    img:{
        height:300,
        width: "min-content"
    },
    faIcons:{
        marginRight:8,
    },
}))


const ShareProductModal = ({ t, product,  showShareProductModal,
                               shareProductErrorMessage,
                               toggleShowShareProductModal,
                               clearShareProductErrorMessage, sendProductEmailError

}) => {
    const  classes = useStyles()
    const {shopStore} = useStores()
    const [link, setLink] = useState(null)
    const [generating, setGenerating] = useState(false)
    const [qrCodeShown, setQRCodeShown] = React.useState(false);

    const showQRCode = () => {
        setQRCodeShown(!qrCodeShown);
    };

    const generateLink = async (e) => {
        try {
            setGenerating(true);
            let result = await shopStore.getCustomerEnrollWithProductLink({
                productID: product.productID
            });
            if (result) {
                setLink(result);
            }
        }
        finally {
            setGenerating(false);
        }
    }

    if (!showShareProductModal) {
        return null;
    }

    const loading = false

    return (
        <Dialog open={showShareProductModal} onClose={toggleShowShareProductModal} maxWidth={"sm"} fullWidth>
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>{t('shopping:shareproduct.share')}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => toggleShowShareProductModal()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent >
                {link
                    ? <CopyLinksPage
                        loading={loading}
                        shareLink={link.url}
                        onGenerateNewPage={() => { setLink(null) }}
                        showQRCode={showQRCode}
                        qrCodeShown={qrCodeShown}
                    />
                    : <Grid container justifyContent={"flex-start"} direction={"column"} alignContent={"center"}>
                    <Grid item xs={12} className="modal-share-product-alert" hidden={shareProductErrorMessage === null}>
                        <span className="modal-share-product-closebtn" onClick={() => clearShareProductErrorMessage()}>&times;</span>
                        <strong>{t('shopping:error.errortitle')}</strong> {shareProductErrorMessage}
                    </Grid>

                    <Grid container item  xs={12}  justifyContent={"center"}>
                        <img className={classes.img} src={product.productMedia[0].imageURL} alt="" />
                    </Grid>
                    <Grid container item  xs={12}  >
                        <Typography variant={"h5"}>{product.productName}</Typography>
                    </Grid>
                    <Grid container item  xs={12}  >
                        <Typography variant={"subtitle1"}><div  dangerouslySetInnerHTML={{
                            __html: product.productSummary
                        }}></div> </Typography>
                    </Grid>
                </Grid>}
            </DialogContent>
            <DialogActions>
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                    {sendProductEmailError && <Alert color={"error"}>{sendProductEmailError}</Alert>}
                    {!link && <Button
                        fullWidth
                        className={classes.generateNewLink}
                        color="primary"
                        variant="contained"
                        size="large"
                        style={{ color: 'white' }}
                        onClick={generateLink}
                        disabled={generating}>
                        {generating
                            ? <CircularProgress size={16} />
                            : <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />}
                        {t("Generate New Link")}
                    </Button>}
                </Box>
            </DialogActions>
        </Dialog>
    )
}


export  default observer(ShareProductModal)