import React, { useEffect, } from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem, ListItemAvatar,
    ListItemText,
    makeStyles, Paper,
    Tab,
    Typography,
} from '@material-ui/core';
import { useStores } from "../../hooks/use-stores";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { TabContext, TabList } from "@material-ui/lab";
import Vimeo from "@u-wave/react-vimeo";
import { useLocalObservable } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
    productImage: {
        height: "100%",
        width: "100%",
        borderRadius: "5%",
        transition: "all .3s"
    },
    productVideo: {
        height: 400,
        width: "100%"
    },
    root: {
        height: "100%"
    },
    tab: {
        minWidth: 72,
        fontSize: '0.75rem'
    },
    videoContainer: {
        padding: 20
    }

}));

const names = {
    9367838: { en: "Home", "es":"Inicio", "fr":"Accueil" },
    5288407: { en: "Fin.\n Literacy", "es":"Educación Financiera", "fr":"Éducation financière" },
    5288406: { en:"Leadership", "es":"Liderazgo", "fr":"Leadership" },
    6260350: { en:"Leverage", "es":"Maximizar", "fr":"Levier" }
};

function mapName(album, t, language) {
    //try map full name:
    let stripped = album.name.replace("Life Accelerator – ", "").replace("Life Accelerator — ", "");
    let translated = t(stripped);
    if (translated !== stripped) // it means translation found
        return translated;

    if (names[album.id])
        return names[album.id][language];
    return stripped;
}

const Life = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { commonStore, shoppingBossStore, authStore, } = useStores();
    const { eAcceleratorValid, eAcceleratorValidityLoading, loadingAlbums, albums, eAcceleratorBannerImageUrl } = shoppingBossStore;
    const { loginGuid, } = authStore;


    useEffect(() => {
        shoppingBossStore.verifyEAccelerator();
    }, []);

    useEffect(() => {
        if (eAcceleratorValid)
            shoppingBossStore.loadAlbums();
    }, [eAcceleratorValid]);

    const localStore = useLocalObservable(() => ({
        albums: albums,
        selectedTab: "1",
        videoId: null,

        get effectiveAlbum() {
            if (this.albums.length > 0)
                return this.albums[Number(this.selectedTab)];

        },
        get effectiveVideoId() {
            if (this.videoId) return this.videoId;
            else if (this.albums.length > 0)
                return this.albums[0].videos[0].id;

        },
        setSelectedTab(value) {
            this.selectedTab = value;
        },

        setVideoId(value) {
            this.videoId = value;
        }


    }));

    const onTabSelected = (event, newValue) => {
        localStore.setSelectedTab(newValue);
    };

    const selectVideo = (videoId) => {
        localStore.setVideoId(videoId);
    };

    if (eAcceleratorValidityLoading === true || eAcceleratorValidityLoading === null || loadingAlbums === true)
        return <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>;

    if (eAcceleratorValid === false)
        return <Grid container spacing={3} justifyContent={"center"}>
            <Grid container xs={12} md={8} justifyContent={"center"}>
                <Grid item xs={12} md={12} container justifyContent={"center"} alignItems={'center'}>
                    <Vimeo id="frameVideo" allow="fullscreen"
                        responsive
                        className={classes.productImage}
                        video={738637854} />
                </Grid>
            </Grid>
            <Grid container xs={10} md={7} >
                <div dangerouslySetInnerHTML={{ __html: t('starfish:eAccelerator.description') }} />
            </Grid>
            <Grid item xs={10} md={7} justifyContent={"center"} alignItems={'center'}>
                <Button href={`https://shop.mainhomepage.com/quickcheckout.aspx?login=${loginGuid}&type=product&id=16232`} target={"_blank"}
                    color="primary" variant="contained" size="large" fullWidth style={{ color: 'white' }}>
                    {t('starfish:eAccelerator.buyNow')}
                </Button>
            </Grid>
        </Grid>;

    return (

        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} lg={7} className={classes.videoContainer}>
                    {localStore.effectiveVideoId
                        && <Vimeo id="frameVideo" allow="fullscreen"
                            responsive
                            video={localStore.effectiveVideoId} />}
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <TabContext value={localStore.selectedTab}>
                        <Paper square>
                            <TabList className={classes.tabsList} onChange={onTabSelected} indicatorColor="primary">
                                {
                                    albums.map((album, key) => {
                                        return <Tab className={classes.tab}
                                            label={mapName(album, t, commonStore.language)} value={key.toString()} key={album.id} />;
                                    })
                                }
                            </TabList>
                        </Paper>
                        <List>

                            {localStore.effectiveAlbum && localStore.effectiveAlbum.videos.map(video => {
                                return <ListItem button onClick={e => { selectVideo(video.id); }}>
                                    <ListItemAvatar>
                                        <Avatar variant={"square"} src={video.thumbnail}>V</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={video.name.split("|")[0]} secondary={video.name.split("|")[1]} />

                                </ListItem>;

                            })}
                        </List>

                    </TabContext>
                </Grid>

            </Grid>
        </Box>
    );
};

export default observer(Life);