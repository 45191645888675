import React, {useEffect, useMemo, useState} from 'react';
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    makeStyles, Popover
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faCopy, faShare, } from "@fortawesome/pro-light-svg-icons";
import { useStores } from '../hooks/use-stores';
import { extractErrorMessage } from '../utils/helpers';
import Vimeo from '@u-wave/react-vimeo';
import UplineSearch from "../containers/Members/controls/UplineSearch";
import CopyLinksPage from "./CopyLinksPage";
import {observer} from "mobx-react";

const useStyles = makeStyles((theme) => ({
    qrCodeHelperText: {
        marginTop: `${theme.spacing(3)}px`,
        textAlign: 'center'
    },
    faIcons: {
        marginRight: "5px"
    },
    divider: {
        margin: "20px 0"
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
        color: "white"
    },
    card: {
        width: "100%",
    },
    qrCodeSpacing: {
        marginBottom: `${theme.spacing(2)}px`
    },
    enrollmentLink: {
        textAlign: 'center',
        width: '100%'
    },
    generateNewLink: {
        marginTop: '18px'
    },
    preziIframe: {
        width: '100%',
        maxWidth: '800px',
        minHeight: '400px',
    }
}));

const SelectLinkPage = observer( ({ loading, hasAccess, links, header, description, generateShareLink
                                      , handleSelectionChange, handleUserSelectFromSearch
                                      , selectionError, selection, selectionArgument }) => {
    const {t} = useTranslation();
    const {myTeamsStore} = useStores();
    const classes = useStyles();
    const [anchorSearchEl, setAnchorSearchEl] = React.useState(null);

    const onSelectionChange = (event) => {
        handleSelectionChange(event);

    }

    const onSelectUplineClick = (event) => {
        setAnchorSearchEl( event.currentTarget );
    }
    const handleSearchClose = () => {
        myTeamsStore.resetSearch()
        setAnchorSearchEl(null);
    }

    const onUserSelectFromSearch = (upline) => {
        handleUserSelectFromSearch(upline);
        handleSearchClose()
    }

    const searchOpen = Boolean(anchorSearchEl);
    const {    searchPanelSize} = myTeamsStore;
    return (
        <>
            <h2>{header}</h2>
            <h4>{description}</h4>
            <FormControl fullWidth className={classes.margin} variant="outlined" error={Boolean(selectionError)}>
                <InputLabel htmlFor="age-native-simple">{t("Please Select")}</InputLabel>
                {links &&
                    links.length > 0 && <Select
                        required
                        placeholder={t("Please Select")}
                        label={t("Enrollment Link Type")+":"}
                        className={classes.enrollmentLink}
                        onChange={onSelectionChange}
                        value={selection || ''}>
                        {links.map((val, index) =>
                            <MenuItem key={index} value={val}>{t(val.text)}</MenuItem>
                        )}
                    </Select>
                }
                <FormHelperText>{selectionError}</FormHelperText>
            </FormControl>
            {
                selection?.vimeoId && <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0px' }}>
                    <Vimeo
                        responsive
                        style={{ width: '100%' }}
                        video={selection.vimeoId}
                    />
                </Grid>
            }
            {
                selection?.preziId &&
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0px' }}>
                    <iframe className={classes.preziIframe} src={`https://prezi.com/view/${selection.preziId}`} />
                </Grid>
            }
            {
                (selection?.value || "").startsWith("starfish.kitupline") &&
                <Grid item xs={12} style={{ display: 'flex', gap:8, alignItems:"center",  width: '100%', padding: '10px 0px' }}>
                    {selectionArgument?.upline
                        ? <Typography>{selectionArgument.upline.userName}[{selectionArgument.upline.userID}]</Typography>
                        : <Typography>Please Select Upline</Typography>
                    }
                    <Button onClick={onSelectUplineClick} variant={"contained"} color={"primary"}>
                        {selectionArgument?.upline
                            ? "Change Upline"
                            : "Select Upline"}</Button>
                    <Popover
                        id="search-menu"
                        anchorEl={anchorSearchEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        open={searchOpen}
                        onClose={handleSearchClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * searchPanelSize,
                                width: '30ch',
                            },
                        }}
                    >
                    <UplineSearch show={(selection?.value || "").startsWith("starfish.kitupline") }
                                  handleUserSelectFromSearch={onUserSelectFromSearch}
                                  onlyId={false}
                                  enableUplineSearch={true}
                    />
                    </Popover>
                </Grid>
            }

            <Grid item xs={12} lg={12}>
                <Button
                    fullWidth
                    className={classes.generateNewLink}
                    color="primary"
                    variant="contained"
                    size="large"
                    style={{ color: 'white' }}
                    onClick={generateShareLink}
                    disabled={!!hasAccess ? loading : true}>
                    <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                    {t("Generate Another Link")}
                </Button>
            </Grid>
        </>
    )
})


/*
Props:
    links: [{value: '', text: '', vimeoId?: string, preziId?: string }];
    header: string;
    description: string;
    hasAccess: boolean;
    onGenerateLink: (value: string used to generate the embed) => linkUrl)
*/
const ShareLinks = ({ links, onGenerateLink, header, description, hasAccess }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [shareLink, setShareLink] = useState('');
    const [qrCodeShown, setQRCodeShown] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [selection, setSelection] = React.useState(null);
    const [selectionArgument, setSelectionArgument] = React.useState(null);

    const [selectionError, setSelectionError] = React.useState(null);
    const [page, setPage] = React.useState('0');

    useEffect(() => {
        if (links && links.length  === 1) {
            setSelection(links[0]);
        }
    },[links])
    const generateShareLink = async () => {
        try {
            if (!selection || selection === '' || !selection.text) {
                setSelectionError('Please select a link type to generate');
                return;
            }
            setError(null);
            setLoading(true);
            setPage('1');

            const response = await onGenerateLink(selection, selectionArgument?.upline?.userID);
            if (response.msgID) {
                setShareLink(`https://www.mainhomepage.com/msg.aspx/${response.msgID}`);
            } else {
                setShareLink(response.url);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setPage('0');
            setError(extractErrorMessage(e));
        }
    };

    const showQRCode = () => {
        setQRCodeShown(!qrCodeShown);
    };

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
        setSelectionError(null);
    }
    const handleUserSelectFromSearch= (upline) => {
        setSelectionArgument( { upline } )
    }

    const allFilled = useMemo(() => {
        if ( selection && (selection.value || "").startsWith( 'starfish.kitupline') && selectionArgument?.upline == null ) {
            return false;
        }
        return true;
    },[selection, selectionArgument]);


    const translationKey = header && header.replace(/\s+/g, "_").replace(/\.+/g, "_").toLowerCase()
    let translatedHeader = t(`starfish:${translationKey}`)
    let isTranslated = translatedHeader !== translationKey
    if (!isTranslated  ){
        translatedHeader = header
    }


    return (
        <Container maxWidth={"md"} className={classes.root}>
            <Grid container spacing={3}>
                <Card variant="outlined" className={classes.card}>
                    <CardContent>
                        {error && <Alert severity={"error"}>{error}</Alert>}
                        {
                            page === '0'
                                ?
                                <SelectLinkPage
                                    links={links}
                                    loading={loading}
                                    hasAccess={hasAccess && allFilled}
                                    header={translatedHeader}
                                    description={description}
                                    generateShareLink={generateShareLink}
                                    selection={selection}
                                    selectionArgument={selectionArgument}
                                    selectionError={selectionError}
                                    handleSelectionChange={handleSelectionChange}
                                    handleUserSelectFromSearch={handleUserSelectFromSearch}
                                />
                                : null
                        }
                        {
                            page === '1'
                                ?
                                <>
                                    <CopyLinksPage
                                        loading={loading}
                                        shareLink={shareLink}
                                        onGenerateNewPage={() => { setPage('0'); setSelection(null); }}
                                        showQRCode={showQRCode}
                                        qrCodeShown={qrCodeShown}
                                    />
                                </>
                                : null
                        }
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
                {
                    page == '1' && <Grid container style={{ marginTop: '8px' }}>
                        <Grid item xs={12}>
                            <Typography>
                                {t("share:enrollPostInstructions")}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container>
    );
};

export default ShareLinks;