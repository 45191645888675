import {Badge, Box, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useTransition} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {formatNumber} from "../../../utils/helpers";
import moment from "moment";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import SummaryRows from "./SummaryRows";



const useStyles = makeStyles((theme) => ({
    summaryCell:{
        backgroundColor:"black",
        border:0,
        paddingTop: 0,
        paddingBottom: 0
    },
    summaryCellFirst:{
        paddingTop: 10,
    },
    noWrap:{
        whiteSpace: "nowrap"
    },
    summaryCellLast:{
        paddingBottom: 10
    },
    badge: {
        padding: '0 4px',
        "& .MuiBadge-badge": {
            position: "static",
            transform: "unset",
            transformOrigin: "unset",
        }
    },
}));


function CustomersGiftCardsDataTable({data}) {
    const classes = useStyles();
    const {t} = useTranslation("myvolume");

    const total = data.reduce((acc, row) => acc + row.giftCardTotal||0, 0);

    if ( !data || data.length === 0 )
        return <Box display="flex" justifyContent={"center"} flex={1}>
            {t("no_activity")}
        </Box>


    return     <Table stickyHeader  size="small"  style={{backgroundColor:"#424242"}}>
        <TableHead>
            <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("account#")}</TableCell>
                <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>{t("total")}{" "}($)</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((row) => {
                return <TableRow key={row.UserID}>
                    <TableCell>{row.firstName} {row.lastName} </TableCell>
                    <TableCell>{row.userID}</TableCell>
                    <TableCell align={"right"}>{row.giftCardTotal ===0? "-":  `${formatNumber(row.giftCardTotal,2)}`}</TableCell>
                </TableRow>
            })}
            <TableRow>
                <TableCell colSpan={3} align={"right"} >
                    <Box className={classes.summaryGrid}>
                        <span className={classes.label}>{t("total")}($): </span>
                        <span className={classes.value}>{formatNumber(total, 2)}</span>
                    </Box>
                </TableCell>
            </TableRow>
        </TableBody>

    </Table>
}

export  default CustomersGiftCardsDataTable;