import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useStores } from "../../../hooks/use-stores";
import {useTranslation} from "react-i18next";

export const selectedProductsKey = "product-transfer-data";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  inputArea: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      gap: "16px",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  inputLabel: {
    width: "300px",
    margin: "0 0 12px 0",
    [theme.breakpoints.up("sm")]: {
      margin: "0",
      width: "300px",
    },
  },
  searchWrapper: { position: "relative" },
  searchSuggesstions: {
    position: "absolute",
    left: 0,
    maxWidth: "calc(100vw - 32px)",
    maxHeight: "300px",
    overflow: "auto",
    zIndex: 10,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "none",
    },
  },
  hideSearchSuggesstions: {
    display: "none",
  },
  topSearchSuggesstions: {
    bottom: "100%",
  },
  bottomSearchSuggesstions: {
    top: "100%",
  },
  input: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
  },
  inputNumberWrapper: {
    textAlign: "right",
  },
  inputNumber: {
    background: "transparent",
    display: "block",
    width: "70px",
    color: "white",
    border: "1px solid gray",
    height: "40px",
    padding: "0 8px 0 16px",
    borderRadius: "4px",
  },
  table: {
    marginTop: "24px",
  },
}));

const ProductSearch = ({
  selectedUser,
  selectedProducts,
  setSelectedProducts,
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const { productSearchStore, authStore } = useStores();
  const { productSearch, productSearchInfo, productSearchLoading } =
    productSearchStore;
  const { currentUser } = authStore;
  const [suggestions, setSuggestions] = useState([]);
  const [selectingProduct, setSelectingProduct] = useState(null);
  const {t} = useTranslation();

  const updateLocalStorage = (newData) => {
    localStorage.setItem(
      `${selectedProductsKey}-${currentUser.userID}`,
      JSON.stringify(newData)
    );
  };

  const handleSearch = () => {
    void productSearch({
      userId: currentUser?.userID,
      keyword: searchValue,
      physicalSKUOnly: 1,
    });
  };

  const handleKey = (e) => {
    if (e.key === "Enter" && searchValue) {
      handleSearch();
    }
  };

  const openSuggestion = () => {
    const inputEl = inputRef.current;
    if (inputEl) {
      const { top } = inputEl.getBoundingClientRect();
      const inputHeight = inputEl.clientHeight;
      const windowHeight = window.innerHeight;
      const spaceLeft = windowHeight - top - inputHeight;
      if (spaceLeft <= 300) {
        setShowSuggestions("top");
      } else {
        setShowSuggestions(true);
      }
    }
  };

  useEffect(() => {
    if (
      productSearchInfo?.suggestions &&
      productSearchInfo?.suggestions?.length
    ) {
      setSuggestions(
        productSearchInfo.suggestions.filter(
          (item) =>
            !selectedProducts.find(
              (product) => product.ProductRef === item.ProductRef
            )
        )
      );
      openSuggestion();
    }
  }, [productSearchInfo?.suggestions]);

  return (
    <Box className={classes.wrapper}>
      <div className={classes.inputArea}>
        <p className={classes.inputLabel}>
          {t("pvtransfer.productSearchLabel")}:
        </p>
        <div className={classes.searchWrapper}>
          <OutlinedInput
            autoComplete="off"
            placeholder={selectedUser ? "" : t("pvtransfer.productSearch")}
            disabled={!selectedUser || productSearchLoading}
            ref={inputRef}
            className={classes.input}
            id="product-search-input"
            type={"text"}
            value={searchValue}
            margin={"dense"}
            fullWidth
            onFocus={(e) => openSuggestion()}
            onBlur={(e) => {
              if (
                !e?.relatedTarget ||
                !e?.relatedTarget.classList.contains("product-suggestion")
              ) {
                setShowSuggestions(false);
              }
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={(e) => handleKey(e)}
            endAdornment={
              <InputAdornment position="end">
                {productSearchLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconButton
                    disabled={searchValue === "" || productSearchLoading}
                    aria-label=""
                    onClick={handleSearch}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />

          <Paper
            className={clsx(
              classes.searchSuggesstions,
              !!showSuggestions
                ? showSuggestions === "top"
                  ? classes.topSearchSuggesstions
                  : classes.bottomSearchSuggesstions
                : classes.hideSearchSuggesstions
            )}
          >
            {suggestions?.map((item) => {
              return selectedUser ? (
                <MenuItem
                  className="product-suggestion"
                  key={item.ProductRef}
                  onClick={() => {
                    setSuggestions([]);
                    setShowSuggestions(false);
                    setSearchValue(`${item.ProductRef} - ${item.ProductName}`);
                    setSelectingProduct({
                      ...item,
                      toAccountId: selectedUser.UserID,
                      toAccountEmail: selectedUser.Email,
                      toAccountName: `${selectedUser.firstName} ${selectedUser.lastName}`,
                      Qty: 1,
                    });
                  }}
                >
                  {item.ProductRef} - {item.ProductName}
                </MenuItem>
              ) : null;
            })}
          </Paper>
        </div>
      </div>

      <div className={classes.inputArea}>
        <p className={classes.inputLabel}> {t("pvtransfer.quantity")}: </p>
        <input
          type="number"
          disabled={searchValue === ""}
          className={classes.inputNumber}
          value={selectingProduct?.Qty || 0}
          onChange={(e) => {
            const value = Number(e.target.value);
            setSelectingProduct((selectingProduct) => ({
              ...selectingProduct,
              Qty: value,
            }));
          }}
        />
      </div>

      <div className={classes.inputArea}>
        <Button
          disabled={
            !selectingProduct || selectingProduct.Qty <= 0 || !selectedUser
          }
          variant="contained"
          color="primary"
          onClick={() => {
            const newSelectedProducts = [...selectedProducts, selectingProduct];
            setSelectedProducts(newSelectedProducts);
            updateLocalStorage(newSelectedProducts);
            setSearchValue("");
            setSelectingProduct(null);
          }}
        >
          {t("pvtransfer.addProduct")}
        </Button>
      </div>

      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("pvtransfer.productSKU")}</TableCell>
              <TableCell>{t("pvtransfer.producName")}</TableCell>
              <TableCell align="right">PV</TableCell>
              <TableCell align="right">{t("pvtransfer.quantity")}</TableCell>
              <TableCell align="right">{t("pvtransfer.toAccount")}</TableCell>
              <TableCell align="right">Total PV</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProducts.map((product) => {
              return (
                <TableRow key={product.ProductRef}>
                  <TableCell>{product.ProductRef}</TableCell>
                  <TableCell>{product.ProductName}</TableCell>
                  <TableCell align="right">{product.PointValue}</TableCell>
                  <TableCell align="right">
                    <div className={classes.inputNumberWrapper}>
                      {product.Qty}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    #&nbsp;{product.toAccountId}
                  </TableCell>
                  <TableCell align="right">
                    {product.PointValue * product.Qty}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        const newSelectedProducts = selectedProducts.filter(
                          (item) => item.ProductRef !== product.ProductRef
                        );
                        updateLocalStorage(newSelectedProducts);
                        setSelectedProducts(newSelectedProducts);
                      }}
                    >
                      DELETE
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default observer(ProductSearch);
