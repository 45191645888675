

import React, {useMemo} from 'react';
import {Box, Divider, makeStyles} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {useTranslation} from "react-i18next";

import {observer} from "mobx-react";
import clsx from "clsx";
import {formatNumberOrZero} from "../../../utils/helpers";
import {useStores} from "../../../hooks/use-stores";


const useStyles = makeStyles((theme) => ({
    left:{
        display:"inline-flex",
        paddingRight: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            fontSize:14,
        },
    },
    right:{
        display:"inline-flex",
        paddingLeft: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            fontSize:14,
        },
    },

}));


function SpendingPanel({starFishMembers}){
    const { t } = useTranslation();
    const {statStore, authStore} = useStores();
    const { giftCardsStats, giftCardsPVStats } = statStore;
    const classes = useStyles();

    return <Box display="flex">
        <div className={classes.left}>{t('starfish:home.transactions')}:{" "}
            {giftCardsStats.transactionCount} </div>
        <Divider orientation="vertical" flexItem/>
        <div
            className={clsx(classes.right, {[classes.left]: starFishMembers})}>{t('starfish:home.perks_earned')}:
            ${formatNumberOrZero(giftCardsStats.perksEarned, 2)}</div>
    </Box>

}

export default observer(SpendingPanel);