import {makeAutoObservable} from 'mobx'
import services from "../services"
import {flatten, uniqBy} from "lodash";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadList} from "../utils/mobx";


const PPS_IDS = { "en":  18981, "es": 22209, "fr": 19817}
const SAA_IDS = { "en":  17553, "es": 19144, "fr": 18091}

class SalesAchieverAudiosStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    series = []
    ppsSeries = []
    seriesLoading = false
    mediaType = false

    error = null

    setError(error, type = "series") {
        error = extractErrorMessage(error)
        this.error = error
    }

    loadSeries = generateLoadList( "series", this, "seriesLoading"
        , async (lang)=>{
            if ( this.libraryStore.library.length ===0 )
                await this.libraryStore.loadLibrary();
            try {

                let pps = await services.Library.details(PPS_IDS[lang])
                if (pps){
                    this.ppsSeries.replace(pps[0].files)
                }
            }
            catch(e){
            }
            return services.Library.details(SAA_IDS[lang])
        }, "series", "[0].files")



}

export default SalesAchieverAudiosStore