import React, { useMemo, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { useStores } from "../../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import MaterialTextField from "../../../components/inputs/MaterialTextField";
import { useHistory } from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialSelectWithSuggetion from "../../../components/inputs/MaterialSelectWithSuggestion";
import Typography from "@material-ui/core/Typography";
import MaskedMaterialTextField from "../../../components/inputs/MaskedMaterialTextField";
import { cardLogo } from "../../../utils/helpers";
import PageTitle from "../../../components/PageTitle";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CardExpMonth from "../../../components/inputs/CardExpMonth";
import CardExpYear from "../../../components/inputs/CardExpYear";
import {useTranslation} from "react-i18next";
import {securityBanner} from "../../../components/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        position: "relative",
        marginBottom: theme.spacing(3),

    },
    logo: {
        marginRight: theme.spacing(1)
    },
    cardIcon: {
        height: 50,
        margin: theme.spacing(1),
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    defaultMark: {
        marginRight: "auto",
        textTransform: "uppercase"
    },
    alertTitle: {
        paddingBottom: `${theme.spacing(2)}px`,
    },
    alert: {
        minHeight: `${theme.spacing(15)}px`,
        minWidth: `${theme.spacing(30)}px`,
        width: `${theme.spacing(30)}px`,
    },
    alertCloseButton: {
        position: 'absolute',
        top: `-${theme.spacing(1)}px`,
        right: `-${theme.spacing(0)}px`,
    },
    ...securityBanner(theme)
}));

export default observer(function CardInfoEdit({ parentMatch, hideHeader, onSaved, onCancel }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const { commonStore, userBillingInfoStore, userProfileStore } = useStores();
    const { cards, cardForm, editingCard, allLoading, error } = userBillingInfoStore;
    const { allCountries, regionsByCountry } = userProfileStore;
    const [showBilling, setShowBilling] = useState();
    const params = useParams();
    const history = useHistory();
    const { cardID: paymentID } = params;
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const showDefault = (!editingCard && cards.length > 0) && (!editingCard || editingCard.primary !== "1");

    const handleOnSaved = () => {
        if ( onSaved )
            onSaved()
        else
            history.push(parentMatch.url);
    };

    const handleCountryChanged = () => {
        if (cardForm && cardForm.$("country").value)
            userProfileStore.loadRegionsByCountry(cardForm.$("country").value);
    };

    const navigateBackToParent = () => {
        if ( onCancel )
            onCancel()
        else
            history.push(parentMatch.url);
    };

    const starfishCountries = () => {
        const validCountries = ['CA', 'US']
        return allCountries.filter(c => validCountries.includes(c.countryShortCode));
    }

    const overriddenErrorMessageForStarfish = useMemo(() => {
        // Override the 'exists' error to display custom error for starfish
        if (commonStore.onStarfishOrRoyalStarfish && error && error.includes('card you are trying to add exists')) {
            return 'The card you are trying to add already exists on another account. Please add another card or for help contact us using Profile < Help.';
        }

        return error;
    }, [error]);


    useEffect(() => {
        (async () => {
            await userProfileStore.loadAllCountries();
            await userProfileStore.loadRegionsByCountry("US");
            await userBillingInfoStore.loadAll();
            userBillingInfoStore.setEditCardForm(paymentID, handleOnSaved);
        })();

    }, []);

    const logo = editingCard && cardLogo(editingCard.type);
    return <>

        {(allLoading || !cardForm)
            ? <CircularProgress />
            : <>
                {error
                    ? (<Dialog onClose={handleClose} aria-labelledby="alert-dialog" open={open}>
                        <Alert variant="outlined" severity="error" className={classes.alert}>
                            <AlertTitle className={classes.alertTitle}>Error Saving Data
                            <IconButton
                                    aria-label="close"
                                    className={classes.alertCloseButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </AlertTitle>
                            {overriddenErrorMessageForStarfish}
                        </Alert>
                    </Dialog>)
                    : null}
                {!hideHeader && <PageTitle>{editingCard?t("editCard"):t("newCard")}</PageTitle>}
                {!editingCard && <div className="col">
                    <small>
                        <span data-i18n="weAccept">{t("weAccept")}</span>:<br/>
                        <img src={`/${userProfileStore.userCountry?.countryShortCode == 'CA' ? 'cardsCA' : 'cardsUS'}.png`} className={classes.cardIcon} />
                    </small>
                    <Alert severity={"info"}
                           className={classes.securityBanner}
                           variant={"filled"}>{t("security:cardNameBanner")}</Alert>
                </div>}
                <Grid className={classes.root} container>
                    <Paper className={classes.section}>
                        <Grid container spacing={3}>
                            {editingCard
                                ? <Grid item xs={12} >
                                    <div>{logo && <img className={classes.logo} src={logo} />}{`************${editingCard.last4}`}</div>
                                    <div>{editingCard.firstName} {editingCard.lastName}</div>
                                    <div>{editingCard.city}, {editingCard.region}, {editingCard.postalCode}</div>
                                </Grid>
                                : <>
                                    <Grid item xs={12} md={3}>
                                        <MaterialSelectWithSuggetion
                                            fullWidth
                                            margin="normal"
                                            className={classes.textField}
                                            field={cardForm.$("cardType")}
                                            labelAccessor={(x => x.label)}
                                            valueAccessor={(x => x.value)}
                                            options={cardForm.$("cardType").extra || []} />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <MaskedMaterialTextField
                                            fullWidth
                                            margin="normal"
                                            id="txtCCNumber"
                                            field={cardForm.$("cardNumber")}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MaterialTextField
                                            fullWidth
                                            margin="normal"
                                            field={cardForm.$("firstName")}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MaterialTextField
                                            fullWidth
                                            margin="normal"
                                            field={cardForm.$("lastName")}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                </>}

                            <CardExpMonth form={cardForm} fieldName={"expMonth"}/>
                            <CardExpYear form={cardForm} fieldName={"expYear"}/>
                            <Grid item xs={12} md={3} xl={2}>
                                <MaterialTextField
                                    fullWidth
                                    margin="normal"
                                    field={cardForm.$("cvv")}
                                    inputProps={{
                                        minLength: 3, maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*'
                                    }}
                                    className={classes.textField}
                                />
                            </Grid>

                            {
                                (editingCard && !showBilling)
                                    ? <Grid item xs={12} >
                                        <Button variant={"outlined"} size={"small"} color={"primary"} onClick={e => { setShowBilling(true); }}>{t("editBillingAddress")}</Button>
                                    </Grid>
                                    : <>
                                        <Grid item xs={12}>
                                            <Typography variant={"subtitle2"}>{t("billingAddress")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={cardForm.$("address1")}
                                                className={classes.textField}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={cardForm.$("address2")}
                                                className={classes.textField}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={cardForm.$("city")}
                                                className={classes.textField}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialSelectWithSuggetion
                                                autoWidth
                                                fullWidth
                                                label="Language"
                                                margin="normal"
                                                valueAccessor={(x=>x.shortCode)}
                                                labelAccessor={(x=>x.name)}
                                                className={classes.textField}
                                                field={cardForm.$("region")}
                                                options={regionsByCountry[cardForm.$("country").value] || []} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaskedMaterialTextField
                                                fullWidth
                                                margin="normal"
                                                field={cardForm.$("zip")}
                                                className={classes.textField}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <MaterialSelectWithSuggetion
                                                fullWidth
                                                label={t("country")}
                                                margin="normal"
                                                labelAccessor={(x => t(x.countryName))}
                                                valueAccessor={(x => x.countryShortCode)}
                                                className={classes.textField}
                                                field={cardForm.$("country")}
                                                options={commonStore.onStarfishOrRoyalStarfish ? starfishCountries() : allCountries}
                                                onChange={handleCountryChanged}
                                            />
                                        </Grid>
                                    </>
                            }


                            {
                                (editingCard)
                                && <Grid item xs={12} >
                                    {t("multiplePaymentAccounts")}
                                  </Grid>
                            }

                        </Grid>
                    </Paper>
                    <Grid container item xs={12} className={classes.buttons}>
                        <Grid  item xs={12} md={6}>
                            <span className={classes.defaultMark}>
                                {showDefault &&
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={!!cardForm.$("primary").value}
                                            onChange={e => { cardForm.$("primary").onChange(e.target.checked); }}
                                            color="primary"
                                        />
                                    }
                                        label={t("makeDefaultPaymentMethod")}
                                    />}

                            </span>
                        </Grid>
                        <Grid container  item xs={12} md={6} justifyContent={"flex-end"}>
                            <Button
                                onClick={navigateBackToParent}
                                color="primary" className={classes.textField} >{t("cancel")}</Button>

                            <Button variant="contained"
                                onClick={cardForm.onSubmit}
                                color="primary" className={classes.textField} >{t("save")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        }
    </>;
});