import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button, CircularProgress,
    MenuItem,
    Select, Table, TableBody, TableHead,
    Typography, withStyles
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStores} from "../../hooks/use-stores";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableRow from "@material-ui/core/TableRow";
import {accordion} from "../../components/styles";
import {formatNumber, formatNumberOrZero, monthAndYear} from "../../utils/helpers";
import TableCell from "./controls/TableCell";
import clsx from "clsx";
import BonusTable from "./controls/BonusTable";
import {reaction} from "mobx";
import Periods from "../RoyalStarfish/components/Periods";


const useStyles = makeStyles((theme) => ({
    qualifyingTeam: {
        display: "table-cell",
        background: "#e3e3e3",
        verticalAlign: "top",
        textAlign: "center",
        minWidth: 75,


    },

    row: {
        border: "1px dashed #fff",
        minWidth: "80px",
        maxWidth: 90,
        paddingTop: "15px",
        paddingBottom: "5px",
        minHeight: "70px",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        fontSize: 13
    },
    cellName: {
        overflow: "hidden",
        padding: "0 5px",
        textOverflow: "ellipsis"
    },
    rowTeam: {
        paddingTop: "5px",
        minHeight: "80px",
        lineHeight: "20px",
        backgroundColor: "#000",
        color: "#fff !important",
    },

    rowQualify: {
        backgroundColor: "#47a447",
        color: "#fff"
    },

    rowPending: {
        border: "1px solid #da2127 !important"
    },

    rowEmpty: {
        backgroundColor: "#fff"
    },

    clickable: {
        cursor: "pointer"
    },
    ...accordion(theme),

    text: {
        width: "90%",
        overflow: "hidden",
        position: "relative",
        "&::after": {
            content: '"......................................................................................................................................................................................................................................................................... "',
            position: "absolute",
            paddingLeft: 5
        }
    },
    pad1: {
        paddingLeft: 40
    },
    pad2: {
        paddingLeft: 70
    },

    printBasic:{
        '@media print': {
            "& .MuiAccordion-root": {
                display: "none !important"
            },
            "& .MuiAccordion-root.mainSummaryBlock": {
                display: "block !important"
            }
        }
    },

    printDetails:{
        '@media print': {
            "& .MuiCollapse-hidden": {
                height: "auto !important",
                visibility: "visible !important"
            }
        }
    }

}));


function MyIncome() {
    const classes = useStyles();
    const {t} = useTranslation("myincome");
    const {authStore, commonStore, myIncomeStore, periodsStore} = useStores();
    const {periodsLoading} = periodsStore;
    const {currentUser} = authStore;
    const [printWithDetails, setPrintWithDetails] = React.useState(false);
    const {
        PVPeriodID, data, dataLoading
    } = myIncomeStore;



    useEffect(() => {

        return reaction(
            () => myIncomeStore.PVPeriodID,
            PVPeriodID => {
                if (PVPeriodID) {
                    void myIncomeStore.loadData();
                }
            }
        );
    }, []);

    useEffect(() => {
        if (currentUser) {
            (async () => {
                await periodsStore.loadPeriods(currentUser.userID);
                await myIncomeStore.loadData();
            })();
        }
    }, [currentUser])

    const handleChangePeriod = (e) => {
        myIncomeStore.setPVPeriodID(e.target.value)
    }

    const showSalesAchiever = PVPeriodID < 147
    const showCTB = PVPeriodID >= 147

    const selectedPeriod = periodsStore.periods.find(p => p.PVPeriodID === PVPeriodID)


    const ctbQualified = data?.memberDetails?.CTB !== 0
    const blbQualified = data?.memberDetails?.BiBB !== 0

    const handlePrint=(withDetails)=>()=>{
        setPrintWithDetails(withDetails)
        window.setTimeout(()=>window.print(), 1000)
    }

    return (
        <>
            {periodsLoading
                ? <> <CircularProgress/> </>
                : <Box className={"noprint"} display={"flex"} alignItems={"center"}  justifyContent={"space-between" }>
                    <Box  style={{gap: 16}} display={"flex"} alignItems={"center"}>
                        <Typography variant={"subtitle1"}>{t("my_income")}</Typography>
                        <Periods margin={"dense"}  periods={periodsStore.paidPeriods} PVPeriodID={PVPeriodID} handleChangePeriod={handleChangePeriod}/>
                    </Box>
                    <Box  style={{gap: 16}} display={"flex"} alignItems={"center"}>
                        <Button  variant={"contained"} color={"primary"}  className={"noprint"} onClick={handlePrint(false)}>{t("Print Summary")}</Button>
                        <Button  variant={"contained"} color={"primary"}  className={"noprint"} onClick={handlePrint(true)}>{t("Print Details")}</Button>
                    </Box>
                </Box>}

            <br/>
            <br/>
            {dataLoading && <CircularProgress/>}
            {dataLoading !== null && !dataLoading && periodsLoading !== null && !periodsLoading && <>

                <Box paddingLeft={2} className={clsx("printContent", {
                    [classes.printBasic]:!printWithDetails,
                    [classes.printDetails]:printWithDetails})}>
                    {selectedPeriod && <Typography variant={"subtitle1"}>{t("Statement for")} { monthAndYear(selectedPeriod.StartDate, commonStore.language,t ) }</Typography>}

                    <Accordion classes={{root: clsx( classes.accordion, "mainSummaryBlock" )}} defaultExpanded>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`bonus_summary`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            {data && <>
                                <Table stickyHeader size="small" style={{marginBottom: 20}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('type')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus_amount')}</TableCell>
                                            <TableCell align={"right"} style={{minWidth: 100}}>{t('ytd')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                className={classes.text}>{t("retail_sales_commission")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.RetailTotalBonus, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.RetailTotalBonus, 2)}</TableCell>
                                        </TableRow>
                                        {showSalesAchiever && <>
                                            <TableRow>
                                                <TableCell className={classes.text}>{t("sales_achiever")}</TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.memberDetails?.SalesAchiever, 2)}</TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.SalesAchiever, 2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.text}>{t("sales_achiever_pro")}</TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.memberDetails?.SalesAchieverPro, 2)}</TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.SalesAchieverPro, 2)}</TableCell>
                                            </TableRow>
                                        </>}
                                        <TableRow>
                                            <TableCell className={classes.text}>{t("cpb")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.CPB, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.CPB, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.text}>{t("ccb")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.CCB, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.CCB, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.pad1}
                                                       colSpan={3}>{t("bonus_chart_commissions")}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={clsx(classes.text, classes.pad2)}>{t("personal_bonus")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.PersonalTotalBonus, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.PersonalTotalBonus, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={clsx(classes.text, classes.pad2)}>{t("customer_bonus")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.CustomerTotalBonus, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.CustomerTotalBonus, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={clsx(classes.text, classes.pad2)}>{t("differential_bonus")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.DiffTotalBonus, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.DiffTotalBonus, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={clsx(classes.text, classes.pad2)}>{t("double_differential_bonus")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.DifferentialBoost, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.DifferentialBoost, 2)}</TableCell>
                                        </TableRow>
                                        {showCTB && <TableRow>
                                            <TableCell className={classes.text}>{t("ctb")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.CTB, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.CTB, 2)}</TableCell>
                                        </TableRow>}
                                        <TableRow>
                                            <TableCell className={classes.text}>{t("blb")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.BiBB , 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.BiBB, 2)}</TableCell>
                                        </TableRow>
                                        {(data?.memberDetails?.AGO !==0 || data?.memberDetailsYTD?.AGO !== 0)  && <TableRow>
                                            <TableCell className={classes.text}>{t("ago")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.AGO, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.AGO, 2)}</TableCell>
                                        </TableRow>}
                                        {(data?.memberDetails?.CurrencyAdjustment !==0 || data?.memberDetailsYTD?.CurrencyAdjustment !== 0)  && <TableRow>
                                            <TableCell className={classes.text}>{t("CurrencyAdjustment")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.CurrencyAdjustment, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.CurrencyAdjustment, 2)}</TableCell>
                                        </TableRow>}
                                        {(data?.memberDetails?.AdjustmentPreviousPeriod !==0 || data?.memberDetailsYTD?.AdjustmentPreviousPeriod !== 0)  && <TableRow>
                                            <TableCell className={classes.text}>{t("AdjustPreviousPeriod")}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.AdjustmentPreviousPeriod, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.AdjustmentPreviousPeriod, 2)}</TableCell>
                                        </TableRow>}
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('bonus_total')}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetails?.Total, 2)}</TableCell>
                                            <TableCell
                                                align={"right"}>${formatNumberOrZero(data?.memberDetailsYTD?.Total, 2)}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3}>{" "}</TableCell>
                                    </TableRow>

                                </Table>
                                {data?.rptAllocation && <Table stickyHeader size="small" style={{marginBottom: 20}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('allocation')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus_amount')}</TableCell>
                                            <TableCell align={"right"} style={{minWidth: 100}}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.rptAllocation.map((allocation, index) => {
                                            return <TableRow key={index}>
                                                <TableCell className={classes.text}>{allocation.Name}</TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(allocation.Amount, 2)}</TableCell>
                                                <TableCell align={"right"}></TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>}
                            </>
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`rank_summary`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            {data?.rptRanks && <Table stickyHeader size="small" style={{marginBottom: 20}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('type')}</TableCell>
                                        <TableCell>{t('rank')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.rptRanks.map((allocation, index) => {
                                        return <TableRow key={index}>
                                            <TableCell className={classes.text}>{t(allocation.RankType)}</TableCell>
                                            <TableCell>{t(allocation.RankName)}</TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`retail_sales_commission`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        dataSet={data?.rptRetail}
                                        qualified={!(data?.rptRetail.length === 0 && data?.memberDetails?.RetailTotalBonus === 0)}/>
                        </AccordionDetails>
                    </Accordion>

                    {showSalesAchiever && <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`sales_achiever`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        dataSet={data?.rptSalesAchiever}
                                        qualified={!(data?.rptSalesAchiever.length === 0 && data?.memberDetails?.SalesAchiever === 0)}/>
                        </AccordionDetails>
                    </Accordion>}

                    {showSalesAchiever && <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`sales_achiever_pro`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        showPV={false}
                                        showPercent={false}
                                        dataSet={data?.rptSalesAchieverPro}
                                        qualified={data?.memberDetails?.SalesAchieverPro !== 0}/>
                        </AccordionDetails>
                    </Accordion>}
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`cpb`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        showPercent={false}
                                        dataSet={data?.rptCPB}
                                        qualified={!(data?.memberDetails?.CPB === 0 && data?.rptCPB.length === 0)}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`ccb`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        showPercent={false}
                                        dataSet={data?.rptCCB}
                                        qualified={!(data?.memberDetails?.CCB === 0 && data?.rptCCB.length === 0)}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`personal_bonus`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        dataSet={data?.rptPersonal}
                                        qualified={!(data?.memberDetails?.PersonalTotalBonus === 0 && data?.rptPersonal.length === 0)}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`customer_bonus`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            <BonusTable t={t}
                                        dataSet={data?.rptCustomer}
                                        qualified={!(data?.memberDetails?.CustomerTotalBonus === 0 && data?.rptCustomer.length === 0)}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`differential_bonus`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            {data?.rptDiff &&
                                <Table stickyHeader size="small" style={{marginBottom: 20}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('member_organization')}</TableCell>
                                            <TableCell align={"right"}>{t('group_pv')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus_percentage')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus percentage_differential')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus_amount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(data?.memberDetails?.DiffTotalBonus === 0 && data?.rptDiff.length === 0)
                                            ? <TableRow>
                                                <TableCell colSpan={5} align={"center"}>{t('not_qualified')}</TableCell>
                                            </TableRow>
                                            : data?.rptDiff.map((item, index) => {
                                                return <TableRow key={index}>
                                                    <TableCell>{item.MemberName}</TableCell>
                                                    <TableCell
                                                        align={"right"}>{formatNumberOrZero(item.GroupPV, 2)}</TableCell>
                                                    <TableCell
                                                        align={"right"}>{formatNumberOrZero(item.BonusPercent * 100, 2)}%</TableCell>
                                                    <TableCell
                                                        align={"right"}>{formatNumberOrZero((data?.memberDetails?.MemberBonusPercent - item.BonusPercent) * 100, 2)}%</TableCell>
                                                    <TableCell
                                                        align={"right"}>${formatNumberOrZero(item.Bonus, 2)}</TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                    {!(data?.memberDetails?.DiffTotalBonus === 0 && data?.rptDiff.length === 0) &&
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.DiffTotalPV, 2)}</TableCell>
                                                <TableCell align={"right"}></TableCell>
                                                <TableCell align={"right"}></TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.memberDetails?.DiffTotalBonus, 2)}</TableCell>
                                            </TableRow>
                                        </TableHead>}
                                </Table>}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`double_differential_bonus`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            {data?.rptDiffBoost &&
                                <Table stickyHeader size="small" style={{marginBottom: 20}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('member_organization')}</TableCell>
                                            <TableCell align={"right"}>{t('group_pv')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus_percentage')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus percentage_differential')}</TableCell>
                                            <TableCell align={"right"}>{t('bonus_amount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(data?.memberDetails?.DifferentialBoost === 0 && data?.rptDiffBoost.length === 0)
                                            ? <TableRow>
                                                <TableCell colSpan={5} align={"center"}>{t('not_qualified')}</TableCell>
                                            </TableRow>
                                            : data?.rptDiffBoost.map((item, index) => {
                                                return <TableRow key={index}>
                                                    <TableCell>{item.MemberName}</TableCell>
                                                    <TableCell
                                                        align={"right"}>{formatNumberOrZero(item.GroupPV, 2)}</TableCell>
                                                    <TableCell
                                                        align={"right"}>{formatNumberOrZero(item.BonusPercent * 100, 2)}%</TableCell>
                                                    <TableCell
                                                        align={"right"}>{formatNumberOrZero((data?.memberDetails?.MemberBonusPercent - item.BonusPercent) * 100, 2)}%</TableCell>
                                                    <TableCell
                                                        align={"right"}>${formatNumberOrZero(item.Bonus, 2)}</TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                    {!(data?.memberDetails?.DifferentialBoost === 0 && data?.rptDiffBoost.length === 0) &&
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.DifferentialBoostPV, 2)}</TableCell>
                                                <TableCell align={"right"}></TableCell>
                                                <TableCell align={"right"}></TableCell>
                                                <TableCell
                                                    align={"right"}>${formatNumberOrZero(data?.memberDetails?.DifferentialBoost, 2)}</TableCell>
                                            </TableRow>
                                        </TableHead>}
                                </Table>}
                        </AccordionDetails>
                    </Accordion>
                    {showCTB && <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`ctb`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            {data?.rptCTB &&
                                <Table stickyHeader  size="small" style={{marginBottom: 20}}>
                                    {!ctbQualified && <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={5} align={"center"}>{t('not_qualified')}</TableCell>
                                        </TableRow>
                                    </TableBody>}
                                    {ctbQualified && <>
                                        {data?.rptCTB.map((item, index) => {
                                            return <Fragment key={index}>
                                                <TableHead>
                                                    { item.rankName &&
                                                        <TableRow>
                                                            <TableCell style={{backgroundColor:"initial"}} align="center" colspan={5}>{item.rankName}</TableCell>
                                                        </TableRow>}
                                                    <TableRow>
                                                        <TableCell align="center" colspan={5}>{item.GroupName}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{t('member_organization')}</TableCell>
                                                        <TableCell align={"right"}>{t('group_pv')}</TableCell>
                                                        <TableCell align={"right"}>{t('outside_pv')}</TableCell>
                                                        <TableCell align={"right"}>{t('percentage')}</TableCell>
                                                        <TableCell align={"right"}>{t('bonus')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{item.MemberName}</TableCell>
                                                        <TableCell
                                                            align={"right"}>{formatNumberOrZero(item.GroupPV, 2)}</TableCell> <TableCell
                                                            align={"right"}>{item.SubtractPV1 !=null ? formatNumberOrZero(item.SubtractPV1, 2):"N/A"}</TableCell>
                                                        <TableCell
                                                            align={"right"}>{formatNumberOrZero(item.BonusPercent * 100, 2)}%</TableCell>
                                                        <TableCell
                                                            align={"right"}>${formatNumberOrZero(item.Bonus, 2)}</TableCell>
                                                    </TableRow>
                                                </TableBody>

                                            </Fragment>
                                        })}
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={5}
                                                           align={"right"}>${formatNumberOrZero(data?.memberDetails?.CTB, 2)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                </>}
                                </Table>}
                        </AccordionDetails>
                    </Accordion>}
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography className={classes.heading}>{t(`blb`)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetailsColumn}>
                            {data?.rptBLB &&
                                <Table stickyHeader  size="small" style={{marginBottom: 20}}>
                                    {!blbQualified && <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={5} align={"center"}>{t('not_qualified')}</TableCell>
                                        </TableRow>
                                    </TableBody>}
                                    {blbQualified && <>
                                        {data?.rptBLB.map((item, index) => {
                                            return <Fragment key={index}>
                                                {(item.rankName  || item.GroupName) && <TableHead>
                                                    { item.rankName &&
                                                        <TableRow>
                                                            <TableCell style={{backgroundColor:"initial"}} align="center" colspan={5}>{item.rankName}</TableCell>
                                                        </TableRow>}
                                                    {item.GroupName && <>
                                                        <TableRow>
                                                            <TableCell align="center" colspan={5}>{item.GroupName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>{t('member_organization')}</TableCell>
                                                            <TableCell align={"right"}>{t('group_pv')}</TableCell>
                                                            <TableCell align={"right"}>{t('outside_pv')}</TableCell>
                                                            <TableCell align={"right"}>{t('percentage')}</TableCell>
                                                            <TableCell align={"right"}>{t('bonus')}</TableCell>
                                                        </TableRow>
                                                    </>}
                                                </TableHead>}
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{item.MemberName}</TableCell>
                                                        <TableCell
                                                            align={"right"}>{formatNumberOrZero(item.GroupPV, 2)}</TableCell> <TableCell
                                                        align={"right"}>{item.SubtractPV1 !=null ? formatNumberOrZero(item.SubtractPV1, 2):"N/A"}</TableCell>
                                                        <TableCell
                                                            align={"right"}>{formatNumberOrZero(item.BonusPercent * 100, 2)}%</TableCell>
                                                        <TableCell
                                                            align={"right"}>${formatNumberOrZero(item.Bonus, 2)}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Fragment>
                                        })}
                                        {( PVPeriodID < 157) &&
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={5}
                                                               align={"right"}>${formatNumberOrZero(data?.memberDetails?.BiBB, 2)}</TableCell>
                                                </TableRow>
                                            </TableHead>}
                                        {( PVPeriodID >= 157) &&
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={4}
                                                               align={"right"}>{t("blbSubtotal")}</TableCell>
                                                    <TableCell
                                                        align={"right"}>${formatNumberOrZero(data?.memberDetails?.BiBB, 2)}</TableCell>
                                                </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4}
                                                           align={"right"}>{t("blbBoost%")}</TableCell>
                                                <TableCell
                                                           align={"right"}>{formatNumberOrZero(data?.memberDetails?.BLBBoostPercentage>=0.05?  data?.memberDetails?.BLBBoostPercentage*100:0, 2)}% - {t("blbBoostBegins")} 5%</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={4}
                                                           align={"right"}>{t("blbBoost")}</TableCell>
                                                <TableCell
                                                           align={"right"}>${formatNumberOrZero(data?.memberDetails?.BLBBoostPercentage>=0.05?  data?.memberDetails?.BLBBoost: 0, 2)}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={4}
                                                           align={"right"}>{t("blbBoostTotal")}</TableCell>
                                                <TableCell
                                                           align={"right"}>${formatNumberOrZero(data?.memberDetails?.BiBB, 2)}</TableCell>
                                            </TableRow>

                                        </TableHead>}
                                    </>}
                                </Table>}
                        </AccordionDetails>
                    </Accordion>
                </Box>

            </>}

        </>


);
}


export default observer(MyIncome);