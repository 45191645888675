import {useTranslation} from "react-i18next";
import {useStores} from "../hooks/use-stores";
import {Button, CircularProgress, Divider, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faQrcode, faShare} from "@fortawesome/pro-light-svg-icons";
import QRCode from "react-qr-code";
import React from "react";


const useStyles = makeStyles((theme) => ({
    qrCodeHelperText: {
        marginTop: `${theme.spacing(3)}px`,
        textAlign: 'center'
    },
    faIcons: {
        marginRight: "5px"
    },
    divider: {
        margin: "20px 0"
    },
    qrCodeSpacing: {
        marginBottom: `${theme.spacing(2)}px`
    },
    enrollmentLink: {
        textAlign: 'center',
        width: '100%'
    },
    generateNewLink: {
        marginTop: '18px'
    }
}));


const CopyLinksPage = ({ loading, shareLink, showQRCode, qrCodeShown, onGenerateNewPage }) => {
    const { t } = useTranslation();
    const { commonStore } = useStores();
    const classes = useStyles();

    return (
        <>
            {
                loading === true
                    ?
                    <>
                        <CircularProgress />
                    </>
                    :
                    !shareLink
                        ? ''
                        :
                        <>
                            <TextField
                                disabled={true}
                                fullWidth
                                variant={"filled"}
                                label={t("starfish:share.shareLink")}
                                value={shareLink} />
                            <br />
                            <br />
                            <br />
                            <br />
                            <Grid container spacing={3} justify={"center"}>
                                <Grid item xs={10} md={7} >
                                    <CopyToClipboard text={shareLink}
                                                     onCopy={() => commonStore.success("Link copied")}>
                                        <Button fullWidth color="primary" variant="contained" size="large" style={{ color: 'white' }} disabled={!shareLink}>
                                            <FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" />
                                            {t("starfish:share.copyLink")}
                                        </Button>
                                    </CopyToClipboard>
                                </Grid>
                                <Grid item xs={10} md={7} >
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        style={{ color: 'white' }}
                                        href={`sms:?&body=${shareLink}`}
                                        disabled={!shareLink}>
                                        <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                        {t("starfish:share.textLink")}
                                    </Button>
                                </Grid>
                                <Grid item xs={10} md={7} container justify={"center"}>
                                    <Button
                                        fullWidth
                                        className={classes.qrCodeSpacing}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        style={{ color: 'white' }}
                                        onClick={showQRCode}
                                        disabled={!shareLink}>
                                        <FontAwesomeIcon className={classes.faIcons} icon={faQrcode} size="1x" />
                                        {t("starfish:share.qrCode")}
                                    </Button>
                                    {qrCodeShown
                                        ?
                                        <>
                                            <Grid item xs={12} container justify={"center"}>
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={shareLink}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.qrCodeHelperText}>
                                                    Scan code above to share link
                                                </Typography>
                                            </Grid>
                                        </>
                                        : null}
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid item xs={10} md={7} >
                                    <Button fullWidth
                                            className={classes.generateNewLink}
                                            color="default"
                                            variant="contained"
                                            size="large"
                                            style={{ color: 'black' }}
                                            onClick={onGenerateNewPage}
                                    >
                                        <FontAwesomeIcon className={classes.faIcons} icon={faShare} size="1x" />
                                        {t("Generate Another Link")}
                                    </Button>
                                </Grid>
                                <Divider className={classes.divider} />
                            </Grid>
                        </>
            }
        </>
    );
}


export default CopyLinksPage;