import React from "react";
import {Box, Typography} from "@material-ui/core";
import Progress from "../../../components/Progress";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../utils/helpers";


function Thermometer({value, requiredValue
                         , hideLabel = false
                         , hideValue = false
                         , orientation = "vertical"
                         , padding = "10px"
                         , height = 20
                         , valueVariant= "caption"
                            , currency = "PV"
                        ,labelKey = "CustomerPV"
                     }){
    const {t} = useTranslation("teams");

    return <Box  padding={padding} display={orientation==="horizontal" ? "flex": null } alignItems={"center"}>
        {orientation==="horizontal" && <Typography variant={"inherit"}>0{currency}</Typography>}<Progress value={value}
                  hideValue={hideValue}
                  max={requiredValue}
                  label={<Typography variant={valueVariant}>{`${formatNumber(value,2)}`}<strong>{currency}</strong></Typography>}
                  color={value >= requiredValue
                      ? "success"
                      : value * 2.0 < requiredValue
                          ? "error"
                          : "primary"}
                  height={height}/>
        {orientation==="horizontal" && <Typography variant={"inherit"}>{requiredValue}{currency}</Typography>}
        {orientation==="vertical" &&  <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant={"inherit"}>0{currency}</Typography>
            {!hideLabel && <Typography>{t(labelKey)}</Typography>}
            <Typography variant={"inherit"}>{requiredValue}{currency}</Typography>
        </Box>}
    </Box>

}

export default Thermometer;