import React from 'react';
import Container from 'react-bootstrap/Container';
import {useTranslation} from "react-i18next";

const LoadingIndicator = ({tag}) => {
    const {t} = useTranslation();
  return (
    <Container className="search-main-content">
        <h1 className="message-loading">{t("Loading")} <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </h1>
    </Container>
  )
}

export default LoadingIndicator;
