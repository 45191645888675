import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { useStores } from "../../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Tab from '@material-ui/core/Tab';
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import { Link as RouterLink } from "react-router-dom";
import { cardLogo, joinPath } from "../../../utils/helpers";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PageTitle from "../../../components/PageTitle";
import clsx from "clsx";
import CardInfoEdit from "./CardInfoEdit";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import BankInfoEdit from "./BankInfoEdit";

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        padding: 0
    },
    root: {
        display: 'flex',
        flexDirection: "column",
        padding: theme.spacing(3)
    },
    card: {
        display: 'flex',
        flexDirection: "column",
        minHeight: 150,
        border: "1px solid #777"
    },
    selectedCard: {
        border: `2px solid ${theme.palette.primary.light}`,
    },
    cardMainArea: {
        flexGrow: 1
    },
    logo: {
        marginRight: theme.spacing(1)
    },
    addAddress: {
        border: "1px dashed white",
        borderRadius: 4,
        minHeight: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    defaultMark: {
        fontSize: 12,
        marginRight: "auto",
        textTransform: "uppercase"
    },
    withSpace: {
        marginTop: theme.spacing(3)
    }
}));

export default observer(function BillingInfoList({ parentMatch, newOnly = false
    , hideHeader = false, allowSelect = false
    , onlyCards = false, onlyBanks = false, showEwallet = false, onCancel
    , onAdded, onSaved, onEdit
    , onSelect }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { userBillingInfoStore, commonStore } = useStores();
    const { cards, banks, allLoading, removingPayments, error } = userBillingInfoStore;
    const [tabValue, setTabValue] = useState("1");
    const [inlineEditId, setInlineEditId] = useState(newOnly ? -1 : null);
    const [selectedId, setSelectedId] = useState();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        (async () => {
            await userBillingInfoStore.loadAll();
        })();

    }, []);


    const handleOnEdit = (cardId) => {
        setInlineEditId(cardId);
        if (onEdit)
            onEdit(cardId);
    };


    const setSelected = (cardId) => {
        setSelectedId(cardId);
        if (onSaved)
            onSaved(cardId);
    };

    const removeCard = (card) => {
        commonStore.showDeleteConfirm("Card", !!card.last4 ? `Card ******${card.last4}` : `Account *******${card.an}`, async () => {
            await userBillingInfoStore.removePayment(!!card.last4 ? card.paymentID : card.id);
            await userBillingInfoStore.loadAll();
        });
    };

    const setCardAsDefault = (card) => {
        commonStore.showConfirm(
            `Verifying that you want this card to be your DEFAULT: ${!!card.last4 ? `Card ******' ${card.last4}` : `Account ******* ${card.an}`}`,
            "Update",
            "Update Card",
            async () => {
                await userBillingInfoStore.setDefaultCard(!!card.last4 ? card.paymentID : card.id);
                await userBillingInfoStore.loadAll();
            });
    };

    const hanldeCancel = () => {
        if (onCancel) {
            onCancel();
        }
        else
            setInlineEditId(null);
    };

    const hanldeSaved = () => {
        if (onSaved) {
            onSaved();
        }
        else
            setInlineEditId(null);
    };

    if (inlineEditId != null) {
        if (onlyCards)
            return <CardInfoEdit cardId={inlineEditId} hideHeader={hideHeader}
                onSaved={hanldeSaved}
                onCancel={hanldeCancel}
            />;
        else if (onlyBanks)
            return <BankInfoEdit bankID={inlineEditId} hideHeader={hideHeader}
                onSaved={hanldeSaved}
                onCancel={hanldeCancel}
            />;
    }



    return <>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {(allLoading)
            ? <CircularProgress />

            : <>
                {!hideHeader && <PageTitle>{t("paymentSources")}</PageTitle>}
                <TabContext value={tabValue}>
                    {!(onlyCards || onlyBanks) && <TabList variant="fullWidth" onChange={handleChange} indicatorColor="primary">
                        <Tab label={t("creditDebit")} value={"1"} />
                        <Tab label={t("bank")} value={"2"} />
                    </TabList>}

                    {!onlyBanks && <TabPanel value={"1"} className={classes.tabPanel}>
                        <div className={classes.root}>
                            <Grid container spacing={2} >
                                {showEwallet &&
                                    <Grid item xs={12} md={6} lg={6} xl={6} >
                                        <Card className={clsx(classes.card, { [classes.selectedCard]: selectedId === -1 })}
                                            onClick={allowSelect ? (e => { setSelected(-1); }) : undefined}>
                                            <CardActionArea className={classes.cardMainArea}>
                                                <ListItem>
                                                    <ListItemText primary={`eWallet`} />
                                                </ListItem>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                }
                                {cards.map((x, i) => {
                                    let logo = cardLogo(x.type);
                                    return <Grid key={x.paymentID + "-" + i} item xs={12} md={6} lg={6} xl={6} >
                                        <Card className={clsx(classes.card, { [classes.selectedCard]: selectedId === x.paymentID })}
                                            onClick={allowSelect ? (e => { setSelected(x.paymentID); }) : undefined}>
                                            <CardActionArea className={classes.cardMainArea}>
                                                <ListItem>
                                                    {logo && <ListItemAvatar>
                                                        <img className={classes.logo} src={logo} />
                                                    </ListItemAvatar>}
                                                    <ListItemText primary={`************${x.last4}`}
                                                        secondary={`${t("expires")} ${x.expMonth | "00"}/${x.expYear | "00"}`} />
                                                </ListItem>

                                            </CardActionArea>
                                            <CardActions >
                                                {x.primary === "1"
                                                    ? <>
                                                        <Typography className={classes.defaultMark}>
                                                            {t("default")}
                                                        </Typography>
                                                    </>
                                                    : <>
                                                        <Typography className={classes.defaultMark}>
                                                        </Typography>
                                                    </>
                                                }
                                                {removingPayments.includes(x.paymentID)
                                                    ? <CircularProgress />
                                                    : <>
                                                        {x.primary !== "1"
                                                            ? <Button size="small" variant={"contained"} color="primary" onClick={e => { setCardAsDefault(x); }}>
                                                                {t("setAsDefault")}
                                                            </Button>
                                                            : null
                                                        }
                                                        <Button size="small" variant={"contained"} color="secondary" onClick={e => { removeCard(x); }}>
                                                            {t("remove")}
                                                        </Button>
                                                    </>
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>;
                                })}

                            </Grid>
                            <Grid container spacing={2} className={classes.withSpace}>
                                <Grid item xs={12} md={6} lg={6} xl={6} >

                                    {parentMatch
                                        ? <Card component={RouterLink} to={joinPath(parentMatch.url, "card/create")}
                                            className={classes.addAddress}>
                                            <Typography align="center" gutterBottom variant="h6" component="h4">
                                                {t("addNewCard")}
                                            </Typography>
                                            <AddIcon fontSize={"large"} />
                                        </Card>
                                        : <Card onClick={e => handleOnEdit(-1)}
                                            className={classes.addAddress}>
                                            <Typography align="center" gutterBottom variant="h6" component="h4">
                                                {t("addNewCard")}
                                            </Typography>
                                            <AddIcon fontSize={"large"} />
                                        </Card>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </TabPanel>}
                    {!onlyCards && <TabPanel value={"2"} className={classes.tabPanel}>
                        <div className={classes.root}>
                            <Grid container spacing={2} >
                                {banks.map((x, i) => {
                                    let logo = cardLogo(x.type);
                                    return <Grid key={x.paymentID + "-" + i} item xs={12} md={6} lg={6} xl={6} >
                                        <Card className={classes.card}>
                                            <CardActionArea className={classes.cardMainArea}>
                                                <ListItem>
                                                    <ListItemText primary={x.friendlyName}
                                                        secondary={x.an} />
                                                </ListItem>

                                            </CardActionArea>
                                            <CardActions >
                                                <Typography className={classes.defaultMark}>
                                                    {x.primary === "1" ? "Default" : ""}
                                                </Typography>
                                                {removingPayments.includes(x.paymentID)
                                                    ? <CircularProgress />
                                                    : <> <Button size="small" variant={"contained"} color="secondary" onClick={e => { removeCard(x); }}>
                                                        {t("remove")}
                                                    </Button>
                                                        {/* <Button component={RouterLink}
                                                            variant={"contained"} color="primary"
                                                            to={joinPath(parentMatch.url, "bank", x.id, "edit")} size="small">
                                                            Edit
                                                        </Button> */}
                                                    </>
                                                }

                                            </CardActions>
                                        </Card>
                                    </Grid>;
                                })}

                            </Grid>
                            <Grid container spacing={2} className={classes.withSpace}>
                                <Grid item xs={12} md={6} lg={6} xl={6} >
                                    <Card component={RouterLink} to={joinPath(parentMatch.url, "bank/create")} className={classes.addAddress}>
                                        <Typography align="center" gutterBottom variant="h6" component="h4">
                                            {t("addBank")}
                                        </Typography>
                                        <AddIcon />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Alert variant="outlined" severity="info" className={classes.withSpace}>
                                {t("pciCompliant")}
                            </Alert>
                        </div>
                    </TabPanel>}
                </TabContext>
            </>}
    </>;
});